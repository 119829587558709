import { ActionFunctionArgs, useRouteLoaderData } from 'react-router-dom';
import { api } from '../../utils/fetchApi';
import { SectionTitle } from './components/SectionTitle';
import { Fragment } from 'react/jsx-runtime';
import { PolicyOverviewCard } from './components/PolicyOverviewCard';
import { usePolicies } from './hooks/usePolicies';
import { TaskCollectionCard } from '@mosey/components/layout/TaskCollectionCard';
import { CopyField } from '@mosey/components/buttons/CopyField';
import { HandbookDataLoader } from './types';
import confettiImg from '../../assets/confetti.png';
import { HandbookBanner } from './components/HandbookBanner';

export const Overview = () => {
  const { published } = useRouteLoaderData('handbook') as HandbookDataLoader;
  const { sections, hasActionablePolicies, currentSection } = usePolicies();

  return (
    <div className="flex flex-col items-start px-8 py-6">
      <HandbookBanner>
        {published ? (
          <div className="flex flex-1 flex-col p-10">
            <img
              src={confettiImg}
              alt=""
              className="pointer-events-none absolute left-0 top-4 h-full"
            />
            <h1 className="text-2xl font-bold">Your handbook is live!</h1>
            <p className="mt-1 text-lg text-zinc-800">
              Employees will start receiving email reminders to view your
              company policies.
            </p>
            <div className="mt-5 flex flex-col gap-1 text-zinc-700">
              <p className="font-medium text-zinc-800">Handbook link</p>
              <CopyField label="handbook link" id="handbook-copy">
                https://handbook.mosey.com
              </CopyField>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col p-10">
            <h1 className="text-2xl font-bold">Welcome to your handbook!</h1>
            <p className="mt-1 text-zinc-800">
              Get compliant in a couple easy steps:
            </p>
            <ul className="mt-5 max-w-sm space-y-1">
              <TaskCollectionCard
                size="medium"
                title="Configure and adopt policies"
                status={hasActionablePolicies ? 'todo' : 'done'}
                to={`/handbook/section/${currentSection.section.id}/policies`}
              />
              <TaskCollectionCard
                size="medium"
                title="Publish handbook"
                status={published ? 'done' : 'todo'}
                to={`/handbook/publish`}
              />
            </ul>
          </div>
        )}
      </HandbookBanner>
      <h2 className="mb-2 mt-8 w-full border-b border-gray-200 pb-1 text-xl font-semibold">
        Policies
      </h2>
      {sections.map((section) => (
        <Fragment key={section.section.id}>
          <SectionTitle section={section.section} />
          <ul className="w-full space-y-2">
            {section.policies.map((policy) => {
              return (
                <PolicyOverviewCard
                  key={policy.id}
                  policy={policy}
                  variant="card"
                />
              );
            })}
          </ul>
        </Fragment>
      ))}
    </div>
  );
};

Overview.action = async ({ request }: ActionFunctionArgs) => {
  const { intent, ...data } = await request.json();

  if (intent === 'handbook-setup') {
    await api({
      method: 'POST',
      url: '/api/form_captures/save/handbook_setup',
      body: {
        data,
      },
    });
    try {
      return await api({
        method: 'POST',
        url: '/api/handbook/draft',
      });
    } catch (err) {
      const response = err as Response;
      if (response.status === 409) {
        // already exists, continue anyway
        return { ok: true, intent };
      } else {
        throw err;
      }
    }
  }

  return { ok: false, intent };
};
