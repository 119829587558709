import { ElementType, ReactNode } from 'react';
import handbookHeroImg from '../../../assets/handbook-hero.png';

type HandbookBannerProps = {
  as?: ElementType;
  children?: ReactNode;
};

export const HandbookBanner = ({
  as: Wrapper = 'div',
  children,
}: HandbookBannerProps) => {
  return (
    <Wrapper className="relative flex w-full items-center overflow-hidden rounded border border-teal-350 bg-teal-100">
      {children}
      <img
        src={handbookHeroImg}
        alt=""
        className="mt-2 hidden max-w-[60%] self-end lg:block"
      />
    </Wrapper>
  );
};
