import { FunctionComponent, useRef, useState } from 'react';
import {
  useNavigate,
  useParams,
  useLocation,
  Navigate,
  Route,
  Outlet,
} from 'react-router-dom';
import { clsx } from 'clsx';
import { USStateAbbrev } from '@mosey/utils/constants/us-states';

import { SentryRoutes as Routes } from '../sentry';
import {
  ActionIcon,
  FingerPrintIcon,
  RecurIcon,
  EmailIcon,
} from '@mosey/components/Icons';
import {
  BatchApiStatusHandler,
  Section,
  SectionHeading,
  Tooltip,
} from '../components';
import { LegalEntityRegion, RegionStatus, Requirement } from '../types';
import { LocationDetailSetupView } from './LocationDetailSetup';
import { LocationDetailSetupViewNew } from './LocationDetailSetup_NEW';
import { LocationDetailRecurringView } from './LocationDetailRecurring';
import { LocationDetailMailView } from './LocationDetailMail';
import { useBatchApi } from '../hooks';
import { LocationDetailAccountsView } from './LocationDetailAccounts';
import { FatalError } from './FatalError';
import { CertificateOfGoodStandingView } from './CertificateOfGoodStanding';
import { getStateSealImage } from '../utils/seals';
import { ErrorBoundary } from '@sentry/react';
import { useUser } from '../hooks/useUser';
import { LocationDetailProvider } from '../contexts/locationDetailContext';
import { useIsQuestionUrl } from './tasks-framework/utils/hooks';

type MenuItemProps = {
  label: string;
  icon: React.ReactNode;
  isSelected: boolean;
  isDisabled: boolean;
  isDisabledMessage?: string;
  linkTo: string;
};

export const MenuItem: FunctionComponent<MenuItemProps> = ({
  label,
  icon,
  isSelected,
  isDisabled,
  isDisabledMessage,
  linkTo,
}) => {
  const navigate = useNavigate();
  return (
    <Tooltip
      name={`tooltip-${label}`}
      label={isDisabled ? isDisabledMessage : null}
    >
      <div
        className={clsx(
          'group relative flex cursor-pointer px-1 py-4 text-lg font-bold',
          {
            "text-rose-700 after:content-[''] after:bg-rose-700 after:w-full after:h-0.5 after:absolute after:left-0 after:bottom-0":
              isSelected,

            'text-gray-500': !isSelected,
            'cursor-default': isDisabled,
          },
        )}
        onClick={() => {
          if (!isDisabled) {
            navigate(linkTo);
          }
        }}
      >
        <div
          className={clsx('mx-auto flex items-center gap-x-2', {
            'hover:text-sage-800': !isSelected && !isDisabled,
            'text-sage-700': !isSelected,
          })}
        >
          <span
            className={clsx('size-5 transition-colors', {
              'text-rose-700 opacity-40': isSelected,
            })}
          >
            {icon}
          </span>
          <span
            className={clsx(
              'inline-flex h-full items-center transition-colors',
            )}
          >
            {label}
          </span>
        </div>
      </div>
    </Tooltip>
  );
};

type LocationDetailProps = {
  legalEntityRegion: LegalEntityRegion;
  requirements: Requirement[];
  triggerRefresh: () => void;
};

export const LocationDetail: FunctionComponent<LocationDetailProps> = ({
  legalEntityRegion,
  requirements,
  triggerRefresh,
}) => {
  const { region } = legalEntityRegion;
  const locationId = region.code.toLowerCase();

  // Determine which tab we are on.
  const { pathname } = useLocation();
  const isLegalEntityStatusMaintaining =
    legalEntityRegion.status === RegionStatus.Maintaining;
  const isSetupTabSelected = pathname.endsWith('setup');
  const isRecurringTabSelected = pathname.endsWith('recurring');
  const isMailTabSelected = pathname.endsWith('mail');
  const isAccountsSelected = pathname.endsWith('accounts');

  const topElementRef = useRef<HTMLDivElement | null>(null);

  return (
    <Section isFullscreen className="flex h-full flex-col">
      <div className="sticky top-0 z-30 flex items-center justify-between border-b bg-white px-6 py-4">
        <div className="flex items-center">
          <img
            src={getStateSealImage(region.code as USStateAbbrev)}
            className="mr-4 size-12"
            alt="State seal"
          />
          <SectionHeading className="p-0" borderless text={`${region.name}`} />
        </div>
      </div>
      <div
        ref={topElementRef}
        className="flex items-center gap-x-8 border-b bg-sage-200 px-6"
      >
        <MenuItem
          label="Setup"
          isSelected={isSetupTabSelected}
          isDisabled={false}
          icon={<ActionIcon />}
          linkTo={`/locations/usa/${locationId}/setup`}
        />
        <MenuItem
          label="Recurring"
          isSelected={isRecurringTabSelected}
          isDisabled={!isLegalEntityStatusMaintaining}
          isDisabledMessage="Complete setup to track recurring requirements"
          icon={<RecurIcon />}
          linkTo={`/locations/usa/${locationId}/recurring`}
        />
        <MenuItem
          label="Mail"
          isSelected={isMailTabSelected}
          isDisabled={!isLegalEntityStatusMaintaining}
          isDisabledMessage="Complete setup to see your mail settings"
          icon={<EmailIcon />}
          linkTo={`/locations/usa/${locationId}/mail`}
        />
        <MenuItem
          label="Accounts"
          isSelected={isAccountsSelected}
          isDisabled={!isLegalEntityStatusMaintaining}
          isDisabledMessage="Complete setup to see your accounts"
          icon={<FingerPrintIcon />}
          linkTo={`/locations/usa/${locationId}/accounts`}
        />
      </div>
      <div className="relative h-full flex-1 bg-sage-100">
        <Routes>
          <Route
            path="setup"
            element={
              <ErrorBoundary fallback={<FatalError />}>
                {legalEntityRegion.is_setup_complete ? (
                  <LocationDetailSetupView
                    legalEntityRegion={legalEntityRegion}
                    requirements={requirements}
                    triggerParentRefresh={triggerRefresh}
                  />
                ) : (
                  <LocationDetailSetupViewNew
                    topElementRef={topElementRef}
                    legalEntityRegion={legalEntityRegion}
                    requirements={requirements}
                    triggerParentRefresh={triggerRefresh}
                  />
                )}
              </ErrorBoundary>
            }
          />
          <Route
            path="recurring"
            element={
              <ErrorBoundary fallback={<FatalError />}>
                <LocationDetailRecurringView
                  legalEntityRegion={legalEntityRegion}
                />
              </ErrorBoundary>
            }
          />
          <Route
            path="mail/*"
            element={
              <ErrorBoundary fallback={<FatalError />}>
                <LocationDetailMailView
                  legalEntityRegion={legalEntityRegion}
                  triggerParentRefresh={triggerRefresh}
                />
              </ErrorBoundary>
            }
          />
          <Route
            path="accounts"
            element={
              <ErrorBoundary fallback={<FatalError />}>
                <LocationDetailAccountsView
                  legalEntityRegion={legalEntityRegion}
                />
              </ErrorBoundary>
            }
          />
          <Route
            path="certificate-of-good-standing"
            element={
              <ErrorBoundary fallback={<FatalError />}>
                <CertificateOfGoodStandingView
                  legalEntityRegion={legalEntityRegion}
                />
              </ErrorBoundary>
            }
          />
          <Route path="/" element={<Navigate to="setup" replace />} />
        </Routes>
      </div>
    </Section>
  );
};

export const LocationDetailView: FunctionComponent = () => {
  const { locationId } = useParams<Record<string, string>>();
  const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false);
  const batchResponse = useBatchApi(
    [
      {
        url: `/api/legal_entity/locations/${locationId}`,
        method: 'GET',
      },
      {
        url: `/api/requirements/${locationId}/navigator`,
        method: 'GET',
      },
    ],
    [triggerRefresh],
  );

  const componentPropsFn = ([
    legalEntityRegionResponse,
    requirementsResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): LocationDetailProps => {
    return {
      legalEntityRegion: legalEntityRegionResponse,
      requirements: requirementsResponse.data,
      triggerRefresh: () => setTriggerRefresh((prev) => !prev),
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={LocationDetail}
      componentProps={componentPropsFn}
    />
  );
};

export const LocationViewGate = () => {
  const user = useUser();
  const isQuestionUrl = useIsQuestionUrl();

  if (user.legal_entity.use_resolvers || isQuestionUrl) {
    return <Outlet />;
  }

  return (
    <LocationDetailProvider>
      <LocationDetailView />
    </LocationDetailProvider>
  );
};
