import { clsx } from 'clsx';
import { PolicyContentMain, PolicyContentType } from './types';
import { PolicyMultiColumnList } from './PolicyMultiColumnList';
import { PolicyMarkdown } from './PolicyMarkdown';
import { LocationIcon } from '@mosey/components/Icons';

type PolicyContentRendererProps = {
  content: PolicyContentMain;
  isLoneContent?: boolean;
};

const Content = ({ content }: PolicyContentRendererProps) => {
  switch (content.type) {
    case PolicyContentType.Markdown:
      return <PolicyMarkdown content={content} />;
    case PolicyContentType.MultiColumnList:
      return <PolicyMultiColumnList content={content} />;
    default:
      return null;
  }
};

export const PolicyContentRenderer = ({
  content,
  isLoneContent,
}: PolicyContentRendererProps) => {
  if (content.audience) {
    return (
      <div
        className={clsx('rounded-lg bg-indigo-50 px-6 py-4', {
          '-m-6': isLoneContent,
        })}
      >
        <Content content={content} />
        <p className="flex justify-end gap-2 font-bold text-indigo-900">
          <LocationIcon className="size-6" />
          Specific to {content.audience}
        </p>
      </div>
    );
  }
  return <Content content={content} />;
};
