import * as Sentry from '@sentry/react';
import { LoaderFunction, json } from 'react-router-dom';
import { api } from './fetchApi';
import { User } from '../types';

export const userLoader: LoaderFunction = async () => {
  const response = await api({
    url: '/api/users/me',
    method: 'POST',
  });

  const user: User = await response.json();

  Sentry.setUser({ id: user.id });

  return json(user);
};
