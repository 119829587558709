import {
  ActionFunction,
  LoaderFunction,
  redirect,
  useFetcher,
  useLoaderData,
} from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@mosey/components/buttons/Button';
import {
  Error,
  Section,
  UsStateSelectField,
  SectionHeading,
} from '../components';
import { FieldError } from '@mosey/components/forms/FieldError';
import { api } from '../utils/fetchApi';
import { LegalEntityRegion } from '../types';
import { useUser } from '../hooks/useUser';
import tasksResolved from '../assets/tasks-framework/tasks-resolved.svg';

export const Component = () => {
  const user = useUser();

  if (!user.legal_entity.use_resolvers) {
    return <NonTasksUsSetup />;
  }

  return (
    <div className="flex h-full items-center justify-center overflow-y-auto bg-white">
      <section className="flex flex-col items-center">
        <img src={tasksResolved} alt="" />
        <h1 className="mt-1 self-stretch text-center text-4xl font-black leading-10 text-black">
          Upgrade your plan to add more states
        </h1>
        <p className="mt-2 self-stretch text-center text-base font-normal text-zinc-800">
          From hiring the founding team to running a complex enterprise, Mosey
          has you covered
        </p>
        <Button
          as="a"
          href="mailto:sales@mosey.com"
          size="large"
          className="mt-10"
        >
          Email sales@mosey.com
        </Button>
      </section>
    </div>
  );
};

type MutationResponse =
  | {
      errors: Record<string, string> | null;
    }
  | undefined;

export const loader: LoaderFunction = async () => {
  return api({
    url: '/api/legal_entity/locations',
    method: 'GET',
  });
};

export const action: ActionFunction = async ({
  request,
}): Promise<MutationResponse | Response> => {
  const data = await request.json();
  const locationId = data.state.toLowerCase();

  try {
    await api({
      url: `/api/legal_entity/locations/${locationId}/requirements`,
      method: 'POST',
    });
  } catch (error) {
    return {
      errors: {
        submit: "Something went wrong, we're looking into it.",
      },
    };
  }

  return redirect(`/locations/usa/${locationId}`);
};

interface FormValues {
  state: string;
}

const NonTasksUsSetup = () => {
  const legalEntityRegions = useLoaderData() as LegalEntityRegion[];
  const fetcher = useFetcher<MutationResponse>();
  const formMethods = useForm<FormValues>();
  const {
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = formMethods;

  const legalEntityRegionCodes: string[] = legalEntityRegions.map((ler) => {
    return ler.region.code;
  });

  const onSubmit = (data: FormValues) => {
    clearErrors();

    fetcher.submit(
      { ...data },
      { method: 'POST', encType: 'application/json' },
    );
  };

  return (
    <Section className="h-full">
      <SectionHeading text="Add a new location" />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="max-w-sm py-8">
            <fieldset className="pb-8">
              {errors?.state && (
                <Error heading="Please select a state" body="" />
              )}

              <div className="my-2 py-2">
                <UsStateSelectField
                  name="state"
                  label="Which location would you like to set up?"
                  description=""
                  reactFormConfig={{ required: 'This is required' }}
                  error={errors.state}
                  disabledStateOptions={legalEntityRegionCodes}
                />
              </div>
            </fieldset>

            <Button
              type="submit"
              size="large"
              disabled={fetcher.state !== 'idle'}
            >
              Next
            </Button>

            {fetcher.data?.errors?.submit && (
              <FieldError
                error={{ type: 'manual', message: fetcher.data.errors.submit }}
              />
            )}
          </div>
        </form>
      </FormProvider>
    </Section>
  );
};
