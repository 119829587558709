import {
  useActionData,
  useLocation,
  useMatch,
  useMatches,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';
import {
  LocationScope,
  ResolverType,
  Task,
  TaskQuestion,
  TaskRef,
  TaskRemediation,
  TaskRequirement,
  TaskType,
} from './types';
import { TaskActionData } from '../tasks/types';
import { AutomationTypeEnum } from '../../../types';
import { getNextTask, isTaskOverdue, isTaskTodo } from '.';
import { useUser } from '../../../hooks/useUser';
import { USStateName } from '@mosey/utils/constants/us-states';
import { useEffect, useState } from 'react';

export const useTasks = () => {
  const matches = useMatches();
  const routeId = matches.find(({ id }) => {
    return id.startsWith('resolver-tasks/');
  })?.id;

  return (useRouteLoaderData(routeId || '') || []) as TaskRef[];
};

export const useTask = () => {
  const matches = useMatches();
  const routeId = matches.find(({ id }) => {
    return id.startsWith('task/');
  })?.id;

  if (!routeId) {
    throw new Error('The useTask hook must be used within a Task route.');
  }

  const task = useRouteLoaderData(routeId) as Task;

  return task;
};

export const useTaskQuestion = () => {
  return useTask() as TaskQuestion;
};

export const useTaskRequirement = () => {
  return useTask() as TaskRequirement;
};

export const useRemediations = () => {
  const {
    legal_entity: { name: legalEntityName, notice_url: noticeUrl },
  } = useUser();
  const {
    source: { remediations, location },
  } = useTaskRequirement();
  const isNotices = useIsNoticesAndPostersTask();

  if (isNotices) {
    if (!noticeUrl) {
      throw new Error(
        'The notice_url is missing and is required for notice tasks.',
      );
    }

    return [
      {
        id: 'notices',
        title: 'Link to Your Notice Board',
        description:
          'Add the link to your notice board to an internal wiki or document that is readily accessible to all remote employees.',
        order: 0,
        paths: [
          {
            type: 'link',
            value: `${noticeUrl}?region=${location.name}`,
            text: `${location.name} Notice Board – ${legalEntityName}`,
          },
          {
            type: 'link',
            value: noticeUrl,
            text: `Notice Board (All Locations) – ${legalEntityName}`,
          },
        ],
      } as TaskRemediation,
      ...remediations,
    ];
  }

  return remediations;
};

export const useTodoTasks = () => {
  const tasks = useTasks();

  return tasks.filter(isTaskTodo);
};

export const useResolverUrl = () => {
  const matches = useMatches();
  return matches.find(({ id }) => id.startsWith('resolver-tasks/'))?.pathname;
};

export const useTaskUrl = () => {
  const matches = useMatches();
  const url = matches.find(({ id }) => id.startsWith('task/'));

  if (!url) {
    throw new Error(
      'The useTaskUrl hook must be used within a Task Resolver Task route.',
    );
  }

  return url.pathname;
};

export const useNextTask = () => {
  const { taskId } = useParams();
  const tasks = useTasks();

  return getNextTask(tasks, taskId);
};

export const useNextTaskUrl = () => {
  const nextTask = useNextTask();
  const resolverUrl = useResolverUrl();

  if (nextTask && resolverUrl) {
    return `${resolverUrl}/tasks/${nextTask.id}`;
  }

  return null;
};

export const useNextResolverUrl = () => {
  const nextTaskUrl = useNextTaskUrl();
  const resolverUrl = useResolverUrl();

  if (!nextTaskUrl && resolverUrl) {
    return `${resolverUrl}/complete`;
  }

  return nextTaskUrl;
};

export const useResolverLocation = () => {
  const { locationId } = useParams();

  if (locationId) {
    return {
      code: locationId,
      name: USStateName[locationId.toUpperCase() as keyof typeof USStateName],
    };
  }

  return null;
};

export const useTaskLocation = () => {
  const resolverLocation = useResolverLocation();
  const { source } = useTask();

  if (resolverLocation) {
    return resolverLocation;
  } else if (source.type === TaskType.Requirement) {
    return {
      code: source.location.code,
      name: source.location.name,
    };
  } else if (source.location_scope !== LocationScope.Entity) {
    return {
      code: source.locations[0].code,
      name: source.locations[0].name,
    };
  }

  return null;
};

export const useTaskActionData = () => {
  return useActionData() as TaskActionData;
};

export const useNewTasks = () => {
  const allTasks = useTasks();
  const taskActionData = useTaskActionData();
  const { state } = useLocation();
  let newTasks: TaskRef[] | undefined;

  if (taskActionData?.newTasks && taskActionData.newTasks.length > 0) {
    newTasks = taskActionData.newTasks;
  } else if (state?.newTasks?.length > 0) {
    newTasks = state.newTasks as TaskRef[];
  }

  if (newTasks) {
    if (allTasks.length > 0) {
      return newTasks.filter((newTask) => {
        return allTasks.find((task) => task.id === newTask.id);
      });
    }

    return newTasks;
  }

  return [];
};

export const useIsNewTask = (id: string) => {
  const newTasks = useNewTasks();
  const newTaskIndex = newTasks.findIndex((newTask) => newTask.id === id);

  return newTaskIndex > -1;
};

export const useIsNoticesAndPostersTask = () => {
  const { source } = useTask();

  return (
    source.type === TaskType.Requirement &&
    source.tags.includes(AutomationTypeEnum.NoticeBoard)
  );
};

export const useNewTasksTimeToReveal = () => {
  const newTasks = useNewTasks();
  const analyzingTime = 3000;
  const generatingTime = 1000;
  const visibilityDelay = analyzingTime + generatingTime;
  const idealIndividualRevealTime = 1000;
  const maxTotalRevealTimeWithoutDelay = 3000;
  const totalRevealTime = Math.min(
    maxTotalRevealTimeWithoutDelay,
    newTasks.length * idealIndividualRevealTime,
  );

  return { analyzingTime, generatingTime, visibilityDelay, totalRevealTime };
};

export const useNewTaskRevealDelay = (id: string) => {
  const newTasks = useNewTasks();
  const newTaskIndex = newTasks.findIndex((newTask) => newTask.id === id);
  const { visibilityDelay, totalRevealTime } = useNewTasksTimeToReveal();

  return (totalRevealTime / newTasks.length) * newTaskIndex + visibilityDelay;
};

export const useNewTaskVisibility = (id: string) => {
  const { resolverType } = useParams();
  const [isVisible, setIsVisible] = useState(false);
  let timeToShow = useNewTaskRevealDelay(id);

  if (resolverType === ResolverType.Assessment) {
    timeToShow = 0;
  }

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (!isVisible) {
      timeoutId = setTimeout(setIsVisible, timeToShow, true);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isVisible, timeToShow]);

  return isVisible;
};

export const useIsStandaloneRoute = () => {
  const matches = useMatches();
  return !matches.find(({ id }) => id.startsWith('resolver-tasks/'));
};

export const useIsAutomationRoute = () => {
  const taskUrl = useTaskUrl();

  return Boolean(useMatch(`${taskUrl}/automation`));
};

export const useIsOverdue = () => {
  const task = useTask();

  return isTaskOverdue(task);
};

const _matchPath = (pattern: string): boolean => {
  return !!useMatch(pattern);
};

export const useIsQuestionUrl = () => {
  const baseUrl = '/locations/usa/:locationId/resolver';
  const allowedQuestionUrlPatterns = [
    '/question',
    '/assessment',
    '/question/complate',
    '/assessment/complete',
    '/question/tasks/:taskId',
    '/assessment/tasks/:taskId',
  ];

  const matches = allowedQuestionUrlPatterns.map((pattern) => {
    return _matchPath(`${baseUrl}${pattern}`);
  });

  return matches.some((match) => match === true);
};
