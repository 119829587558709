import { clsx } from 'clsx';
import { PolicyMultiColumnListContent } from './types';

type PolicyMultiColumnListProps = {
  content: PolicyMultiColumnListContent;
};

const SUPPORTED_COLUMNS: Record<number, string> = {
  2: 'md:columns-2',
  3: 'md:columns-3',
  4: 'md:columns-4',
};

export const PolicyMultiColumnList = ({
  content,
}: PolicyMultiColumnListProps) => {
  const columnClass =
    SUPPORTED_COLUMNS[content.columns] || SUPPORTED_COLUMNS[2];
  return (
    <ul className={clsx('list-disc gap-8 pl-8', columnClass)}>
      {content.items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};
