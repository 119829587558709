import { FormProvider, useForm } from 'react-hook-form';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import { Renderer, formSpecToRendererConfig } from '../../../components';
import { useTaskActionData, useTaskQuestion } from '../utils/hooks';
import { SkipButton } from '../common/SkipButton';
import { SubmitButton } from '../common/SubmitButton';
import { TaskActions } from '../common/TaskActions';
import { TaskRadioField } from '../common/forms/RadioField';
import { TaskNumberField } from '../common/forms/NumberField';
import { TaskStatus } from '../utils/types';
import { FormSpecField } from '../../../types';
import { NextButton } from '../common/NextButton';
import { Form, useNavigation } from 'react-router-dom';
import { TaskHeader } from '../common/TaskHeader';
import { TaskDateField } from '../common/forms/DateField';
import { TaskContainer } from './TaskContainer';

const TaskQuestionAnswered = () => {
  const {
    source: { form, answer, description },
  } = useTaskQuestion();
  let formattedAnswer = answer;

  if (formattedAnswer === 'true') {
    formattedAnswer = 'Yes';
  } else if (formattedAnswer === 'false') {
    formattedAnswer = 'No';
  }

  return (
    <TaskContainer>
      <TaskHeader />

      {description && <p className="max-w-3xl">{description}</p>}

      {form.sections.map((section) => {
        return section.form_fields.map((field) => {
          return (
            <div key={field.name} className="mt-4 space-y-8">
              <p>{field.label}</p>
              <div className="relative w-fit min-w-52 space-y-1 rounded border border-gray-200 px-6 py-4 uppercase text-zinc-700">
                <p className="absolute left-2 top-0 -translate-y-1/2 bg-white px-1 text-xs">
                  Your answer
                </p>
                <p className="text-2xl font-medium leading-[48px]">
                  {formattedAnswer}
                </p>
              </div>
            </div>
          );
        });
      })}

      <TaskActions>
        <NextButton defaultToHome>Next</NextButton>
      </TaskActions>
    </TaskContainer>
  );
};

interface SingleFieldFormProps {
  formId: string;
  field: FormSpecField;
}

const SingleFieldForm = ({ formId, field }: SingleFieldFormProps) => {
  const actionData = useTaskActionData();

  /**
   * TODO: Move these mega components into the main /components
   * directory once the feature stablizes.
   *
   * TODO: Once feature is stable, consider merging into <Renderer />
   */
  let content;
  const props = {
    field,
    errors: actionData?.errors,
  };

  switch (field.component.type) {
    case 'radio':
      content = <TaskRadioField {...props} />;
      break;

    case 'number':
      content = <TaskNumberField {...props} />;
      break;

    case 'date':
      content = <TaskDateField {...props} />;
      break;
  }

  return (
    <Form method="POST" id={formId} replace>
      {content}
    </Form>
  );
};

const TaskQuestionForm = () => {
  const { state } = useNavigation();
  const {
    id,
    source: { description, form },
  } = useTaskQuestion();
  const actionData = useTaskActionData();
  const formMethods = useForm();
  const {
    formState: { errors },
  } = formMethods;
  const isSingleField =
    form.sections.length === 1 && form.sections[0].form_fields.length === 1;
  const formId = `task-question-form_${id}`;

  return (
    <TaskContainer>
      <TaskHeader />

      <BlockAlert
        variant="error"
        message={actionData?.errors?.submit}
        scrollIntoView
        show={!!actionData?.errors?.submit && state === 'idle'}
        aria-live="assertive"
      />

      {description && <p className="max-w-3xl">{description}</p>}

      {isSingleField ? (
        <SingleFieldForm
          key={formId}
          formId={formId}
          field={form.sections[0].form_fields[0]}
        />
      ) : (
        <FormProvider {...formMethods}>
          <Form method="POST" id={formId} key={formId} replace>
            <Renderer config={formSpecToRendererConfig(form)} errors={errors} />
          </Form>
        </FormProvider>
      )}

      <TaskActions>
        <SkipButton />
        <SubmitButton form={formId} intent="answer-question">
          Continue
        </SubmitButton>
      </TaskActions>
    </TaskContainer>
  );
};

export const TaskQuestion = () => {
  const { status } = useTaskQuestion();

  if (status === TaskStatus.Done) {
    return <TaskQuestionAnswered />;
  }

  return <TaskQuestionForm />;
};
