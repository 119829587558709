import { LoaderFunction, Navigate, Outlet, redirect } from 'react-router-dom';
import { api } from '../../../utils/fetchApi';
import { useUser } from '../../../hooks/useUser';
import { ResolverType } from '../utils/types';
import { USStateId } from '@mosey/utils/constants/us-states';
import { getResolverStartDate } from '../utils/session';
import { useIsQuestionUrl } from '../utils/hooks';

export const loader: LoaderFunction = async ({
  params: { countryId, locationId, resolverType },
}) => {
  let url = '/api/compliance/tasks';
  let regionId: USStateId | undefined;
  const searchParams = new URLSearchParams();

  if (countryId && locationId) {
    regionId = USStateId[locationId.toUpperCase() as keyof typeof USStateId];

    if (regionId) {
      searchParams.set('region_id', regionId);
    } else {
      return redirect(`/home`);
    }
  }

  searchParams.set('is_setup', 'false');

  if (resolverType) {
    switch (resolverType) {
      case ResolverType.Assessment:
        searchParams.set('is_setup', 'true');
        searchParams.set('task_type', ResolverType.Question);
        break;

      case ResolverType.Setup:
        searchParams.set('is_setup', 'true');
        break;

      case ResolverType.Requirement: {
        searchParams.set(
          'completed_start_time',
          getResolverStartDate(ResolverType.Requirement, regionId),
        );
        searchParams.set('task_type', ResolverType.Requirement);
        break;
      }

      case ResolverType.Question:
        searchParams.set(
          'completed_start_time',
          getResolverStartDate(ResolverType.Question, regionId),
        );
        searchParams.set('task_type', ResolverType.Question);
        break;

      case ResolverType.Upcoming:
      case ResolverType.Overdue:
        searchParams.set('date_filter', resolverType);
        break;

      default:
        return redirect('/home');
    }
  }

  if (searchParams.size > 0) {
    url = `${url}?${searchParams.toString()}`;
  }

  return api({ url, method: 'GET' });
};

export const Component = () => {
  const user = useUser();
  const isQuestionUrl = useIsQuestionUrl();

  if (!user.legal_entity.use_resolvers && !isQuestionUrl) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};
