import { CheckCircleIcon } from '@heroicons/react/solid';
import {
  ActionFunctionArgs,
  Form,
  Link,
  redirect,
  useNavigation,
  useRouteLoaderData,
} from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { HandbookDataLoader, PublishableChange } from './types';
import { api } from '../../utils/fetchApi';
import { HandbookContainer } from './HandbookContainer';
import { ReactNode } from 'react';
import { getHandbookChanges } from './utils';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';

const MAX_INLINE_CHANGES = 6;
const NUM_CHANGES_IN_PREVIEW = 4;

const Pill = ({ children }: { children: ReactNode }) => {
  // TODO: Use the @mosey/compenents Pill component once it is built (designs in review)
  // https://www.figma.com/design/mdBFPcX4SlUbNWQI9seAnf/Tasks?node-id=161-1373&m=dev
  return (
    <div className="rounded-full bg-teal-800 px-3 py-1 text-xs font-bold uppercase text-white">
      {children}
    </div>
  );
};

const Row = ({ change }: { change: PublishableChange }) => {
  return (
    <li
      className="flex items-center justify-between gap-2 px-3 py-2"
      aria-label={`${change.instance.title} was ${change.type === 'adopted' ? 'adopted' : 'updated'}`}
    >
      <p className="text-sm font-medium text-zinc-800">
        {change.instance.title}
      </p>
      <Pill>{change.type === 'adopted' ? 'Adopted' : 'Updated'}</Pill>
    </li>
  );
};

export const Publish = () => {
  const { draft, published } = useRouteLoaderData(
    'handbook',
  ) as HandbookDataLoader;
  const { state } = useNavigation();
  const changes = getHandbookChanges(draft!, published);
  const requiresPreview = changes.length > MAX_INLINE_CHANGES;
  const shownChanges = requiresPreview
    ? changes.slice(0, NUM_CHANGES_IN_PREVIEW)
    : changes;
  const hiddenChanges = requiresPreview
    ? changes.slice(NUM_CHANGES_IN_PREVIEW)
    : [];

  return (
    <HandbookContainer>
      <div className="flex grow flex-col items-start justify-center gap-y-6">
        <CheckCircleIcon className="size-12 self-center text-teal-700 " />

        <div className="w-full space-y-2 text-center">
          <h2 className="text-2xl font-bold">
            Your handbook is ready to be published!
          </h2>

          <p className="text-base">
            Employees will access it by verifying their company email address.
          </p>
        </div>
        <ul className="flex gap-10 self-center">
          <li>
            <Button as={Link} variant="secondary" to="/handbook/overview">
              Cancel
            </Button>
          </li>
          <li className="-ml-6">
            <Form action="/handbook/publish" method="POST">
              <Button
                disabled={!draft || !draft.is_publishable}
                isLoading={state !== 'idle'}
                name="intent"
                value="handbook-publish"
              >
                Publish
              </Button>
            </Form>
          </li>
        </ul>
      </div>

      {shownChanges.length > 0 && (
        <div className="mb-10 w-full space-y-2">
          <h3 className="text-base font-semibold">Summary</h3>
          <ul className="divide-y-1 divide-gray-100 border border-gray-100">
            {shownChanges.map((change) => (
              <Row key={change.instance.id} change={change} />
            ))}
            {requiresPreview && (
              <Disclosure>
                {({ open }) => (
                  <>
                    {!open && (
                      <li>
                        <DisclosureButton className="w-full bg-gray-50 px-3 py-1 text-sm font-medium text-zinc-600 hover:bg-gray-100">
                          + {hiddenChanges.length} more
                        </DisclosureButton>
                      </li>
                    )}
                    <DisclosurePanel as="li">
                      {/* DisclosurePanel requires a tangible node to pass a ref to, so these items need to be nested */}
                      <p className="sr-only">More changes:</p>
                      <ul className="divide-y-1 divide-gray-100">
                        {hiddenChanges.map((change) => (
                          <Row key={change.instance.id} change={change} />
                        ))}
                      </ul>
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            )}
          </ul>
        </div>
      )}
    </HandbookContainer>
  );
};

Publish.action = async ({ request }: ActionFunctionArgs) => {
  const intent = (await request.formData()).get('intent');

  if (intent === 'handbook-publish') {
    try {
      await api({
        method: 'POST',
        url: '/api/handbook/publish',
      });

      return redirect('/handbook/overview');
    } catch (e: Response | unknown) {
      let error = 'We were unable to publish your handbook. Please try again.';

      if (e instanceof Response) {
        error = (await e.json()).detail;
      }

      return { ok: false, error, intent };
    }
  }

  return { ok: false, error: 'Unknown', intent };
};
