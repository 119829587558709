import {
  useState,
  useContext,
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
} from 'react';
import { User } from '../types';

export type GlobalState = {
  user: User;
  hasCheckedBasicPlanOnboarding: boolean;
};
type SetState = Dispatch<SetStateAction<GlobalState>>;
type GlobalProviderProps = { initState: GlobalState; children: ReactNode };

export const GlobalContext = createContext<
  { globalState: GlobalState; setGlobalState: SetState } | undefined
>(undefined);

export function GlobalContextProvider({
  initState,
  children,
}: GlobalProviderProps) {
  const [globalState, setGlobalState] = useState<GlobalState>(initState);

  const value = useMemo(
    () => ({ globalState, setGlobalState }),
    [globalState, setGlobalState],
  );
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

export const useGlobalContext = (): {
  globalState: GlobalState;
  setGlobalState: SetState;
} => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error(
      'useGlobalContext must be used within a GlobalContextProvider',
    );
  }
  return context;
};
