import { FunctionComponent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@mosey/components/buttons/Button';
import {
  Address,
  ApiStatusHandler,
  CheckBoxField,
  SectionHeading,
} from '../components';
import { Address as AddressType, AddressIFormValues } from '../types';
import { useApi } from '../hooks';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus } from '../utils/types';
import { FormError } from '@mosey/components/forms/FormError';

type BusinessInformationProps = {
  physical_address?: AddressType;
  mailing_address?: AddressType;
};

type BusinessInformationIFormValues = {
  physical_address?: AddressIFormValues;
  mailing_address?: AddressIFormValues;
  is_physical_address_same_as_mailing_address?: boolean;
};

const areAddressesSame = (a?: AddressType, b?: AddressType) =>
  a && b && a.id === b.id;

const BusinessInformation: FunctionComponent<BusinessInformationProps> = ({
  physical_address: physicalAddress,
  mailing_address: mailingAddress,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [submitResult, setSubmitResult] = useState<boolean | null>(null);
  const isSubmitSuccess = submitResult === true;
  const isSubmitErrored = submitResult === false;

  // set default values for the form
  const defaultIsBusinessAddressSameAsMailingAddress = areAddressesSame(
    physicalAddress,
    mailingAddress,
  );
  const defaultMailingAddress = defaultIsBusinessAddressSameAsMailingAddress
    ? {}
    : mailingAddress;
  const formMethods = useForm<BusinessInformationIFormValues>({
    defaultValues: {
      physical_address:
        physicalAddress as BusinessInformationIFormValues['physical_address'],
      mailing_address:
        defaultMailingAddress as BusinessInformationIFormValues['mailing_address'],
      is_physical_address_same_as_mailing_address:
        defaultIsBusinessAddressSameAsMailingAddress,
    },
  });

  const {
    handleSubmit,
    clearErrors,
    unregister,
    watch,
    reset,
    formState: { errors, isDirty, isValid, defaultValues },
  } = formMethods;

  const isBusinessAddressSameAsMailingAddress = watch(
    'is_physical_address_same_as_mailing_address',
  );

  useEffect(() => {
    if (isBusinessAddressSameAsMailingAddress) {
      unregister('mailing_address', {
        // don't erase the fields if the user toggles is_physical_address_same_as_mailing_address
        keepValue: true,
        // if user resets the form (via cancel), still keep the default values
        keepDefaultValue: true,
      });
    }
  }, [isBusinessAddressSameAsMailingAddress]);

  useEffect(() => {
    // if the form is edited, clear away any submission success or errors
    if (!isDirty) return;
    setSubmitResult(null);
  }, [isDirty]);

  const onCancel = () => {
    // reset to the default values
    reset(defaultValues);
  };

  const onSubmit = async (formData: BusinessInformationIFormValues) => {
    if (!isValid) return;

    setSubmitResult(null);
    setIsLoading(true);
    clearErrors();

    const body: Record<string, AddressIFormValues | undefined> = {
      physical_address: formData.physical_address,
    };
    if (!formData.is_physical_address_same_as_mailing_address) {
      body.mailing_address = formData.mailing_address;
    }

    const businessInformationResponse = await fetchApi({
      url: '/api/legal_entity/entity_information',
      method: 'PUT',
      body,
    });
    const { status, data } = businessInformationResponse;

    if (status === ApiStatus.Error || status === ApiStatus.ErrorUnauthorized) {
      setSubmitResult(false);
    } else {
      setSubmitResult(true);
      // setup default values to the new submitted values
      const newDefaultValues = {
        ...data,
        is_physical_address_same_as_mailing_address: false,
      };

      // set if address are the same in the returned data
      if (areAddressesSame(data.physical_address, data.mailing_address)) {
        newDefaultValues.is_physical_address_same_as_mailing_address = true;
        newDefaultValues.mailing_address = {};
      }

      // use reset to set the default values of the form to the submitted values
      reset(newDefaultValues);
    }

    setIsLoading(false);
  };

  return (
    <div className="flex h-full flex-col">
      <div className="grow p-16">
        <SectionHeading className="flex-1 pb-4" text="Business Information" />
        <div className="flex flex-col py-9 xl:max-w-xl">
          <section>
            <FormProvider {...formMethods}>
              <form>
                <Address
                  error={errors.physical_address}
                  name="physical_address"
                  label="Business Address"
                />
                <CheckBoxField
                  name="is_physical_address_same_as_mailing_address"
                  label="This address is also the mailing address"
                  error={errors.is_physical_address_same_as_mailing_address}
                  className="my-3"
                />

                {!isBusinessAddressSameAsMailingAddress && (
                  <div className="mt-8">
                    <Address
                      label="Mailing Address"
                      error={errors.mailing_address}
                      name="mailing_address"
                    />
                  </div>
                )}
              </form>
            </FormProvider>
            {isSubmitErrored && <FormError margin="mb-9" />}
            {isSubmitSuccess && (
              <div>
                <div className="mb-9 w-full rounded border border-green-200 bg-green-100 px-4 py-2 text-green-700">
                  Your business information has been updated!
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
      {isDirty && isValid && (
        <div className="sticky bottom-0 border-t border-gray-200 bg-sage-50 px-6 py-4">
          <div className="flex items-center justify-end space-x-2">
            <Button
              type="button"
              variant="secondary"
              onClick={onCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <div className="w-28">
              <Button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                isFullWidth
                isLoading={isLoading}
                disabled={isLoading}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const BusinessInformationView: FunctionComponent = () => {
  const { status, error, data } = useApi({
    url: '/api/legal_entity/entity_information',
    method: 'GET',
  });
  return (
    <ApiStatusHandler
      status={status}
      error={error}
      data={data}
      component={BusinessInformation}
    />
  );
};
