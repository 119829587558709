import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { HandbookDataLoader, Policy } from '../types';
import { usePolicyDetails } from '../hooks/usePolicyDetails';
import {
  TaskCardStatus,
  TaskCardVariant,
} from '@mosey/components/layout/types';
import { useRouteLoaderData } from 'react-router-dom';

type PolicyCardProps = {
  policy: Policy;
  variant: TaskCardVariant;
};

export const PolicyOverviewCard = ({ policy, variant }: PolicyCardProps) => {
  const { published } = useRouteLoaderData('handbook') as HandbookDataLoader;
  const { isAdopted, isUpdatable } = usePolicyDetails(policy.id);
  const regionName = policy.policy_scope?.region?.name;

  // default to TODO for non-adopted policies when the user hasn't published a handbook yet
  let status: TaskCardStatus = 'todo';
  if (isUpdatable) {
    status = 'todo';
  } else if (isAdopted) {
    status = 'done';
  } else if (published) {
    status = 'new';
  }

  return (
    <TaskSummaryCard
      as="li"
      variant={variant}
      to={`/handbook/section/${policy.policy_section.id}/policies#${policy.slug}`}
      status={status}
      title={policy.title}
      isExactMatch
      region={regionName}
      reason={
        regionName
          ? {
              title: 'State Compliance',
              type: 'new-requirement',
            }
          : undefined
      }
    />
  );
};
