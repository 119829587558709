import { FunctionComponent } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckIcon, ArrowRightIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { useScrollIntoView } from '@mosey/components/hooks';
import * as config from '../settings/config';
import { BatchApiStatusHandler } from '../components';
import { TextField } from '@mosey/components/forms/TextField';
import { useBatchApi } from '../hooks';
import { FatalError } from './FatalError';
import { NextStepDataProps } from './LocationDetailSetup_NEW';
import { Region } from '../types';
import { useCopy } from '@mosey/components/hooks/useCopy';
import { useUser } from '../hooks/useUser';

export type NoticeEnrollmentNewProps = {
  publicKey: string;
  region: Region;
  nextStepData: NextStepDataProps | null;
  onNext: () => void;
};

interface NoticeUrlDisplayProps {
  region: Region;
}

export const NoticeUrlDisplay = ({ region }: NoticeUrlDisplayProps) => {
  const {
    legal_entity: { notice_url: noticeUrl },
  } = useUser();
  const url = `${noticeUrl}?region=${region.name}`;
  const [onCopy, isCopied] = useCopy(url);
  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form className="flex w-full" onSubmit={handleSubmit(onCopy)}>
        <TextField
          name="url"
          readOnly
          value={url}
          inputClassName="rounded-r-none"
          hasMargin={false}
          isFullWidth
        />
        <Button
          variant="secondary"
          size="large"
          className="w-48 rounded-l-none"
        >
          {isCopied ? 'Link copied' : 'Copy link'}
        </Button>
      </form>
    </FormProvider>
  );
};

export const NoticeEnrollmentNew: FunctionComponent<
  NoticeEnrollmentNewProps
> = ({ publicKey, region, nextStepData, onNext }) => {
  const [scrollIntoViewRef] = useScrollIntoView();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const url = `${config.EMBED_BASE_URL}/notices/summary/${publicKey}?region=${region.name}`;

  return (
    <div className="flex justify-center" ref={scrollIntoViewRef}>
      <div className="p-14">
        <div className="flex w-full justify-center pb-2">
          <CheckIcon className="size-12 rounded-full bg-green-600 p-1 text-white" />
        </div>
        <h1 className="w-full pb-10 text-center text-2xl font-bold">
          Employee Notice Board Updated
        </h1>
        <div className="rounded border-1 bg-sage-50">
          <div className="p-9">
            <h2 className="pb-6 text-xl font-bold">
              Share With Your Employees
            </h2>
            <p className="text-sm">
              All required notices and posters for {region.name} are now
              available on your{' '}
              <a href={url} className="text-blue-500">
                Notice Board
              </a>
              . Copy the link below to an internal wiki or document that is
              readibly accessible to all remote employees and notify them of
              where to find it. Updates will happen automatically for all
              regions you add.
            </p>
          </div>
          <div className="border-t p-9">
            <p className="mb-9 text-sm">
              If you have a physical office in {region.name}, print the notices
              and post them in an area that is accessible and visible to all
              employees.
            </p>
            <NoticeUrlDisplay region={region} />
          </div>
        </div>
        {nextStepData && (
          <div className="flex w-full justify-end pb-9 pr-9">
            <Button
              size="xlarge"
              className="mt-4"
              onClick={() => {
                if (!nextStepData) {
                  return;
                }

                const locationSetupCompleteness = {
                  section: nextStepData.section.id,
                  requirement: nextStepData.requirement.data_id,
                };

                localStorage.setItem(
                  `location_setup_completeness_${region.code}`,
                  JSON.stringify(locationSetupCompleteness),
                );

                const queryParams = new URLSearchParams({
                  section: nextStepData.section.id,
                  requirement: nextStepData.requirement.data_id,
                });

                navigate(`${pathname}?${queryParams.toString()}`);
                onNext();
              }}
              rightIcon={<ArrowRightIcon className="w-4" />}
            >
              Next step
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

type NoticeEnrollmentViewNewProps = {
  locationId: string;
  requirementId: string;
  nextStepData: NextStepDataProps | null;
  onNext: () => void;
};

export const NoticeEnrollmentViewNew: FunctionComponent<
  NoticeEnrollmentViewNewProps
> = ({ locationId, requirementId, nextStepData, onNext }) => {
  if (!locationId || !requirementId) {
    return <FatalError />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const batchResponse = useBatchApi([
    {
      url: `/api/notices/enrollment/${locationId}/${requirementId}`,
      method: 'POST',
    },
    {
      url: `/api/regions/${locationId}`,
      method: 'GET',
    },
  ]);

  const componentPropsFn = ([
    enrollmentResponse,
    regionResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): NoticeEnrollmentNewProps => {
    return {
      publicKey: enrollmentResponse.public_key,
      region: regionResponse,
      nextStepData,
      onNext,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={NoticeEnrollmentNew}
      componentProps={componentPropsFn}
    />
  );
};
