import { SubscriptionPlanEnum } from '../types';
import { Feature } from '../types/features';

const FEATURE_PLAN_MAP = {
  [Feature.MailroomAddress]: [
    SubscriptionPlanEnum.ScaleUp,
    SubscriptionPlanEnum.Plus,
    SubscriptionPlanEnum.Standard,
    SubscriptionPlanEnum.Advanced,
  ],
};

export function planHasAccessToFeature(
  plan: SubscriptionPlanEnum | undefined,
  feature: Feature,
) {
  if (!FEATURE_PLAN_MAP[feature]) {
    return true;
  }
  if (!plan) {
    return false;
  }
  return FEATURE_PLAN_MAP[feature].includes(plan);
}
