import { ChevronRightIcon } from '@heroicons/react/outline';
import { PolicySection } from '../types';
import { Link } from 'react-router-dom';

type SectionTitleProps = {
  section: PolicySection;
};

export const SectionTitle = ({ section }: SectionTitleProps) => {
  return (
    <div className="mb-4 mt-6">
      <Link
        to={`/handbook/section/${section.id}/policies`}
        className="group inline-block rounded outline-none focus-visible:ring-2 focus-visible:ring-rose-600"
      >
        <h3 className="text-lg font-medium text-teal-800 group-hover:text-teal-900">
          {section.title}
          <ChevronRightIcon className="mb-0.5 ml-1 inline size-4" />
        </h3>
      </Link>
      {section.description && <p>{section.description}</p>}
    </div>
  );
};
