import { useRouteLoaderData } from 'react-router-dom';
import { HandbookDataLoader } from '../types';

export function usePolicyDetails(policyId: string) {
  const { policies, draft, published } = useRouteLoaderData(
    'handbook',
  ) as HandbookDataLoader;

  const policy = policies.find((p) => p.id === policyId);
  const draftInstance = draft?.policy_instances.find(
    (pi) => pi.policy_id === policyId,
  );
  const publishedInstance = published?.policy_instances.find(
    (pi) => pi.policy_id === policyId,
  );

  return {
    isUpdated:
      draftInstance &&
      publishedInstance &&
      draftInstance.policy_version !== publishedInstance.policy_version,
    isAdopted: !!draftInstance,
    isAdoptable: !draftInstance,
    isUpdatable:
      draftInstance && draftInstance?.policy_version !== policy?.version,
  };
}
