import { Button } from '@mosey/components/buttons/Button';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageNavLink } from '@mosey/components/page-header/PageNavLink';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { Beta } from '@mosey/components/badges/Beta';
import { Link, useRouteLoaderData } from 'react-router-dom';
import { HandbookDataLoader } from '../types';

export const HandbookHeader = () => {
  // Add submit and state as props
  const { draft } = useRouteLoaderData('handbook') as HandbookDataLoader;

  return (
    <PageHeader>
      <PageTitle>
        Handbook <Beta />
      </PageTitle>
      <nav>
        <ul className="flex items-center">
          <li>
            <PageNavLink to="overview">Overview</PageNavLink>
          </li>
          <li className="ml-10">
            <Button
              as={Link}
              to="/handbook/publish"
              isDisabled={!draft || !draft.is_publishable}
            >
              Publish
            </Button>
          </li>
        </ul>
      </nav>
    </PageHeader>
  );
};
