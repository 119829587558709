import { useGlobalContext } from '../../contexts/globalContext';
import { getPlatform } from '../../utils/platform';
import { clsx } from 'clsx';
import logo from '../../assets/logo.svg';
import { Platform } from '../../types';

interface AuthlessPlatformNavProps {
  children: React.ReactNode;
  platform?: Platform;
}

export const AuthlessPlatformNav = ({
  children,
  platform,
}: AuthlessPlatformNavProps) => {
  const platformName = platform?.name;
  const platformConfig = platformName ? getPlatform(platformName) : undefined;
  return (
    <div className="flex h-screen flex-col items-center overflow-y-auto bg-gray-50">
      <header className="sticky top-0 z-10 mb-1 w-full bg-gray-50 py-5">
        <div className="m-auto flex max-w-3xl items-center gap-4">
          <img src={logo} alt="Mosey Logo" className="mt-1 h-5" />
          {platformConfig && (
            <>
              <span className="text-lg text-gray-200">|</span>
              <img
                src={platformConfig.iconPath}
                alt={`${platformConfig.name} Logo`}
                className={clsx('mt-0.5', platformConfig.height || 'h-5')}
              />
            </>
          )}
        </div>
      </header>
      <main className="mb-10 w-full max-w-3xl grow rounded bg-white shadow">
        {children}
      </main>
    </div>
  );
};

interface PlatformNavProps {
  children: React.ReactNode;
}

export const PlatformNav = ({ children }: PlatformNavProps) => {
  const { globalState } = useGlobalContext();

  return (
    <AuthlessPlatformNav
      platform={globalState.user.legal_entity?.platforms?.[0]}
    >
      {children}
    </AuthlessPlatformNav>
  );
};
