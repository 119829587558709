import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronDownIcon } from '@mosey/components/Icons';
import { PolicyMarkdown } from '@mosey/components/handbook/PolicyMarkdown';
import { PolicyContentType } from '@mosey/components/handbook/types';

type PolicyFullDetailsProps = {
  fullDetails: string;
};

export const PolicyFullDetails = ({ fullDetails }: PolicyFullDetailsProps) => {
  return (
    <Disclosure>
      <DisclosureButton className="group flex w-full cursor-pointer gap-1 rounded py-2 text-sm font-medium text-amber-900 outline-none focus-visible:ring-2 focus-visible:ring-rose-600">
        Full policy details
        <ChevronDownIcon className="size-5 transition-all group-data-[open]:-rotate-180" />
      </DisclosureButton>
      <DisclosurePanel className="mt-0.5 rounded bg-amber-50 p-4 text-sm text-amber-900">
        <PolicyMarkdown
          content={{
            type: PolicyContentType.Markdown,
            content: fullDetails,
          }}
        />
      </DisclosurePanel>
    </Disclosure>
  );
};
