import { Navigate, useParams } from 'react-router-dom';
import {
  useNextTaskUrl,
  useResolverLocation,
  useTasks,
  useTodoTasks,
} from '../utils/hooks';
import { ResolverType, LocationResolverRouteParams } from '../utils/types';
import { InterstitialContainer } from '../tasks/InterstitialContainer';
import { AssessmentGraphic } from './AssessmentGraphic';
import { SetupGraphic } from './SetupGraphic';
import { useUser } from '../../../hooks/useUser';

export const ResolverIntro = () => {
  const { resolverType = ResolverType.Assessment } =
    useParams<LocationResolverRouteParams>();
  const tasks = useTasks();
  const todoTasks = useTodoTasks();
  const nextTaskUrl = useNextTaskUrl();
  const location = useResolverLocation();

  const isResolverUser = useUser().legal_entity.use_resolvers;

  if (tasks.length === 0) {
    return <Navigate to="/home" replace />;
  }

  if (
    !location ||
    (todoTasks.length < tasks.length &&
      /**
       * TODO: The check for resolverType here is only necessary until ENG-196 is
       * complete (https://www.notion.so/moseyworks/Setup-should-not-show-assessment-questions-in-sidebar-3de4cc52bcc54321bc53f6c3d5a46732?pvs=4)
       */
      resolverType === ResolverType.Assessment) ||
    !isResolverUser
  ) {
    return <Navigate to={nextTaskUrl || '/home'} replace />;
  }

  if (todoTasks.length === 0) {
    return <Navigate to="complete" replace />;
  }

  if (tasks.length === 1) {
    return <Navigate to={`tasks/${tasks[0].id}`} replace />;
  }

  const percentComplete =
    (resolverType === ResolverType.Assessment ? 1 / 3 : 2 / 3) * 100;

  return (
    <InterstitialContainer
      percentComplete={percentComplete}
      title={
        resolverType === ResolverType.Assessment
          ? 'Answer questions'
          : 'Complete setup tasks'
      }
      description={
        resolverType === ResolverType.Assessment
          ? 'Every business is unique. Your answers to a handful of questions will generate setup tasks tailored to your situation.'
          : 'Automate many tedious processes. For everything else, follow step-by-step instructions with resources to help you along the way.'
      }
    >
      <div className="-mb-10 max-w-[860px]">
        {resolverType === ResolverType.Assessment
          ? AssessmentGraphic
          : SetupGraphic}
      </div>
    </InterstitialContainer>
  );
};
