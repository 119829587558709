import { Outlet } from 'react-router-dom';
import { GlobalContextProvider } from '../contexts/globalContext';
import { useUser } from '../hooks/useUser';

export const Component = () => {
  const user = useUser();

  return (
    <div className="h-screen overflow-hidden">
      <GlobalContextProvider
        initState={{ user, hasCheckedBasicPlanOnboarding: false }}
      >
        <Outlet />
      </GlobalContextProvider>
    </div>
  );
};
