export enum PolicyContentType {
  Markdown = 'markdown',
  MultiColumnList = 'multicolumnlist',
}

type BaseContent = {
  audience?: string;
};

export type PolicyMarkdownContent = BaseContent & {
  type: PolicyContentType.Markdown;
  content: string;
};

export type PolicyMultiColumnListContent = BaseContent & {
  type: PolicyContentType.MultiColumnList;
  items: Array<string>;
  columns: number;
};

export type PolicyContentMain =
  | PolicyMarkdownContent
  | PolicyMultiColumnListContent;

export type PolicyContentResource = {
  title: string;
  url: string;
  audience?: string;
};
