import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { Section } from '../components';
import requirementCompleteImage from '../assets/requirement_complete.png';

type OnboardingBusinessProfileSuccessProps = {
  onClick?: () => void;
};

export const OnboardingBusinessProfileSuccess: FunctionComponent<
  OnboardingBusinessProfileSuccessProps
> = ({ onClick }) => {
  const navigate = useNavigate();

  return (
    <Section>
      <div className="mt-32 flex flex-col items-center justify-center space-y-8">
        <img
          src={requirementCompleteImage}
          alt="Requirement complete"
          className="ml-12 h-44 w-56"
        />
        <div className="text-5xl font-extrabold leading-10 text-gray-700">
          Got it!
        </div>
        <p className="max-w-md text-center">
          You&apos;ve successfully created your business profile. You can now
          register in a new state or import your existing states.
        </p>
        <Button
          type="button"
          onClick={() => {
            navigate('/locations/usa/setup');

            if (onClick) onClick();
          }}
          size="large"
        >
          Add location
        </Button>
      </div>
    </Section>
  );
};
