import { PolicyContentRenderer } from '@mosey/components/handbook/PolicyContentRenderer';
import { AttributeValues, Policy } from '../types';
import { Button } from '@mosey/components/buttons/Button';
import { usePolicyDetails } from '../hooks/usePolicyDetails';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useMemo } from 'react';
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
import { fillPolicyContent } from '../utils';
import { PolicyFullDetails } from '../section/FullDetails';
import { getStateSealImage } from '../../../utils/seals';
import { USStateAbbrev } from '@mosey/utils/constants/us-states';

type PolicyDetailSectionProps = {
  policy: Policy;
};

export const PolicyDetailSection = ({ policy }: PolicyDetailSectionProps) => {
  const { isAdoptable, isUpdatable, isUpdated } = usePolicyDetails(policy.id);
  const { Form, state } = useFetcher();
  const attributeValues = useRouteLoaderData(
    'policy-section-details',
  ) as AttributeValues;

  const content = useMemo(
    () => fillPolicyContent(policy, attributeValues),
    [attributeValues, policy],
  );

  return (
    <Form id={policy.slug} className="mb-10" method="POST">
      <input type="hidden" name="policy_id" value={policy.id} />
      <div className="mb-3 flex min-h-12 items-center gap-3 border-b-1 border-gray-200 pb-2">
        {policy.policy_scope?.region && (
          <img
            className="size-10"
            src={getStateSealImage(
              policy.policy_scope.region.code as USStateAbbrev,
            )}
            alt={`State seal of ${policy.policy_scope.region.name}`}
          />
        )}
        <div className="flex flex-1 flex-col">
          <h2 className="font-semibold">{policy.title}</h2>
          {policy.policy_scope?.region && (
            <p className="text-sm text-gray-700">
              Applies to {policy.policy_scope.region.name}-based employees
            </p>
          )}
        </div>
        {isUpdatable || isAdoptable ? (
          <Button
            variant="secondary"
            type="submit"
            name="intent"
            value="adopt-policy"
            isLoading={state !== 'idle'}
            aria-label={`${isUpdatable ? 'Update' : 'Adopt'} ${policy.title}`}
          >
            {isUpdatable ? 'Update' : 'Adopt'}
          </Button>
        ) : (
          <div className="flex items-center gap-2">
            <CheckCircleIcon className="size-4 text-teal-700" />
            <p className="text-sm font-bold text-zinc-700">
              {isUpdated ? 'Updated' : 'Adopted'}
            </p>
          </div>
        )}
      </div>
      <div className="max-w-2xl">
        {content.main.map((item, index) => (
          <PolicyContentRenderer key={index} content={item} />
        ))}
        {content.full_details && (
          <div className="mt-3">
            <PolicyFullDetails fullDetails={content.full_details} />
          </div>
        )}
      </div>
    </Form>
  );
};
