import { useGlobalContext } from '../contexts/globalContext';
import { Requirement } from '../types';
import { isDomestic } from '../utils/legal-entity';

export const useRequirementFee = (requirement?: Requirement) => {
  const { globalState } = useGlobalContext();
  if (!requirement) {
    return undefined;
  }
  return isDomestic(globalState.user.legal_entity, requirement.region[0])
    ? requirement.domestic_fee
    : requirement.foreign_fee;
};
