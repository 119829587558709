import { CheckIcon } from '@heroicons/react/solid';
import { useCopy } from '../hooks/useCopy';
import { CopyIcon } from '../Icons';
import { useState } from 'react';
import {
  Tooltip,
  Button,
  TooltipTrigger,
  OverlayArrow,
} from 'react-aria-components';

type CopyFieldProps = {
  children: string;
  label: string;
  id: string;
};

const COPY_DELAY = 2000;

export const CopyField = ({ label, children }: CopyFieldProps) => {
  const [open, setOpen] = useState(false);
  const [onCopy, isCopied] = useCopy(children, COPY_DELAY);

  return (
    <TooltipTrigger
      delay={0}
      closeDelay={0}
      onOpenChange={setOpen}
      isOpen={open || isCopied}
    >
      <Button
        className="flex w-fit items-center gap-2 rounded border border-teal-350 bg-teal-100 px-3 py-2 text-teal-800 outline-none ring-rose-600 hover:bg-teal-50 data-[focus-visible]:ring-2"
        aria-label={`Copy ${label}`}
        onPressStart={onCopy}
        type="button"
      >
        <span>{children}</span>
        {isCopied ? (
          <CheckIcon className="size-4" />
        ) : (
          <CopyIcon className="size-4" />
        )}
      </Button>
      <Tooltip
        className="rounded bg-black px-4 py-3 text-sm text-white"
        offset={5}
      >
        <OverlayArrow className="pointer-events-none">
          <svg width={16} height={16} viewBox="0 0 8 8">
            <path d="M0 0 L4 4 L8 0" />
          </svg>
        </OverlayArrow>
        {isCopied ? 'Copied!' : `Copy ${label}`}
      </Tooltip>
    </TooltipTrigger>
  );
};
