import { Outlet } from 'react-router-dom';
import { HandbookHeader } from './components/HandbookHeader';

export const Handbook = () => {
  return (
    <section className="flex h-full flex-col">
      <HandbookHeader />
      <main className="grow">
        <Outlet />
      </main>
    </section>
  );
};
