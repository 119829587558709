import {
  convertDateStringToLocalDate,
  getLocalTodayMidnight,
} from '@mosey/utils/dates';
import { BaseTask, Task, TaskRef, TaskStatus, TaskType } from './types';

export const isTaskTodo = ({ status }: BaseTask) => status === TaskStatus.Todo;

export const getNextTask = (tasks: TaskRef[], taskId?: BaseTask['id']) => {
  if (taskId) {
    const currentIndex = tasks.findIndex(({ id }) => id === taskId) + 1;

    return tasks
      .slice(currentIndex)
      .concat(tasks.slice(0, currentIndex))
      .find(isTaskTodo);
  }

  return tasks.find(isTaskTodo);
};

export const isTaskOverdue = ({ status, source }: Task | TaskRef) => {
  if (source.type === TaskType.Requirement) {
    const { due_date: dueDate, managed_provider: managedProvider } = source;

    if (managedProvider) {
      return false;
    }

    if (dueDate && status !== TaskStatus.Done) {
      const localToday = getLocalTodayMidnight();
      const localDueDate = convertDateStringToLocalDate(dueDate);

      return localDueDate < localToday;
    }
  }

  return false;
};
