import { useRouteLoaderData } from 'react-router-dom';
import { HandbookDataLoader } from '../types';
import { groupPoliciesBySections } from '../utils';
import { useMemo } from 'react';

export function usePolicies(sectionId?: string) {
  const { policies, draft } = useRouteLoaderData(
    'handbook',
  ) as HandbookDataLoader;

  const sections = groupPoliciesBySections(policies);

  const { currentSection, nextSection } = useMemo(() => {
    for (let index = 0; index < sections.length; index++) {
      if (sections[index].section.id === sectionId) {
        return {
          currentSection: sections[index],
          nextSection: sections[index + 1],
        };
      }
    }
    return {
      currentSection: sections[0],
      nextSection: sections[1],
    };
  }, [sections, sectionId]);

  const relevantPolicies = sectionId
    ? policies.filter((policy) => policy.policy_section.id === sectionId)
    : policies;

  const actionablePolicies = relevantPolicies.filter(
    (policy) =>
      !draft?.policy_instances.some(
        (instance) =>
          instance.policy_id === policy.id &&
          instance.policy_version === policy.version,
      ),
  );

  return {
    policies: relevantPolicies,
    hasActionablePolicies: actionablePolicies.length > 0,
    currentSection,
    nextSection,
    sections,
  };
}
