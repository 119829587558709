import { useState, FunctionComponent, MouseEvent, useEffect } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { USStateName } from '@mosey/utils/constants/us-states';
import { ArrowRightIcon, ErrorIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { formatCriteriaQuestion } from '../utils/format';
import { fetchApi } from '../utils/fetchApi';
import {
  CriteriaType,
  LegalEntityRegionRef,
  Requirement,
  RequirementStatus,
  criteriaTypesToSkipJM,
} from '../types';
import { Section } from './base';
import { RequirementContentWrapper } from '../views/LocationDetailSetup_NEW';
import { useScrollIntoView } from '@mosey/components/hooks';

enum IsApplicableEnum {
  Yes = 'yes',
  No = 'no',
}

type CriteriaOptionsForm = {
  isApplicable: IsApplicableEnum;
};

const CriteriaOptionsFormResolver: Resolver<CriteriaOptionsForm> = async (
  values,
) => {
  return {
    values: values.isApplicable ? values : {},
    errors:
      values.isApplicable === null
        ? {
            isApplicable: {
              type: 'required',
              message: 'At least one option is required.',
            },
          }
        : {},
  };
};

type CriteriaQuestionsContentProps = {
  legalEntityRegion: LegalEntityRegionRef;
  requirementData: Requirement;
  onComplete: () => void;
  onDefer: (e?: MouseEvent) => Promise<void>;
  onSkip?: () => Promise<void>;
};

const CriteriaQuestionsContent: FunctionComponent<
  CriteriaQuestionsContentProps
> = ({ legalEntityRegion, requirementData, onComplete, onDefer, onSkip }) => {
  const [criteriaIndex, setCriteriaIndex] = useState<number>(0);
  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);
  const [isSkipLoading, setIsSkipLoading] = useState<boolean>(false);
  const isAnyButtonLoading = isSkipLoading || isNextLoading;
  const formMethods = useForm<CriteriaOptionsForm>({
    resolver: CriteriaOptionsFormResolver,
  });

  if (
    requirementData.status == RequirementStatus.Done ||
    !requirementData.criteria
  ) {
    // if no criteria, assume the criteria questions have been completed
    onComplete();
    return <></>;
  }

  const {
    handleSubmit,
    register,
    setError,
    reset: resetForm,
    formState: { errors },
  } = formMethods;

  const foundRegion = Object.entries(USStateName).find((entry) => {
    const [code] = entry;
    return code.toUpperCase() === requirementData.region[0].toUpperCase();
  });
  if (!foundRegion) {
    // if no valid region, complete the criteria instead of showing an error
    onComplete();
    return <></>;
  }

  const [, regionName] = foundRegion;
  const currentCriteria = requirementData.criteria[criteriaIndex];
  const formattedQuestion: string = formatCriteriaQuestion(
    currentCriteria,
    regionName,
  );

  const markCriteriaSatisfied = async (isSatisfied: boolean) => {
    setIsNextLoading(true);
    const reqCriteriaSatisfiedResponse = await fetchApi({
      url: `/api/requirements/${requirementData.id}`,
      method: 'PATCH',
      body: { operation: 'mark-criteria-satisfied', is_satisfied: isSatisfied },
    });

    if (reqCriteriaSatisfiedResponse.error) {
      const err = {
        type: 'manual',
        message: `Something went wrong, we're looking into it.`,
      };
      setError('isApplicable', err);
      setIsNextLoading(false);
      return;
    }
    setIsNextLoading(false);
  };

  const iterateNextCriteria = async () => {
    if (!requirementData.criteria) {
      return;
    }
    if (criteriaIndex === requirementData.criteria.length - 1) {
      // since this is the last criteria and the answer is not no, we assume that
      // all criteria are satisfied, and we will update the LegalEntityRequirement
      await markCriteriaSatisfied(true);
      onComplete();
    } else {
      // reset form for the next criteria
      resetForm();
      // iterate to next criteria question
      setCriteriaIndex(criteriaIndex + 1);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (
      (currentCriteria.type === CriteriaType.RegionHasEmployees &&
        legalEntityRegion.has_employees) ||
      (currentCriteria.type === CriteriaType.RegionHasPhysicalLocation &&
        legalEntityRegion.has_physical_location) ||
      criteriaTypesToSkipJM.includes(currentCriteria.type) // skip criteria for JM
    ) {
      iterateNextCriteria();
    }
  }, [criteriaIndex]);

  const handleSkipRequirement = async () => {
    if (!onSkip) return;
    setIsSkipLoading(true);
    await onSkip();
    setIsSkipLoading(false);
  };

  const onSubmit = async (data: CriteriaOptionsForm) => {
    setIsNextLoading(true);

    if (!requirementData.criteria) {
      // should never be true given we're checking against this at the top
      // adding this to satisfy compilier
      return;
    }

    if (data.isApplicable === IsApplicableEnum.No) {
      // exit criteria questions early since not all criteria are satisifed
      await markCriteriaSatisfied(false);
      setIsNextLoading(false);
      await onDefer();
      return;
    }

    if (data.isApplicable === IsApplicableEnum.Yes) {
      await iterateNextCriteria();
      setIsNextLoading(false);
    }
  };

  return (
    <>
      <h3 className="mb-6 text-lg font-bold">{formattedQuestion}</h3>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="divide-y-2 divide-sage-100 bg-white">
            <div>
              <label className="cursor-pointer text-lg font-bold" htmlFor="yes">
                <div className="flex items-center gap-x-4 px-6 py-3">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    value="yes"
                    id="yes"
                    defaultChecked={false}
                    disabled={isAnyButtonLoading}
                    {...register('isApplicable')}
                  />
                  Yes
                </div>
              </label>
            </div>
            <div>
              <label className="cursor-pointer text-lg font-bold" htmlFor="no">
                <div className="flex items-center gap-x-4 px-6 py-3">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    value="no"
                    id="no"
                    defaultChecked={false}
                    disabled={isAnyButtonLoading}
                    {...register('isApplicable')}
                  />
                  No
                </div>
              </label>
            </div>
          </div>
          {errors.isApplicable && (
            <div className="mt-2 flex items-center text-xs text-red-600">
              <span>
                <ErrorIcon className="mr-1 size-4" />
              </span>
              <div>
                <p>{`${
                  errors.isApplicable.message || 'Something went wrong.'
                }`}</p>
              </div>
            </div>
          )}

          <div className="mt-6 flex gap-x-6">
            {onSkip ? (
              <Button
                type="button"
                isFullWidth
                disabled={isAnyButtonLoading}
                isLoading={isSkipLoading}
                onClick={handleSkipRequirement}
                variant="secondary"
                size="xlarge"
              >
                Skip for now
              </Button>
            ) : (
              <div className="w-full" />
            )}
            <Button
              disabled={isAnyButtonLoading}
              isLoading={isNextLoading}
              isFullWidth
              rightIcon={<ArrowRightIcon className="w-4" />}
              size="xlarge"
              className="right-0"
            >
              Next
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

type CriteriaQuestionsProps = {
  legalEntityRegion: LegalEntityRegionRef;
  requirementData: Requirement;
  onComplete: () => void;
  onDefer: (e?: MouseEvent) => Promise<void>;
};

export const CriteriaQuestions: FunctionComponent<CriteriaQuestionsProps> = (
  props,
) => {
  return (
    <Section>
      <div className="rounded-sm bg-sage-100 p-9">
        <CriteriaQuestionsContent {...props} />
      </div>
    </Section>
  );
};

type CriteriaQuestionsNewProps = CriteriaQuestionsProps & {
  onSkip: (e?: MouseEvent) => Promise<void>;
};

export const CriteriaQuestionsNew: FunctionComponent<
  CriteriaQuestionsNewProps
> = ({ legalEntityRegion, requirementData, onComplete, onDefer, onSkip }) => {
  const [scrollIntoViewRef] = useScrollIntoView();

  return (
    <RequirementContentWrapper
      title={requirementData.title}
      description={requirementData.description}
      resources={requirementData.resources}
      ref={scrollIntoViewRef}
    >
      <CriteriaQuestionsContent
        legalEntityRegion={legalEntityRegion}
        requirementData={requirementData}
        onComplete={onComplete}
        onDefer={onDefer}
        onSkip={onSkip}
      />
    </RequirementContentWrapper>
  );
};
