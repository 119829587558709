import { FunctionComponent } from 'react';
import { Button } from '@mosey/components/buttons/Button';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

type QuestionTasksBannerProps = {
  regionCode: string;
  isSetup: boolean;
};

export const QuestionTasksBanner: FunctionComponent<
  QuestionTasksBannerProps
> = ({ regionCode, isSetup }) => {
  return (
    <div className="flex px-6 pt-6">
      <div className="flex w-full items-center justify-between rounded-sm border border-solid border-teal-400 bg-teal-50 px-4 py-3 text-teal-900">
        <div className="flex items-center gap-x-2">
          <InformationCircleIcon className="size-6 self-center stroke-1" />
          <div className="text-base font-medium">
            Additonal information required
          </div>
          <div>&#8212;</div>
          <div className="text-base font-normal">
            Your answers to a handful of questions will generate{' '}
            {isSetup && 'setup'} tasks tailored to your situation
          </div>
        </div>
        <Button
          type="button"
          variant="secondary"
          as={Link}
          to={`/locations/usa/${regionCode.toLowerCase()}/resolver/${isSetup ? 'assessment' : 'question'}`}
        >
          Answer Questions
        </Button>
      </div>
    </div>
  );
};
