import { useState } from 'react';

export function useCopy(
  valueToCopy: string,
  timeoutMs = 1000,
): [() => void, boolean] {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const onCopy = () => {
    navigator.clipboard.writeText(valueToCopy);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), timeoutMs);
  };

  return [onCopy, isCopied];
}
