import { FunctionComponent, useState } from 'react';

import {
  QuickFilters,
  RequirementsTable,
  BatchApiStatusHandler,
} from '../components';
import { QuestionTasksBanner } from '../components/QuestionTasksBanner';
import { useApi, useBatchApi } from '../hooks';
import { LegalEntityRegion, RequirementOverview } from '../types';
import {
  FilterRequirementsBy,
  filterRequirements,
  getCountForFilter,
} from '../utils/filter';
import { computedRequirementCounts } from '../utils/stats';
import { isoDate } from '../utils/format';
import { USStateId } from '@mosey/utils/constants/us-states';
import {
  ResolverType,
  TaskQuestion,
  TaskStatus,
} from './tasks-framework/utils/types';

type LocationDetailRecurringProps = {
  legalEntityRegion: LegalEntityRegion;
  data: RequirementOverview[];
};

export const LocationDetailRecurring: FunctionComponent<
  LocationDetailRecurringProps
> = ({ legalEntityRegion, data }) => {
  const requirements = data.filter((r) => r.schedule.category !== 'one-time');
  const computedReqCounts = computedRequirementCounts(requirements);
  const [requirementsForTable, setRequirementsForTable] =
    useState<RequirementOverview[]>(requirements);
  const [statusFilter, setFilter] = useState<FilterRequirementsBy>(
    FilterRequirementsBy.All,
  );

  const taskSearchParams = new URLSearchParams();
  const regionId =
    USStateId[
      legalEntityRegion.region.code.toUpperCase() as keyof typeof USStateId
    ];
  taskSearchParams.set('region_id', regionId);
  taskSearchParams.set('is_setup', 'false');
  taskSearchParams.set('task_type', ResolverType.Question);
  taskSearchParams.set('statuses', TaskStatus.Todo);
  const { error, data: questionTasksData } = useApi({
    url: `/api/compliance/tasks?${taskSearchParams.toString()}`,
    method: 'GET',
  });
  let questionTasks: TaskQuestion[] = [];
  if (!error) {
    questionTasks = questionTasksData;
  }

  const handleStatusFilterClick = (reqFilter: FilterRequirementsBy): void => {
    setFilter(reqFilter);
    setRequirementsForTable(filterRequirements(requirements, reqFilter));
  };

  const allowedFilters = [
    FilterRequirementsBy.All,
    FilterRequirementsBy.Upcoming,
    FilterRequirementsBy.Done,
    FilterRequirementsBy.Overdue,
    FilterRequirementsBy.Locked,
  ];
  const regionCode = requirements[0].region[0].toLowerCase();

  return (
    <>
      {legalEntityRegion.is_setup_complete &&
        questionTasks &&
        questionTasks.length > 0 && (
          <QuestionTasksBanner regionCode={regionCode} isSetup={false} />
        )}
      <div className="px-6 pb-6 pt-4">
        <div className="flex items-center pb-4">
          <h2 className="flex-1 font-bold leading-8 text-zinc-800">
            Requirements
          </h2>
          <QuickFilters
            items={allowedFilters.map((f) => {
              return {
                label: f,
                count: getCountForFilter(computedReqCounts, f),
                isSelected: statusFilter === f,
              };
            })}
            onClick={handleStatusFilterClick}
          />
        </div>
        <div className="min-h-full bg-white">
          <RequirementsTable requirements={requirementsForTable} />
        </div>
      </div>
    </>
  );
};

type LocationDetailRecurringViewProps = {
  legalEntityRegion: LegalEntityRegion;
};

export const LocationDetailRecurringView: FunctionComponent<
  LocationDetailRecurringViewProps
> = ({ legalEntityRegion }) => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 60);
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 90);
  const regionCodes = [legalEntityRegion.region.code.toLowerCase()];

  const batchResponse = useBatchApi(
    [
      {
        url: '/api/requirements',
        method: 'POST',
        body: {
          start_date: isoDate(startDate), // eslint-disable-line
          end_date: isoDate(endDate), // eslint-disable-line
          region_codes: regionCodes, // eslint-disable-line
          include_virtual: true, // eslint-disable-line
        },
      },
    ],
    [],
  );

  const componentPropsFn = ([
    requirementsResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): LocationDetailRecurringProps => {
    return {
      legalEntityRegion,
      data: requirementsResponse.data as RequirementOverview[],
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      componentProps={componentPropsFn}
      component={LocationDetailRecurring}
    />
  );
};
