import { NavLink, Outlet } from 'react-router-dom';
import {
  MenuItem,
  MenuItemVariants,
  MenuList,
} from '../components/navigation/Menu';
import Section from '../components/base/Section';
import { SectionHeading } from '../components/base/SectionHeading';
import { useGlobalContext } from '../contexts/globalContext';
import { planHasAccessToFeature } from '../utils/plans';
import { Feature } from '../types/features';

export const SettingsView = () => {
  const {
    globalState: {
      user: {
        legal_entity: { subscription_plan: plan },
      },
    },
  } = useGlobalContext();

  return (
    <Section className="relative flex h-full flex-col" isFullscreen>
      <SectionHeading className="px-6 py-5" text="Settings" />
      <div className="flex w-full flex-1 flex-col">
        <div className="flex flex-1">
          <aside className="w-[256px] shrink-0">
            <MenuList>
              <NavLink to="/settings/account">
                {({ isActive }) => {
                  return (
                    <MenuItem
                      variant={MenuItemVariants.Small}
                      isActive={isActive}
                    >
                      <span>Account</span>
                    </MenuItem>
                  );
                }}
              </NavLink>
              <NavLink to="/settings/business-information">
                {({ isActive }) => {
                  return (
                    <MenuItem
                      variant={MenuItemVariants.Small}
                      isActive={isActive}
                    >
                      <span>Business Information</span>
                    </MenuItem>
                  );
                }}
              </NavLink>
              <NavLink to="/settings/integrations">
                {({ isActive }) => {
                  return (
                    <MenuItem
                      variant={MenuItemVariants.Small}
                      isActive={isActive}
                    >
                      <span>Integrations</span>
                    </MenuItem>
                  );
                }}
              </NavLink>
              <NavLink to="/settings/officers-and-owners">
                {({ isActive }) => {
                  return (
                    <MenuItem
                      variant={MenuItemVariants.Small}
                      isActive={isActive}
                    >
                      <span>Officers and Owners</span>
                    </MenuItem>
                  );
                }}
              </NavLink>
              {planHasAccessToFeature(plan, Feature.MailroomAddress) && (
                <NavLink to="/settings/mailroom">
                  {({ isActive }) => {
                    return (
                      <MenuItem
                        variant={MenuItemVariants.Small}
                        isActive={isActive}
                      >
                        <span>Mailroom</span>
                      </MenuItem>
                    );
                  }}
                </NavLink>
              )}
            </MenuList>
          </aside>
          <main className="flex-1">
            <Outlet />
          </main>
        </div>
      </div>
    </Section>
  );
};
