import { CheckCircleIcon, LockClosedIcon } from '@heroicons/react/solid';
import { TaskCardStatus } from '../layout/types';
import { Pill, PillProps } from './Pill';
import {
  StopwatchIcon,
  RobotWithCheckIcon,
  RobotWithRefreshIcon,
  TodoCircleIcon,
  IconProps,
  RobotWithClockIcon,
} from '../Icons';

interface TaskStatusPillProps
  extends Omit<PillProps, 'Icon' | 'variant' | 'children'> {
  children?: React.ReactNode;
  status:
    | Exclude<TaskCardStatus, 'setup-collection' | 'question-collection'>
    | 'managed'
    | 'locked';
}

const getNewIcon = (isCircle: PillProps['isCircle']) => {
  const NewIcon = (props: IconProps) => {
    return (
      <div className="relative">
        <TodoCircleIcon {...props} />
        {isCircle && (
          <p className="absolute -bottom-3 left-1/2 -translate-x-1/2 rounded-lg bg-teal-800 px-1 py-[2px] text-[7px] font-medium uppercase leading-[7px] text-rose-50">
            New
          </p>
        )}
      </div>
    );
  };

  return NewIcon;
};

export const TaskStatusPill = ({
  status,
  children,
  ...rest
}: TaskStatusPillProps) => {
  let content = 'To do';
  let Icon = TodoCircleIcon;
  let variant: PillProps['variant'] = 'secondary';

  switch (status) {
    case 'overdue':
      content = 'Overdue';
      variant = 'primary';
      Icon = StopwatchIcon;
      break;

    case 'done':
      content = 'Done';
      variant = 'success';
      Icon = CheckCircleIcon;
      break;

    case 'locked':
      content = 'Locked';
      variant = 'inactive';
      Icon = LockClosedIcon;
      break;

    case 'in-progress':
      content = 'In progress';
      variant = 'automation';
      Icon = RobotWithClockIcon;
      break;

    case 'automated':
      content = 'Automated';
      variant = 'automation';
      Icon = RobotWithCheckIcon;
      break;

    case 'managed':
      content = 'Managed';
      variant = 'automation';
      Icon = RobotWithRefreshIcon;
      break;

    case 'new':
      Icon = getNewIcon(rest.isCircle);
      break;
  }

  return (
    <Pill Icon={Icon} variant={variant} {...rest}>
      {children || content}
    </Pill>
  );
};
