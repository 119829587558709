import { FunctionComponent, useState } from 'react';
import { useSearchParams, useMatch } from 'react-router-dom';
import { KeyedBatchApiStatusHandler } from '../components';
import { useGlobalContext } from '../contexts/globalContext';
import { useKeyedBatchApi } from '../hooks';
import { convertDateToUTC, threeMonthPeriod } from '@mosey/utils/dates';
import { isoDate } from '../utils/format';
import { isDone } from '../utils/requirement';
import { LegalEntityRegion } from '../types';
import { MailRoom } from './MailRoom';
import type { MailRoomProps } from './MailRoom';
import type { IFetchApi } from '../utils/types';

type MailRoomViewProps = {
  legalEntityRegion?: LegalEntityRegion;
};

export const MailRoomView: FunctionComponent<MailRoomViewProps> = ({
  legalEntityRegion,
}) => {
  const isGlobal = useMatch('/mail');
  const [searchParams] = useSearchParams();
  const mailId = searchParams.get('mail_id') || undefined;

  const {
    globalState: {
      user: {
        legal_entity: { regions, incorporation_region: incorporationRegion },
      },
    },
  } = useGlobalContext();
  const stateCode = (
    legalEntityRegion?.region.code || searchParams.get('region')
  )?.toLowerCase();

  const isIncorporationState =
    incorporationRegion?.code.toLowerCase() === stateCode;

  const [toggleReload, setReload] = useState<boolean>(false);
  const toggleReloadView = () => {
    setReload((prev) => !prev);
  };

  const today = convertDateToUTC(new Date());
  const { startDate: requirementStartDate, endDate: requirementEndDate } =
    threeMonthPeriod(today);
  const startDate = isoDate(requirementStartDate);
  const endDate = isoDate(requirementEndDate);
  const mailFilterStartDate = searchParams.get('start_date');
  const mailFilterEndDate = searchParams.get('end_date');
  const mailQuery = new URLSearchParams();

  if (mailFilterStartDate) {
    mailQuery.set('start_date', mailFilterStartDate);
  }

  if (mailFilterEndDate) {
    mailQuery.set('end_date', mailFilterEndDate);
  }

  let mailQueryString = mailQuery.toString();

  if (mailQueryString) {
    mailQueryString = `?${mailQueryString}`;
  }

  const mailURL = stateCode
    ? `/api/legal_entity/locations/${stateCode}/mail${mailQueryString}`
    : `/api/legal_entity/mail${mailQueryString}`;

  const calls: {
    [key: string]: IFetchApi;
  } = {
    mail: { url: mailURL, method: 'GET' },
    users: {
      url: '/api/users',
      method: 'GET',
    },
  };

  /**
   * We only need to get the requirements if we're filtered to a specific region
   * to show the registered agent transfer banner for that particular location.
   * In the global view showing all locations, we don't have a banner.
   */
  if (stateCode) {
    calls.requirements = {
      url: '/api/requirements',
      method: 'POST',
      body: {
        start_date: startDate,
        end_date: endDate,
        region_codes: [stateCode],
        tags: ['foreign-qualification'],
      },
    };
  }

  /**
   * We only need to make this call if we're in the global inbox and the user
   * has filtered mail to a location. If we're in the location detail view mail
   * tab, this call is made at top route so no need to fetch again.
   */
  if (!legalEntityRegion && stateCode) {
    calls.legalEntityRegionConditional = {
      url: `/api/legal_entity/locations/${stateCode}`,
      method: 'GET',
    };
  }

  const batchResponse = useKeyedBatchApi(calls, [
    toggleReload,
    mailURL,
    startDate,
    endDate,
    stateCode,
    regions,
  ]);

  const componentPropsFn = ({
    mail,
    users,
    requirements,
    legalEntityRegionConditional,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }): MailRoomProps => {
    const isForeignQualified = isGlobal
      ? false
      : requirements.data.some(isDone);

    return {
      isIncorporationState,
      isForeignQualified,
      messages: mail,
      mailId: mailId,
      users,
      toggleReloadView,
      legalEntityRegion: legalEntityRegion || legalEntityRegionConditional,
    };
  };

  return (
    <KeyedBatchApiStatusHandler
      batchResponse={batchResponse}
      component={MailRoom}
      componentProps={componentPropsFn}
    />
  );
};
