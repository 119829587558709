import { FunctionComponent, Suspense, useEffect, useState } from 'react';
import {
  FormProvider,
  useForm,
  RegisterOptions,
  useFormContext,
  FieldError as FieldErrorT,
} from 'react-hook-form';
import {
  createSearchParams,
  useSearchParams,
  Outlet,
  Link,
  useSubmit,
  useLoaderData,
  defer,
  Await,
  Navigate,
  redirect,
  useAsyncError,
  useActionData,
  useNavigation,
  useLocation,
} from 'react-router-dom';
import { clsx } from 'clsx';
import { USStateName, USStateAbbrev } from '@mosey/utils/constants/us-states';
import {
  ArrowRightIcon,
  DownloadIcon,
  HumanResourcesIcon,
  MinusIcon,
  PayrollIcon,
  PlusIcon,
  RegistrationIcon,
  TaxIcon,
  CalendarIcon,
  HomeIcon,
} from '@mosey/components/Icons';
import { DropdownMenu, MenuItem } from '@mosey/components/menus/DropdownMenu';

import { Button } from '@mosey/components/buttons/Button';
import { ProgressBar, StatusBar } from '../components';
import { FieldError } from '@mosey/components/forms/FieldError';
import { NavigatorSection } from '../components/Navigator';
import {
  EntityType,
  EntityTypeToName,
  RequirementCategory,
} from '../types/api';
import { EmailPattern } from '@mosey/utils/validation/patterns';
import { CategoryToStringMapping } from '../utils/category';
import { dueDateOccurrenceDescriptor } from '../utils/format';
import logo from '../assets/logo.svg';
import alexImage from '../assets/alex-2.jpeg';
import { useCopy } from '@mosey/components/hooks/useCopy';
import { MultiSelect } from '../components/forms/MultiSelect';
import { MAIN_APP_URL } from '../settings/config';
import { api } from '../utils/fetchApi';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';

type Requirement = {
  title: string;
  category: RequirementCategory;
  instance_count: number; // number of instances implies they're upcoming
  is_managed?: boolean;
  // TODO: Fix this when we start generating types based on the OpenAPI schema
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schedule: any;
};

type Agency = {
  name: string;
};

type Region = {
  code: string;
  name: string;
  agencies: Agency[];
  requirements: Requirement[];
};

type ComplianceScanResponse = {
  entity_type: EntityType;
  payroll_provider: string; // todo enum for payroll providers
  regions: Region[];
};

type TextFieldProps = {
  type?: string;
  name: string;
  label: string;
  error?: FieldErrorT;
  placeholder?: string;
  reactFormConfig?: RegisterOptions;
};

interface ComplianceCheckError {
  title: string;
  message: string;
}

const PayrollProviders: Record<string, string> = {
  adp_run: 'ADP Run',
  adp_workforce_now: 'ADP Workforce Now',
  gusto: 'Gusto',
  rippling: 'Rippling',
  sequoia_one: 'Sequoia One',
  zenefits: 'Zenefits',
  other: 'Other',
};

const STATE_OPTIONS = Object.values(USStateAbbrev).map((state) => {
  return { value: state, name: USStateName[state] };
});

const SCAN_RESULTS_OPTIONS = [
  null,
  RequirementCategory.Payroll,
  RequirementCategory.HR,
  RequirementCategory.Registration,
  RequirementCategory.Tax,
];

const TextField: FunctionComponent<TextFieldProps> = ({
  type,
  name,
  label,
  error,
  placeholder,
  reactFormConfig,
}) => {
  const { register } = useFormContext();
  return (
    <div className="mb-8">
      <div>
        <label className="mb-1 block text-sm font-normal" htmlFor={name}>
          {label}
        </label>
        <input
          type={type || 'text'}
          className={clsx(
            'h-12 w-full rounded border-gray-300 placeholder:text-sage-600',
            {
              'border-red-500': error,
            },
          )}
          placeholder={placeholder}
          id={name}
          {...register(name, { ...reactFormConfig })}
        />
      </div>
      <FieldError error={error} />
    </div>
  );
};

type SelectFieldProps = {
  name: string;
  label: string;
  error?: FieldErrorT;
  options?: { name?: string; value: string | number }[];
  emptyHelpText?: string;
  inputClassName?: string;
  reactFormConfig?: RegisterOptions;
};

export const SelectField: FunctionComponent<SelectFieldProps> = ({
  name,
  label,
  error,
  options,
  emptyHelpText,
  inputClassName,
  reactFormConfig,
}) => {
  const { register } = useFormContext();
  return (
    <div className="mb-8">
      <div>
        <label className="mb-1 block text-sm font-normal" htmlFor={name}>
          {label}
        </label>
        <select
          className={clsx(
            {
              'h-12 w-full rounded border-gray-300': !inputClassName,
              'border-red-500': error,
            },
            inputClassName,
          )}
          id={name}
          {...register(name, { ...reactFormConfig })}
        >
          <option key="_default" value="" disabled selected>
            {emptyHelpText || 'Select one'}
          </option>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name || option.value}
            </option>
          ))}
        </select>
      </div>
      <FieldError error={error} />
    </div>
  );
};

const StateMultiSelectField: FunctionComponent = () => {
  return (
    <div className="mb-8">
      <div>
        <label className="mb-1 block text-sm font-normal" htmlFor="states">
          States:
        </label>
        <MultiSelect
          options={STATE_OPTIONS}
          name="states"
          rules={{ required: 'This is required' }}
        />
      </div>
    </div>
  );
};

type NavProps = {
  children?: React.ReactNode;
};

const Nav: FunctionComponent<NavProps> = ({ children }) => {
  return (
    <div className="min-h-screen">
      <div className="flex h-16 w-full items-center bg-teal-800 px-10 text-white sm:h-28">
        <div className="flex items-center">
          <img className="mr-6 mt-1 w-20" src={logo} alt="Logo" />
          <div className="flex h-8 items-center border-l pl-6 text-xl font-bold leading-4 tracking-normal">
            Compliance Check
          </div>
        </div>
      </div>
      <div className="mt-8 sm:mt-24">{children}</div>
    </div>
  );
};

type CardLeftSideTemplateProps = {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  children?: React.ReactNode;
  hideWhenCollapsed?: boolean;
};

const CardLeftSideTemplate: FunctionComponent<CardLeftSideTemplateProps> = ({
  icon,
  title,
  description,
  children,
  hideWhenCollapsed,
}) => {
  return (
    <div
      className={clsx(
        'flex flex-col items-center rounded border bg-teal-300 p-6 sm:w-60 sm:min-w-60 sm:rounded-none sm:border-none sm:py-12',
        hideWhenCollapsed ? 'hidden sm:flex' : 'flex',
      )}
    >
      {icon && <div className="mb-4">{icon}</div>}
      {title && <div className="text-lg font-bold">{title}</div>}
      {description && (
        <div className="mt-4 text-center text-sm font-normal">
          {description}
        </div>
      )}
      {children}
    </div>
  );
};

type CardRightSideTemplateProps = {
  heading?: string;
  children?: React.ReactNode;
};

const CardRightSideTemplate: FunctionComponent<CardRightSideTemplateProps> = ({
  heading,
  children,
}) => {
  return (
    <div className="flex flex-1 flex-col bg-white text-left sm:rounded-r-3xl sm:border-l  sm:px-10 sm:py-8">
      {heading && (
        <h2 className="mb-4 text-lg font-bold text-zinc-800">{heading}</h2>
      )}
      {children}
    </div>
  );
};

type PageTemplateProps = {
  title: string;
  description: string[];
  cta?: React.ReactNode;
  ctaPosition?: 'top' | 'bottom';
  children: React.ReactNode;
};

const PageTemplate: FunctionComponent<PageTemplateProps> = ({
  title,
  description,
  cta,
  ctaPosition = 'bottom',
  children,
}) => {
  return (
    <div className="mb-12 flex flex-col items-center px-6 text-zinc-800 sm:mb-24">
      <h1 className="px-6 text-2xl font-extrabold sm:text-4xl">{title}</h1>
      <div className="my-6 flex max-w-2xl flex-col items-center gap-4 text-center text-lg font-medium text-zinc-600 sm:my-14 sm:text-xl">
        {description.map((desc, lineNumber) => (
          <p key={lineNumber}>{desc}</p>
        ))}
        {ctaPosition === 'top' && cta}
      </div>
      <div
        className={clsx(
          'flex w-full flex-col gap-6 overflow-hidden sm:max-w-[960px] sm:flex-row sm:gap-0 sm:rounded-3xl sm:border',
        )}
      >
        {children}
      </div>
      {ctaPosition === 'bottom' && cta}
    </div>
  );
};

type FormState = {
  name: USStateName;
  value: USStateAbbrev;
};

type CompanyFormData = {
  user_email: string;
  company_name: string;
  company_type: string;
  states: FormState[];
  payroll_provider: string;
};

export const ComplianceCheckAbout = () => {
  const formMethods = useForm<CompanyFormData>({
    defaultValues: {
      states: [],
    },
  });

  const submit = useSubmit();
  let { error } =
    (useActionData() as { error: ComplianceCheckError | undefined }) || {};
  const { state } = useNavigation();
  const { state: locState } = useLocation();
  error = error ?? locState?.error;

  const onSubmit = (data: CompanyFormData) => {
    // Put data in the url params so it can be shared later
    const states = data.states.map((s) => s.value);
    const params = {
      user_email: data.user_email,
      company_name: data.company_name,
      company_type: data.company_type,
      region_codes: states,
      payroll_provider: data.payroll_provider,
    };

    submit(params, {
      action: `/compliance-check/about`,
      method: 'post',
      encType: 'application/json',
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(onSubmit)}
        method="POST"
        className="px-8"
      >
        <PageTemplate
          title="Enter a few details"
          description={[
            'The Mosey compliance check gives you a quick look at what it takes to stay compliant in any state. Start with Mosey to do state compliance the easy way.',
          ]}
          cta={
            <div className="mt-6 flex w-[350px] items-center justify-center sm:mt-10">
              <Button
                isFullWidth
                size="large"
                rightIcon={<ArrowRightIcon className="size-6" />}
                isDisabled={state === 'submitting'}
                isLoading={state === 'submitting'}
              >
                Next Step
              </Button>
            </div>
          }
        >
          <CardLeftSideTemplate
            title="Hi, I'm Alex"
            description="Share a few details about your company, and the states in which you’d like to check your compliance. Include states where you are registered, have employees, or would like to hire employees."
            icon={
              <img
                className="size-20 rounded-full"
                src={alexImage}
                alt="Alex"
              />
            }
          />
          <CardRightSideTemplate>
            <div className="mb-6">
              <BlockAlert
                scrollIntoView
                show={!!error}
                message={error?.title}
                variant={'error'}
              >
                {error?.message}
              </BlockAlert>
            </div>
            <div className="mb-6 text-xs font-bold uppercase tracking-wide text-stone-600">
              Company Info
            </div>
            <div className="-mb-8 text-zinc-800">
              <TextField
                name="user_email"
                type="email"
                label="Your Email:"
                placeholder="me@mosey.com"
                error={formMethods.formState.errors.user_email}
                reactFormConfig={{
                  required: 'This is required',
                  pattern: EmailPattern,
                }}
              />
              <TextField
                name="company_name"
                label="Company Name:"
                placeholder="ACME Inc."
                error={formMethods.formState.errors.company_name}
                reactFormConfig={{ required: 'This is required' }}
              />
              <SelectField
                name="company_type"
                label="Company Type:"
                options={[
                  { name: 'C Corporation', value: 'c_corp' },
                  { name: 'LLC', value: 'llc' },
                ]}
                emptyHelpText="Select Company Type"
                error={formMethods.formState.errors.company_type}
                reactFormConfig={{ required: 'This is required' }}
              />
              <StateMultiSelectField />
              <SelectField
                name="payroll_provider"
                label="Payroll Provider:"
                options={Object.keys(PayrollProviders).map((key: string) => ({
                  name: PayrollProviders[key],
                  value: key,
                }))}
                emptyHelpText="Select Payroll Provider"
                error={formMethods.formState.errors.payroll_provider}
                reactFormConfig={{ required: 'This is required' }}
              />
            </div>
          </CardRightSideTemplate>
        </PageTemplate>
      </form>
    </FormProvider>
  );
};

ComplianceCheckAbout.action = async ({ request }: { request: Request }) => {
  const params = await request.json();

  try {
    await api({
      url: '/api/public/compliance_check/lead',
      method: 'POST',
      body: {
        email: params.user_email,
        company_name: params.company_name,
      },
    });
  } catch (e) {
    let error = errorMessages.generic;

    if (e instanceof Response && e.status === 429) {
      error = errorMessages['rate-limit'];
    }

    return { error };
  }

  return redirect(
    `/compliance-check/scan/results?${createSearchParams(params).toString()}`,
  );
};

const randRange = (min: number, max: number): number => {
  const minValue = Math.ceil(min);
  const maxValue = Math.floor(max);
  return Math.random() * (maxValue - minValue) + minValue;
};

interface ComplianceCheckScanProps {
  isComplete: boolean;
}

export const ComplianceCheckScan = ({
  isComplete,
}: ComplianceCheckScanProps) => {
  const [searchParams] = useSearchParams();
  const companyName = searchParams.get('company_name');
  const companyType = searchParams.get('company_type');
  const regionCodes = searchParams.getAll('region_codes');
  const payrollProvider = searchParams.get('payroll_provider') || 'other';

  const [payrollProgress, setPayrollProgress] = useState<number>(0);
  const [hrProgress, setHrProgress] = useState<number>(0);
  const [registrationProgress, setRegistrationProgress] = useState<number>(0);
  const [taxProgress, setTaxProgress] = useState<number>(0);

  // slowest progress bar should take around 10 seconds (p99 of the API call we are loading)
  const payrollStep = randRange(2, 4);
  const hrStep = randRange(0.5, 1);
  const registrationStep = randRange(1, 2);
  const salesTaxStep = 0.3;

  useEffect(() => {
    const interval = setInterval(() => {
      setPayrollProgress((prev) => Math.min(prev + payrollStep, 100));
      setHrProgress((prev) => Math.min(prev + hrStep, 100));
      setRegistrationProgress((prev) => Math.min(prev + registrationStep, 100));
      setTaxProgress((prev) => Math.min(prev + salesTaxStep, 100));
    }, 30);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <PageTemplate
      title="Your Compliance Report"
      description={['Check out your potential compliance gaps in each state.']}
    >
      <CardLeftSideTemplate
        hideWhenCollapsed
        title="Compliance Scan"
        description="We're collecting compliance tasks in each location based on the information you provided."
      >
        <div className="mt-6 flex flex-col self-start">
          <div className="mb-4">
            <div className="text-xs font-normal uppercase tracking-wide text-stone-600">
              Company Name
            </div>
            <div className="text-lg font-bold text-zinc-800">{companyName}</div>
          </div>
          <div className="mb-4">
            <div className="text-xs font-normal uppercase tracking-wide text-stone-600">
              Company Type
            </div>
            <div className="text-lg font-bold text-zinc-800">
              {
                companyType
                  ? EntityTypeToName[companyType as EntityType]
                  : EntityTypeToName.c_corp /* component redirects before using default case */
              }
            </div>
          </div>
          <div className="mb-4">
            <div className="text-xs font-normal uppercase tracking-wide text-stone-600">
              Locations
            </div>
            <div className="text-lg font-bold text-zinc-800">
              {regionCodes?.length}
            </div>
          </div>
          <div className="mb-4">
            <div className="text-xs font-normal uppercase tracking-wide text-stone-600">
              Payroll Provider
            </div>
            <div className="text-lg font-bold capitalize text-zinc-800">
              {PayrollProviders[payrollProvider]}
            </div>
          </div>
        </div>
      </CardLeftSideTemplate>
      <CardRightSideTemplate heading="Scan in progress">
        <div>
          <div className="mb-3 flex w-full">
            <div className="flex-1 text-xs font-bold uppercase tracking-wide text-stone-600">
              Focus Areas
            </div>
            <div className="flex-1 text-xs font-bold uppercase tracking-wide text-stone-600">
              Progress
            </div>
          </div>
          {[
            { title: 'Payroll', progress: payrollProgress },
            { title: 'Human Resources', progress: hrProgress },
            { title: 'Registration', progress: registrationProgress },
            { title: 'Taxes', progress: taxProgress },
          ].map(({ title, progress }) => (
            <div
              key={title}
              className="mb-4 flex w-full items-center last:mb-0 sm:mb-10"
            >
              <div className="flex-1 text-base text-zinc-600">{title}:</div>
              <div className="mt-3 flex-1">
                <ProgressBar
                  complete={isComplete ? 100 : progress}
                  bgColor="bg-sage-400"
                  bgHighlightColor="bg-teal-700"
                />
              </div>
            </div>
          ))}
        </div>
      </CardRightSideTemplate>
    </PageTemplate>
  );
};

type ScanResultsStatusBarProps = {
  requirements: Requirement[];
};

/**
 * Status bar for scan results page.
 *
 * Given a list of requirements:
 * - managed requirements are counted as "complete" - it's assumed the 3rd party handles them
 * - setup requirements are counted as "inProgress" - it's possible and unknown if the user has already started/done these
 * - all remaining requirements are counted as "incomplete"
 */
const ScanResultsStatusBar: FunctionComponent<ScanResultsStatusBarProps> = ({
  requirements,
}) => {
  const managedReqs = requirements.filter((req) => req.is_managed);
  const percentComplete = Math.min(
    (managedReqs.length / requirements.length) * 100,
    100,
  );
  const percentSetup =
    (requirements.filter(
      (req) =>
        !req.is_managed &&
        (req.title.match(/Foreign Qualification|Foreign Registration/) ||
          [
            RequirementCategory.InsuranceSetup,
            RequirementCategory.PayrollSetup,
            RequirementCategory.TaxSetup,
          ].indexOf(req.category) > -1),
    ).length /
      requirements.length) *
    100;

  return (
    <>
      <StatusBar
        complete={percentComplete}
        inProgress={percentSetup}
        incomplete={100 - percentComplete - percentSetup}
        completeLabel="managed"
        inProgressLabel="setup"
        incompleteLabel="unknown"
      />
    </>
  );
};

type ScanResultRowProps = {
  stateName: string;
  requirements: Requirement[];
  payrollProvider: string;
};

const ScanResultRow: FunctionComponent<ScanResultRowProps> = ({
  stateName,
  requirements,
  payrollProvider,
}) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <>
      <tr
        key={`result-${stateName}`}
        className={clsx('border-t align-top', {
          'bg-teal-400': showDetails,
        })}
      >
        <td className="py-6">
          <div className="flex">
            <div className="mt-px flex w-28 items-start px-6">
              <ScanResultsStatusBar requirements={requirements} />
            </div>
          </div>
        </td>
        <td className="py-4 pr-6">
          <div className="text-lg font-semibold">{stateName}</div>
        </td>
        <td className="px-3 py-4">
          <div className="flex justify-center text-xl font-bold">
            {requirements.length}
          </div>
        </td>
        <td className="flex justify-end py-4 pr-6">
          <div
            className={clsx('cursor-pointer rounded-full p-1', {
              'bg-teal-700 hover:bg-teal-800': showDetails,
              'bg-rose-700 hover:bg-rose-800': !showDetails,
            })}
            onClick={() => setShowDetails((prev) => !prev)}
          >
            {showDetails ? (
              <MinusIcon className="size-4 text-white" />
            ) : (
              <PlusIcon className="size-4 text-white" />
            )}
          </div>
        </td>
      </tr>
      {showDetails && (
        <tr>
          <td colSpan={4}>
            <table className="w-full bg-gray-50">
              <thead className="pt-6">
                <tr className="text-xs font-bold uppercase text-gray-800">
                  <th className="w-2/3 border-b py-2 pl-6 pr-4 text-left">
                    Requirements
                  </th>
                  <th className="border-b py-2 pr-4">How Often</th>
                  <th className="border-b py-2 pr-4">Covered By</th>
                </tr>
              </thead>
              <tbody>
                {requirements.map((req, idx) => (
                  <tr
                    key={req.title.toLowerCase().replace(' ', '-')}
                    className="border-t align-top"
                  >
                    <td className="py-4">
                      <div className="flex px-4">
                        <div className="mr-2 flex size-6 items-center justify-center rounded-full bg-teal-700 p-2 text-sm font-semibold text-white">
                          {idx + 1}
                        </div>
                        <p className="text-sm font-bold">{req.title}</p>
                      </div>
                    </td>
                    <td className="py-4">
                      <div className="flex">
                        <div className="pr-6">
                          <p className="text-sm capitalize">
                            {dueDateOccurrenceDescriptor(req.schedule)}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="py-4 pr-6">
                      <p className="text-sm">
                        {req.is_managed ? payrollProvider : 'Mosey'}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

type StateReqs = {
  [s in USStateName]?: Requirement[];
};

type ReqsByCategoryByState = {
  [key in RequirementCategory]?: StateReqs;
};

const ScanCategoryToStringMapping: Record<RequirementCategory, string> = {
  [RequirementCategory.Payroll]: 'Payroll',
  [RequirementCategory.PayrollSetup]: 'Payroll setup',
  [RequirementCategory.Registration]: 'Registration',
  [RequirementCategory.RegistrationMaintenance]: 'Maintenance',
  [RequirementCategory.HR]: 'Human Resources',
  [RequirementCategory.Insurance]: 'Insurance',
  [RequirementCategory.InsuranceSetup]: 'Insurance setup',
  [RequirementCategory.Tax]: 'Tax',
  [RequirementCategory.TaxSetup]: 'Tax setup',
};

const CategoryToDescriptionMapping: { [key in RequirementCategory]?: string } =
  {
    [RequirementCategory.Payroll]: `Payroll taxes differ in every
      state. Businesses need to open the right set of accounts and set
      up their payroll provider to file and reports wages. Payroll
      requirements can change as businesses grow and new laws go into
      effect.`,
    [RequirementCategory.Registration]: `A business operating in
      another state (e.g. remote employees, an office, etc.) may need to
      register with the Secretary of State for a certificate of
      authority. This typically comes with additional reporting
      requirements and corporate taxes.`,
    [RequirementCategory.HR]: `Labor laws vary by state and even by
      city or county. They also change rapidly, making it difficult
      for multi-state employers to keep up with. While difficult,
      these laws help protect your employees—it's the right thing
      to do to make work safer and more equitable.`,
    [RequirementCategory.Tax]: `Taxes are different in every location
      and it's common for multi-state employers to have tax obligations
      beyond payroll in the states they operate it. This includes sales
      and use tax but also corporate taxes, franchise taxes, and excise
      taxes depending on the state.`,
  };

interface ScanResultsSummaryProps {
  apiResponse: ComplianceScanResponse;
  setSelectedCategory: (value: RequirementCategory | null) => void;
}

const ScanResultsSummary = ({
  apiResponse,
  setSelectedCategory,
}: ScanResultsSummaryProps) => {
  const requirementsByCategory: { [key: string]: Requirement[] } = {};
  apiResponse.regions.forEach((region: Region) => {
    region.requirements.forEach((req: Requirement) => {
      const cat: string = req.category;
      if (req.category in requirementsByCategory) {
        requirementsByCategory[cat].push(req);
      } else {
        requirementsByCategory[cat] = [req];
      }
    });
  });

  const payrollReqs = (
    requirementsByCategory[RequirementCategory.Payroll] || []
  ).concat(requirementsByCategory[RequirementCategory.PayrollSetup] || []);
  const hrReqs = (requirementsByCategory[RequirementCategory.HR] || []).concat(
    (requirementsByCategory[RequirementCategory.Insurance] || []).concat(
      requirementsByCategory[RequirementCategory.InsuranceSetup] || [],
    ),
  );
  const taxReqs = (
    requirementsByCategory[RequirementCategory.Tax] || []
  ).concat(requirementsByCategory[RequirementCategory.TaxSetup] || []);
  const registrationReqs = (
    requirementsByCategory[RequirementCategory.Registration] || []
  ).concat(
    requirementsByCategory[RequirementCategory.RegistrationMaintenance] || [],
  );
  return (
    <>
      <h3 className="my-6 hidden font-bold text-teal-800 sm:block">Summary</h3>
      <div className="my-6 sm:hidden">
        <DropdownMenu buttonText="Summary" isFullWidth>
          {SCAN_RESULTS_OPTIONS.map((category) => (
            <MenuItem
              key={category}
              as="button"
              onClick={() => setSelectedCategory(category)}
              selected={category === null}
            >
              {category ? CategoryToStringMapping[category] : 'Summary'}
            </MenuItem>
          ))}
        </DropdownMenu>
      </div>
      <p className="mb-6">
        For more details, review each compliance category. Some of the
        requirements listed may not be relevant to you (e.g., payroll in
        Delaware if you are not registered for withholding or unemployment
        insurance in the state).
      </p>
      <div className="mb-3 flex w-full">
        <div className="flex-1 text-xs font-bold uppercase tracking-wide text-stone-600">
          Results
        </div>
        <div className="flex flex-[2] justify-between">
          <div className="text-xs font-bold uppercase tracking-wide text-stone-600">
            Status
          </div>
          <div className="w-fit whitespace-nowrap text-xs font-bold uppercase tracking-wide text-stone-600">
            Requirements
          </div>
        </div>
      </div>
      {[
        { title: 'Payroll', reqs: payrollReqs },
        { title: 'Human Resources', reqs: hrReqs },
        { title: 'Registration', reqs: registrationReqs },
        { title: 'Taxes', reqs: taxReqs },
      ].map(({ title, reqs }) => (
        <div
          key={title}
          className="mb-6 flex w-full items-center border-b border-stone-400 pb-3 last:border-b-0"
        >
          <div className="mb-3 mt-2 flex-1 font-bold text-zinc-600">
            {title}
          </div>
          <div className="my-3 flex flex-[2] justify-between">
            <div className="flex flex-1 items-center">
              <ScanResultsStatusBar requirements={reqs} />
            </div>
            <div className="flex-1 whitespace-nowrap text-right text-xl font-medium">
              {reqs.length}
            </div>
          </div>
        </div>
      ))}
      <p className="text-sm">
        Green items are tasks that are covered by your payroll provider. Yellow
        items are one time requirements, and red items are reccurring compliance
        requirements that are not covered by your payroll provider.
      </p>
    </>
  );
};

interface ScanResultsForCategoryProps {
  stateReqs: StateReqs;
  selectedCategory: RequirementCategory;
  setSelectedCategory: (value: RequirementCategory | null) => void;
}

const ScanResultsForCategory = ({
  stateReqs,
  selectedCategory,
  setSelectedCategory,
}: ScanResultsForCategoryProps) => {
  const [searchParams] = useSearchParams();
  const payrollProvider = searchParams.get('payroll_provider');

  const formattedPayrollProvider =
    !payrollProvider || payrollProvider === 'other'
      ? 'Payroll Provider'
      : PayrollProviders[payrollProvider];
  return (
    <>
      <div className="py-4">
        <h3 className="mb-4 mt-2 hidden font-bold text-teal-800 sm:block">
          {ScanCategoryToStringMapping[selectedCategory]}
        </h3>
        <h3 className="mb-4 mt-2 sm:hidden">
          <DropdownMenu
            isFullWidth
            buttonText={
              selectedCategory
                ? CategoryToStringMapping[selectedCategory]
                : 'Summary'
            }
          >
            {SCAN_RESULTS_OPTIONS.map((category) => (
              <MenuItem
                key={category}
                as="button"
                onClick={() => setSelectedCategory(category)}
                selected={category === selectedCategory}
              >
                {category ? CategoryToStringMapping[category] : 'Summary'}
              </MenuItem>
            ))}
          </DropdownMenu>
        </h3>
        <p>{CategoryToDescriptionMapping[selectedCategory]}</p>
      </div>
      <table className="w-full">
        <thead>
          <tr className="border-t text-xs font-bold uppercase tracking-wider text-gray-500">
            <th className="py-2 pl-6 pr-5">Risk</th>
            <th className="py-2 pr-3">Location</th>
            <th className="px-3 py-2">Items Found</th>
            <th>
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(stateReqs).map(([stateName, stateReqs]) => (
            <ScanResultRow
              key={`${selectedCategory}-${stateName}-row`}
              stateName={stateName}
              requirements={stateReqs}
              payrollProvider={formattedPayrollProvider}
            />
          ))}
        </tbody>
      </table>
      <p className="mt-6 text-sm">
        Green items are tasks that are covered by your payroll provider. Yellow
        items are one time requirements, and red items are reccurring compliance
        requirements that are not covered by your payroll provider.
      </p>
    </>
  );
};

const ScanResultsFull: FunctionComponent<ScanResultsProps> = ({
  apiResponse,
  source,
}) => {
  const [onCopy, isCopied] = useCopy(window.location.href, 1500);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('user_email');
  const companyName = searchParams.get('company_name');
  // Handle Stripe-Atlas users separately.
  const isStripeAtlasUser = (source || '').toLocaleLowerCase() === 'stripe';

  const [selectedCategory, setSelectedCategory] =
    useState<RequirementCategory | null>(null);

  // Create an index of requirements
  const reqsByCategoryByState: ReqsByCategoryByState =
    apiResponse.regions.reduce((prev, region) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const accum: any = prev;
      const reqs = region.requirements;

      reqs.forEach((r) => {
        // Combine common categories like payroll and payroll-setup
        let requirementCategory: RequirementCategory;
        switch (r.category) {
          case RequirementCategory.PayrollSetup:
            requirementCategory = RequirementCategory.Payroll;
            break;
          case RequirementCategory.RegistrationMaintenance:
            requirementCategory = RequirementCategory.Registration;
            break;
          case RequirementCategory.Insurance:
            requirementCategory = RequirementCategory.HR;
            break;
          case RequirementCategory.InsuranceSetup:
            requirementCategory = RequirementCategory.HR;
            break;
          case RequirementCategory.TaxSetup:
            requirementCategory = RequirementCategory.Tax;
            break;
          default:
            requirementCategory = r.category;
        }

        // Make sure the category key exists
        const category = accum[requirementCategory];
        if (!category) {
          accum[requirementCategory] = {};
        }

        // Make sure the state key exists
        const stateReqs = accum[requirementCategory][region.name];
        if (!stateReqs) {
          accum[requirementCategory][region.name] = [];
        }

        // Update the index
        accum[requirementCategory][region.name].push(r);
      });

      return accum;
    }, {});

  const categoryIndex = selectedCategory
    ? reqsByCategoryByState[selectedCategory]
    : undefined;

  const params = new URLSearchParams();
  params.append('email', email || '');
  params.append('company_name', companyName || '');
  if (isStripeAtlasUser) {
    // Pass the stripe coupon code
    params.append('code', 'bc1ef89e');
    params.append('plan', 'basic');
  }
  return (
    <PageTemplate
      title="Your Compliance Report"
      description={[
        'Check out your potential compliance gaps in each state.',
        'Ready to get your compliance covered? Sign up now or schedule a free consultation to see how Mosey transforms business compliance.',
      ]}
      ctaPosition="top"
      cta={
        <>
          <div className="mt-2 flex w-[350px] flex-col items-center justify-center gap-4">
            {!isStripeAtlasUser && (
              <Button
                as="a"
                href="https://meetings.hubspot.com/alex-kehayias/compliance-check"
                target="_blank"
                rel="noreferer noopener"
                isFullWidth
                size="large"
                rightIcon={<CalendarIcon className="size-6" />}
              >
                Schedule demo
              </Button>
            )}
            <Button
              as={Link}
              to={`${MAIN_APP_URL}/signup?${params.toString()}`}
              target="_blank"
              isFullWidth
              size="large"
              rightIcon={<ArrowRightIcon className="size-6" />}
              variant={isStripeAtlasUser ? 'primary' : 'secondary'}
            >
              Get started with Mosey
            </Button>
          </div>
        </>
      }
    >
      <CardLeftSideTemplate hideWhenCollapsed>
        <div className="sticky top-0 py-4">
          <NavigatorSection
            key="scan-result-overview"
            sectionIcon={HomeIcon}
            heading="Summary"
            isSelected={!selectedCategory}
            isFirst
            className="mb-4 mt-0 px-4 py-2"
            isDone={false}
            onClick={() => setSelectedCategory(null)}
          />
          <NavigatorSection
            key="scan-result-payroll"
            sectionIcon={PayrollIcon}
            heading="Payroll"
            isSelected={selectedCategory === RequirementCategory.Payroll}
            isFirst
            className="mb-4 mt-0 px-4 py-2"
            isDone={false}
            onClick={() => setSelectedCategory(RequirementCategory.Payroll)}
          />
          <NavigatorSection
            key="scan-result-hr"
            sectionIcon={HumanResourcesIcon}
            heading="HR"
            isSelected={selectedCategory === RequirementCategory.HR}
            isFirst
            className="mb-4 px-4 py-2"
            isDone={false}
            onClick={() => setSelectedCategory(RequirementCategory.HR)}
          />
          <NavigatorSection
            key="scan-result-reg"
            sectionIcon={RegistrationIcon}
            heading="Registration"
            isSelected={selectedCategory === RequirementCategory.Registration}
            isFirst
            className="mb-4 px-4 py-2"
            isDone={false}
            onClick={() =>
              setSelectedCategory(RequirementCategory.Registration)
            }
          />
          <NavigatorSection
            key="scan-result-tax"
            sectionIcon={TaxIcon}
            heading="Tax"
            isSelected={selectedCategory === RequirementCategory.Tax}
            isFirst
            className="px-4 py-2"
            isDone={false}
            onClick={() => setSelectedCategory(RequirementCategory.Tax)}
          />
        </div>
      </CardLeftSideTemplate>
      <CardRightSideTemplate>
        <div className="flex items-center justify-between border-b ">
          <h2 className="pb-6 text-lg font-bold">Audit Details</h2>
          <div>
            <Button
              type="button"
              size="small"
              variant="secondary"
              onClick={onCopy}
              rightIcon={<DownloadIcon className="ml-2 w-4" />}
            >
              Share Report
            </Button>
            <div
              className={clsx(
                'flex h-6 items-center px-3 py-1 text-xs text-lime-500 transition-opacity',
                {
                  'opacity-100': isCopied,
                  'opacity-0': !isCopied,
                },
              )}
            >
              Copied to clipboard!
            </div>
          </div>
        </div>

        {categoryIndex && selectedCategory ? (
          <ScanResultsForCategory
            selectedCategory={selectedCategory}
            stateReqs={categoryIndex}
            setSelectedCategory={setSelectedCategory}
          />
        ) : (
          <ScanResultsSummary
            apiResponse={apiResponse}
            setSelectedCategory={setSelectedCategory}
          />
        )}
      </CardRightSideTemplate>
    </PageTemplate>
  );
};

type ScanResultsProps = {
  apiResponse: ComplianceScanResponse;
  source?: string;
};
type ComplianceCheckScanResultsView = {
  source?: string;
};

const errorMessages: Record<string, ComplianceCheckError> = {
  form: {
    title: 'Some fields are invalid',
    message: 'Please review your entry and try again.',
  },
  'rate-limit': {
    title: "You've submitted this form too many times",
    message: 'Please wait a few minutes and try again',
  },
  generic: {
    title: 'Something went wrong',
    message: 'Please try again later.',
  },
};

const ComplianceCheckScanResultsError = () => {
  const errorResponse = useAsyncError() as Response;
  let error = errorMessages.generic;

  if (errorResponse.status === 429)
    return (
      <div className="px-8">
        <BlockAlert
          show
          scrollIntoView
          variant="error"
          message={errorMessages['rate-limit'].title}
        >
          {errorMessages['rate-limit'].message}
        </BlockAlert>
      </div>
    );
  else if (errorResponse.status === 422) {
    error = errorMessages.form;
  }

  return <Navigate state={{ error }} to={`/compliance-check/about`} />;
};

export const ComplianceCheckScanResultsView = ({
  source,
}: ComplianceCheckScanResultsView) => {
  const { response } = useLoaderData() as { response: ComplianceScanResponse };

  return (
    <Suspense fallback={<ComplianceCheckScan isComplete={false} />}>
      <Await
        resolve={response}
        errorElement={<ComplianceCheckScanResultsError />}
      >
        {(response) => (
          <ScanResultsFull apiResponse={response} source={source} />
        )}
      </Await>
    </Suspense>
  );
};

ComplianceCheckScanResultsView.loader = ({ request }: { request: Request }) => {
  const searchParams = new URL(request.url).searchParams;
  const entity_type = searchParams.get('company_type');
  const region_codes = searchParams.getAll('region_codes');
  const payroll_provider = searchParams.get('payroll_provider');
  const email = searchParams.get('user_email');
  const company_name = searchParams.get('company_name');

  const response = api({
    url: '/api/public/compliance_check/summary',
    method: 'POST',
    body: {
      entity_type,
      payroll_provider,
      region_codes,
      company_name,
      email,
    },
  }).then(async (response) => {
    return await response.json();
  });

  return defer({ response });
};

export const ComplianceCheck: FunctionComponent = () => {
  return (
    <Nav>
      <Outlet />
    </Nav>
  );
};
