// Developing in GitHub Codespaces needs a dynamic configuration based
// on the environment variables listed here:
// https://docs.github.com/en/codespaces/developing-in-codespaces/default-environment-variables-for-your-codespace#list-of-default-environment-variables
let baseUrl: string;
let apiUrl: string;
let adminUrl: string;
let embedUrl: string;
let isEmbedded = false;
let mainAppUrl: string;

if (import.meta.env.VITE_CODESPACES === 'true') {
  baseUrl = `https://${import.meta.env.VITE_CODESPACE_NAME}-3000.githubpreview.dev`;
  apiUrl = `https://${import.meta.env.VITE_CODESPACE_NAME}-8888.githubpreview.dev`;
  adminUrl = `https://${import.meta.env.VITE_CODESPACE_NAME}-3002.githubpreview.dev`;
  embedUrl = `https://${import.meta.env.VITE_CODESPACE_NAME}-3000.githubpreview.dev`;
  mainAppUrl = `https://${import.meta.env.VITE_CODESPACE_NAME}-3000.githubpreview.dev`;
} else {
  baseUrl = import.meta.env.VITE_MOSEY_BASE_URL!;
  apiUrl = import.meta.env.VITE_MOSEY_BACKEND_URL!;
  adminUrl = import.meta.env.VITE_MOSEY_ADMIN_URL!;
  embedUrl = import.meta.env.VITE_MOSEY_EMBED_URL!;
  mainAppUrl = import.meta.env.VITE_MOSEY_BASE_URL!;
}

if (baseUrl === 'https://app.mosey.com') {
  if (window.location.host === 'sandbox-app.mosey.com') {
    baseUrl = 'https://sandbox-app.mosey.com';
    apiUrl = 'https://sandbox-api.mosey.com';
    embedUrl = 'https://sandbox-embed.mosey.com';
    adminUrl = 'https://sandbox-admin.mosey.com';
    mainAppUrl = 'https://sandbox-app.mosey.com';
  } else if (window.location.host === 'embed.mosey.com') {
    isEmbedded = true;
    baseUrl = 'https://embed.mosey.com';
    mainAppUrl = 'https://app.mosey.com';
  } else if (window.location.host === 'sandbox-embed.mosey.com') {
    isEmbedded = true;
    baseUrl = 'https://sandbox-embed.mosey.com';
    apiUrl = 'https://sandbox-api.mosey.com';
    embedUrl = 'https://sandbox-embed.mosey.com';
    adminUrl = 'https://sandbox-admin.mosey.com';
    mainAppUrl = 'https://sandbox-app.mosey.com';
  }
}

export const APP_BASE_URL: string = baseUrl;
export const API_BASE_URL: string = apiUrl;
export const ADMIN_BASE_URL: string = adminUrl;
export const EMBED_BASE_URL: string = embedUrl;
export const MAIN_APP_URL: string = mainAppUrl;

const finchRedirectPath = import.meta.env.VITE_FINCH_REDIRECT_PATH;
export const FINCH_REDIRECT_URI = `${APP_BASE_URL}${finchRedirectPath}`;

const finchClientID = import.meta.env.VITE_FINCH_CLIENT_ID;
const finchProducts = 'directory%20individual%20employment';
let finchURL: string;
// for sandbox, use the dev finch URL, so the client_id is hardcoded here.
if (window.location.host === 'sandbox-app.mosey.com') {
  finchURL = `https://connect.tryfinch.com/authorize?&client_id=d089d37e-57f8-4433-b757-2eedf8e6b7e2&products=${finchProducts}&redirect_uri=${FINCH_REDIRECT_URI}&sandbox=true&manual=true`;
} else if (import.meta.env.PROD) {
  finchURL = `https://connect.tryfinch.com/authorize?&client_id=${finchClientID}&products=${finchProducts}&redirect_uri=${FINCH_REDIRECT_URI}&manual=true`;
} else {
  finchURL = `https://connect.tryfinch.com/authorize?&client_id=${finchClientID}&products=${finchProducts}&redirect_uri=${FINCH_REDIRECT_URI}&sandbox=true&manual=true`;
}

export const STRIPE_BILLING_URL =
  'https://billing.stripe.com/p/login/eVa7wu0SQ5qj08M4gg';

export const FINCH_CONNECT_URL: string = finchURL;

export const SENTRY_DSN: string = import.meta.env.VITE_SENTRY_DSN!;

export const IS_EMBEDDED = isEmbedded;
