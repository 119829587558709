import { CheckIcon } from '@heroicons/react/outline';
import { ReactNode } from 'react';
import { Button } from '../buttons/Button';
import { Link, LinkProps } from 'react-router-dom';

type ActionBannerProps = {
  children: ReactNode;
  to: LinkProps['to'];
};

export const ActionBanner = ({ children, to }: ActionBannerProps) => {
  return (
    <div className="flex w-full items-center gap-2 rounded-lg border border-teal-900 bg-teal-800 px-4 py-3">
      <CheckIcon className="size-5 text-white" />
      <p className="flex-1 font-semibold text-white">{children}</p>
      <Button as={Link} to={to} variant="secondary">
        Continue
      </Button>
    </div>
  );
};
