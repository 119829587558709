import { USStateId } from '@mosey/utils/constants/us-states';
import { ResolverType } from './types';

const KEY = 'TASKS_FRAMEWORK_RESOLVER_COMPLETED_TASKS';

export const getTasksCompletedThisSession = () => {
  const completedTasks = sessionStorage.getItem(KEY);

  if (completedTasks) {
    let parsed: string[];

    try {
      parsed = JSON.parse(completedTasks);
    } catch (error) {
      sessionStorage.removeItem(KEY);
      parsed = [];
    }

    return parsed;
  }

  return [];
};

export const markTaskAsCompleteForSession = (id?: string) => {
  if (id) {
    const completedTasks = getTasksCompletedThisSession();

    completedTasks.push(id);

    sessionStorage.setItem(KEY, JSON.stringify(completedTasks));
  }
};

export const getResolverStartDate = (
  resolverType: ResolverType,
  regionId?: USStateId,
) => {
  const key = ['resolver_completed_start_time', resolverType, regionId]
    .filter(Boolean)
    .join('.');

  const statusStartDate = sessionStorage.getItem(key);

  if (!statusStartDate) {
    const newStatusStartDate = new Date()
      .toISOString()
      .replace('T', ' ')
      .replace('Z', '');

    sessionStorage.setItem(key, newStatusStartDate);

    return newStatusStartDate;
  }

  return statusStartDate;
};
