import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useFetcher,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { ActionBanner } from '@mosey/components/layout/ActionBanner';
import { useScrollToElement } from '@mosey/components/hooks/useScrollToElement';
import { api } from '../../../utils/fetchApi';
import { PolicyDetailSection } from '../components/PolicyDetailSection';
import { PolicyOverviewCard } from '../components/PolicyOverviewCard';
import { CheckIcon } from '@heroicons/react/outline';
import { usePolicies } from '../hooks/usePolicies';
import { useEffect } from 'react';

export const PolicySectionDetailView = () => {
  const { sectionId } = useParams();
  const { hash } = useLocation();
  const { hasActionablePolicies, policies, currentSection, nextSection } =
    usePolicies(sectionId);
  const { Form, state } = useFetcher();

  const { section } = currentSection;

  const [scrollContainerRef, retriggerScroll] =
    useScrollToElement<HTMLDivElement>({
      elementId: hash.slice(1),
      // leave room for sticky banner if no actionable policies
      bufferSpacing: hasActionablePolicies ? 40 : 120,
    });

  useEffect(() => {
    retriggerScroll();
  }, [sectionId]);

  return (
    <div className="relative flex max-h-[calc(100vh-144px)] min-h-[calc(100vh-144px)]">
      <div className="min-w-[288px] overflow-y-auto border-r border-gray-200">
        <Form className="p-6" method="POST">
          <h2 className="text-xl font-semibold">{section?.title} Policies</h2>
          {section?.description && (
            <p className="mt-2">{section.description}</p>
          )}
          {hasActionablePolicies ? (
            <Button
              className="mt-4"
              type="submit"
              name="intent"
              value="adopt-all-policies"
              isLoading={state !== 'idle'}
            >
              Adopt all
            </Button>
          ) : (
            <div className="mt-4 flex min-h-9 items-center gap-1 text-zinc-800">
              <CheckIcon className="size-5" />
              <p className="font-bold">{policies.length} policies adopted</p>
            </div>
          )}
        </Form>
        <ul className="max-w-[288px] overflow-x-hidden border-t border-gray-200">
          {policies.map((policy) => (
            <PolicyOverviewCard key={policy.id} policy={policy} variant="nav" />
          ))}
        </ul>
      </div>
      <div
        className="relative flex-1 overflow-auto py-6 pl-6 pr-8"
        ref={scrollContainerRef}
      >
        {!hasActionablePolicies && (
          <div className="sticky top-0 mb-10">
            <ActionBanner
              to={
                nextSection
                  ? `/handbook/section/${nextSection.section.id}`
                  : `/handbook`
              }
            >
              You&apos;re done with {section.title} Policies
            </ActionBanner>
          </div>
        )}
        {policies.map((policy) => (
          <PolicyDetailSection key={policy.id} policy={policy} />
        ))}
      </div>
    </div>
  );
};

PolicySectionDetailView.loader = async ({ params }: LoaderFunctionArgs) => {
  return api({
    url: `/api/handbook/policy_section/${params.sectionId}/attribute_values`,
    method: 'GET',
  });
};

PolicySectionDetailView.action = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const formData = await request.formData();
  const intent = formData.get('intent') as string;

  if (intent === 'adopt-all-policies') {
    return api({
      url: `/api/handbook/policy_section/${params.sectionId}/adopt`,
      method: 'POST',
    });
  } else if (intent === 'adopt-policy') {
    return api({
      url: `/api/handbook/policies/${formData.get('policy_id')}/adopt`,
      method: 'POST',
    });
  }
};
