import { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { CheckIcon, SearchIcon } from '@heroicons/react/solid';
import { USStateAbbrev } from '@mosey/utils/constants/us-states';
import { Transition } from '@headlessui/react';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { NextButton } from '../common/NextButton';
import {
  useNewTasks,
  useNewTasksTimeToReveal,
  useNewTaskVisibility,
  useResolverUrl,
  useTask,
  useTaskLocation,
} from '../utils/hooks';
import successImage from '../../../assets/success.svg';
import automatedImage from '../../../assets/automated.svg';
import { TaskRef, TaskStatus, TaskType } from '../utils/types';
import { TaskActions } from '../common/TaskActions';
import { getStateSealImage } from '../../../utils/seals';
import { TaskContainer } from './TaskContainer';

const NewTask = ({ task: { id, status, title } }: { task: TaskRef }) => {
  const urlPrefix = useResolverUrl();
  const isVisible = useNewTaskVisibility(id);

  return (
    <TaskSummaryCard
      as="li"
      status={status}
      title={title}
      to={`${urlPrefix}/tasks/${id}`}
      isVisible={isVisible}
    />
  );
};

const Skeleton = () => {
  return (
    <div className="flex w-32 items-center gap-3 rounded bg-teal-50 p-2 shadow">
      <CheckIcon className="size-3 rounded-full bg-teal-600 text-teal-50" />
      <div className="space-y-1">
        <div className="h-2 w-10 animate-pulse rounded-full bg-teal-600"></div>
        <div className="h-2 w-16 animate-pulse rounded-full bg-teal-500"></div>
      </div>
    </div>
  );
};

enum AnalyzingStage {
  IDLE,
  ANALYZING,
  GENERATING,
}

export const TaskSummary = () => {
  const { source, status, title } = useTask();
  const isQuestionTask = source.type === TaskType.Question;
  const isManaged = !isQuestionTask && source.managed_provider;
  const taskLocation = useTaskLocation();
  const [analyzingStage, setAnalyzingStage] = useState<AnalyzingStage>(
    isManaged ? AnalyzingStage.IDLE : AnalyzingStage.ANALYZING,
  );
  const newTasks = useNewTasks();
  const { analyzingTime, generatingTime, totalRevealTime } =
    useNewTasksTimeToReveal();

  let heading = `${newTasks.length} new compliance task${newTasks.length > 1 ? 's' : ''} detected`;
  let body = `These tasks are a result of completing ${title}`;

  if (analyzingStage === AnalyzingStage.ANALYZING) {
    heading = 'Analyzing Requirements';
    body = `Checking compliance rules${taskLocation ? ` for ${taskLocation.name}` : ''}…`;
  } else if (analyzingStage === AnalyzingStage.GENERATING) {
    heading = 'Analyzing Requirements';
    body = 'Generating new tasks…';
  } else if (isManaged) {
    heading = 'You automated this task!';
    body = "Pat yourself on the back. That's one less item on your list.";
  }

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (analyzingStage === AnalyzingStage.ANALYZING) {
      timeoutId = setTimeout(
        () => setAnalyzingStage(AnalyzingStage.GENERATING),
        analyzingTime,
      );
    } else if (analyzingStage === AnalyzingStage.GENERATING) {
      timeoutId = setTimeout(
        () => setAnalyzingStage(AnalyzingStage.IDLE),
        generatingTime + totalRevealTime,
      );
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [analyzingStage, analyzingTime, generatingTime, totalRevealTime]);

  return (
    <TaskContainer>
      <div className="flex grow flex-col items-center justify-center gap-y-6">
        {isManaged ? (
          <img
            src={
              status === TaskStatus.InProgress ? automatedImage : successImage
            }
            alt=""
          />
        ) : (
          <div
            className={clsx(
              'relative flex size-32 flex-col justify-center rounded-full text-stone-800 transition-colors',
              {
                'bg-teal-600': analyzingStage !== AnalyzingStage.IDLE,
                'bg-white': analyzingStage === AnalyzingStage.IDLE,
              },
            )}
          >
            <img
              src={
                taskLocation
                  ? getStateSealImage(taskLocation.code as USStateAbbrev)
                  : successImage
              }
              className={clsx('absolute inset-0 size-full transition-opacity', {
                'opacity-20': analyzingStage !== AnalyzingStage.IDLE,
                'opacity-100': analyzingStage === AnalyzingStage.IDLE,
              })}
              alt={taskLocation ? `${taskLocation.name} State seal` : ''}
            />

            <Transition
              as="div"
              show={analyzingStage !== AnalyzingStage.IDLE}
              leave="transition-opacity"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              aria-hidden="true"
              className="relative z-20 flex flex-col gap-y-2"
              unmount
            >
              <div className="ml-8">
                <Skeleton />
              </div>
              <div className="ml-4">
                <Skeleton />
              </div>
              <SearchIcon className="absolute right-8 top-1/2 size-8 animate-orbit" />
            </Transition>
          </div>
        )}

        <div className="flex w-full max-w-3xl flex-col gap-y-2">
          <h1 className="text-center text-4xl font-bold">{heading}</h1>
          <p className="text-center text-sm text-zinc-600">{body}</p>

          <ul className="my-4 space-y-2">
            {newTasks.map((newTask) => (
              <NewTask key={newTask.id} task={newTask} />
            ))}
          </ul>
        </div>
      </div>

      <TaskActions isHidden={analyzingStage !== AnalyzingStage.IDLE}>
        <NextButton defaultToHome />
      </TaskActions>
    </TaskContainer>
  );
};
