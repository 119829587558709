import { Link, Navigate, useParams } from 'react-router-dom';
import {
  MailOpenIcon,
  AnnotationIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';
import { USStateAbbrev } from '@mosey/utils/constants/us-states';
import { CheckIcon } from '@heroicons/react/solid';
import { Button } from '@mosey/components/buttons/Button';
import {
  useTodoTasks,
  useResolverUrl,
  useResolverLocation,
} from '../utils/hooks';
import successCloudImage from '../../../assets/success-cloud.svg';
import smallCloudImage from '../../../assets/small-cloud.svg';
import confetti from '../../../assets/tasks-framework/task-resolver-interstitial-confetti.svg';
import { getStateSealImage } from '../../../utils/seals';
import {
  ResolverType,
  LocationResolverRouteParams,
  ResolverRouteParams,
} from '../utils/types';
import { ResolverSidebar } from './Sidebar';
import { useUser } from '../../../hooks/useUser';

import { InterstitialContainer } from '../tasks/InterstitialContainer';
import { NextButton } from '../common/NextButton';

const stateSetupCompleteBullets = [
  {
    title: 'Weekly Summary',
    description:
      'You will receive a weekly summary email that includes any upcoming tasks',
    Icon: MailOpenIcon,
  },
  {
    title: 'Threshold Notifications',
    description:
      'You will be notified when you hit thresholds such as employee counts or payroll amounts that require further action',
    Icon: AnnotationIcon,
  },
  {
    title: '24/7 Monitoring',
    description:
      'Ongoing monitoring of upcoming laws and changes in requirements ensure that you will always be compliant',
    Icon: ShieldCheckIcon,
  },
];

const StateSetupComplete = () => {
  const location = useResolverLocation()!;

  return (
    <InterstitialContainer
      percentComplete={100}
      title={
        <>
          You’re all set up in{' '}
          <span className="text-teal-800">{location.name}</span>!
        </>
      }
      description="Activate monitoring, alerting, and automation for state and local compliance."
      rightHandContent={
        <div
          className="bg-no-repeat py-8 sm:w-[512px] sm:bg-[center_top_90px] sm:pb-0 sm:pt-[135px]"
          style={{ backgroundImage: `url(${confetti})` }}
        >
          <div className="relative mx-auto size-[320px] overflow-hidden rounded-full bg-white">
            <div
              className="absolute inset-0 overflow-visible bg-teal-100 mix-blend-color"
              aria-hidden
            />
            <img
              className="absolute inset-0 opacity-50 grayscale"
              src={getStateSealImage(location.code as USStateAbbrev)}
              alt={`${location.name} state seal`}
            />
            <div
              className="absolute inset-0 bg-teal-400 opacity-50 mix-blend-color"
              aria-hidden
            />
          </div>
        </div>
      }
      actions={<NextButton to="/home">Finish</NextButton>}
    >
      <ul className="max-w-xl space-y-10 text-zinc-700">
        {stateSetupCompleteBullets.map(({ title, description, Icon }) => (
          <li key={title} className="flex gap-x-3">
            <Icon className="mt-1 size-6 shrink-0 text-teal-700" aria-hidden />
            <div>
              <p className="text-2xl font-semibold text-teal-900">{title}</p>
              <p>{description}</p>
            </div>
          </li>
        ))}
      </ul>
    </InterstitialContainer>
  );
};

const StateComplete = () => {
  const { resolverType } = useParams<LocationResolverRouteParams>();
  const location = useResolverLocation()!;
  const resolverUser = useUser().legal_entity.use_resolvers;

  if (!location) {
    return <Navigate to="/home" replace />;
  }

  const setupUrl = resolverUser
    ? `/locations/usa/${location.code}/resolver/setup`
    : `/locations/usa/${location.code}/setup`;

  return (
    <>
      <div className="relative">
        <img
          className="w-96"
          src={successCloudImage}
          width="474"
          height="374"
          alt=""
        />

        <div className="absolute right-28 top-24 z-20 -translate-x-2 -translate-y-2 rounded-full border border-lime-500 bg-lime-500 p-1 text-white">
          <div className="size-5">
            <CheckIcon />
          </div>
        </div>

        <img
          className="absolute left-1/2 top-1/2 size-36 -translate-x-1/2 -translate-y-1/2"
          src={getStateSealImage(location.code as USStateAbbrev)}
          alt={`${location.name} state seal`}
        />

        <img
          className="absolute right-20 top-24 w-16 -translate-y-2 translate-x-6"
          src={smallCloudImage}
          alt=""
        />

        <img
          className="absolute bottom-24 left-20 w-16 -translate-x-2 translate-y-4"
          src={smallCloudImage}
          alt=""
        />
      </div>

      <div className="max-w-xl space-y-6 text-center">
        <h1 className="text-5xl font-black">
          {resolverType === ResolverType.Assessment
            ? `You’re ready to set up ${location.name}!`
            : 'Great Job!'}
        </h1>
        <p className="text-lg font-medium">
          {resolverType === ResolverType.Assessment
            ? 'New tasks have been identified based on your answers.'
            : 'All outstanding tasks have been completed.'}
        </p>
      </div>

      <Button
        size="xlarge"
        as={Link}
        to={resolverType === ResolverType.Assessment ? setupUrl : '/home'}
      >
        {resolverType === ResolverType.Assessment
          ? `Set up ${location.name}`
          : 'Finish'}
      </Button>
    </>
  );
};

const GenericComplete = () => {
  return (
    <>
      <div className="relative">
        <img className="w-96" src={successCloudImage} alt="" />

        <div className="absolute left-1/2 top-1/2 size-36 -translate-x-1/2 -translate-y-1/2 rounded-full bg-lime-500 text-white">
          <CheckIcon strokeWidth={3} aria-hidden="true" />
        </div>

        <img
          className="absolute right-20 top-24 w-16 -translate-y-2 translate-x-6"
          src={smallCloudImage}
          alt=""
        />

        <img
          className="absolute bottom-24 left-20 w-16 -translate-x-2 translate-y-4"
          src={smallCloudImage}
          alt=""
        />
      </div>

      <div className="max-w-xl space-y-6 text-center">
        <h1 className="text-5xl font-black">Great Job!</h1>
        <p className="text-lg font-medium">
          All outstanding tasks have been completed.
        </p>
      </div>

      <Button size="xlarge" as={Link} to="/home">
        Finish
      </Button>
    </>
  );
};

export const ResolverComplete = () => {
  const { locationId, resolverType } = useParams<ResolverRouteParams>();
  const todoTasks = useTodoTasks();
  const resolverUrl = useResolverUrl();

  if (todoTasks.length > 0) {
    return <Navigate to={resolverUrl || '/home'} replace />;
  }

  if (resolverType === ResolverType.Setup) {
    return <StateSetupComplete />;
  }

  return (
    <div className="flex h-full max-w-screen-2xl @container/task">
      <ResolverSidebar />

      <section className="mb-24 flex size-full flex-col items-center justify-center gap-y-6 self-center overflow-y-auto">
        {locationId ? <StateComplete /> : <GenericComplete />}
      </section>
    </div>
  );
};
