import { Link, LinkProps } from 'react-router-dom';
import { useNextResolverUrl } from '../utils/hooks';
import { Button } from '@mosey/components/buttons/Button';

interface NextButtonProps {
  to?: LinkProps['to'];
  children?: React.ReactNode;
  defaultToHome?: boolean;
}

export const NextButton = ({
  to,
  children,
  defaultToHome,
}: NextButtonProps) => {
  const nextResolverUrl = useNextResolverUrl();
  const url = to || nextResolverUrl;

  if (url) {
    return (
      <Button as={Link} to={url} size="xlarge">
        {children || 'Continue'}
      </Button>
    );
  }

  if (defaultToHome) {
    return (
      <Button as={Link} to="/home" size="xlarge">
        Finish
      </Button>
    );
  }

  return null;
};
