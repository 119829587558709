type PageHeaderProps = {
  children: React.ReactNode;
};

export const PageHeader = ({ children }: PageHeaderProps) => {
  return (
    <header className="sticky top-0 z-10 flex min-h-20 items-center gap-10 border-b border-gray-200 bg-white px-8 py-4">
      {children}
    </header>
  );
};
