import { FunctionComponent, ReactNode, useState } from 'react';
import {
  Link,
  useParams,
  Navigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { clsx } from 'clsx';
import { Button } from '@mosey/components/buttons/Button';
import { BatchApiStatusHandler } from '../components';
import { useBatchApi } from '../hooks';
import { ACTIVE_PLANS, SubscriptionPlanEnum } from '../types';
import logo from '../assets/logo.svg';

type CheckoutProps = {
  url: string;
};

const Checkout: FunctionComponent<CheckoutProps> = ({ url }) => {
  if (window?.top) {
    window.top.location = url;
  }
  return <></>;
};

export const CheckoutView: FunctionComponent = () => {
  const { planId } = useParams<Record<string, string>>();

  if (!planId) {
    throw Error('Plan missing');
  }

  const batchResponse = useBatchApi([
    {
      url: `/api/subscription/checkout/${planId}`,
      method: 'GET',
    },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ([checkoutResponse]: any[]): CheckoutProps => {
    return {
      url: checkoutResponse.checkout_url,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={Checkout}
      componentProps={componentPropsFn}
    />
  );
};

type PlanTabContentProps = {
  title: string;
  tagLine: string;
  description: string;
  monthlyPrice: string;
  pricePerEmployee?: string;
  implementationFee?: string;
};

const PlanTabContent: FunctionComponent<PlanTabContentProps> = ({
  title,
  tagLine,
  description,
  monthlyPrice,
  pricePerEmployee,
  implementationFee,
}) => (
  <div className="flex w-full flex-wrap gap-x-12">
    <div className="flex-1">
      <h3 className="text-2xl font-bold text-zinc-800">{title}</h3>
      <span className="mb-5 mt-6 inline-flex rounded-full bg-teal-400 px-4 py-2 text-sm font-medium text-teal-800">
        {tagLine}
      </span>
      <p className="text-sm leading-6 text-zinc-800">{description}</p>
    </div>

    <div className="flex flex-1 flex-col items-center justify-end">
      <div
        className={clsx(
          'mb-4 flex flex-wrap items-center justify-center gap-x-3 py-5 pr-5',
          {
            'border-b': pricePerEmployee || implementationFee,
          },
        )}
      >
        <div className="flex gap-x-1">
          <span className="mt-2 text-4xl font-medium">$</span>
          <span className="text-6xl font-bold">{monthlyPrice}</span>
        </div>
        <div>
          <p className="text-2xl font-medium">Month</p>
          <p className="text-sm font-medium text-gray-500">Platform fee</p>
        </div>
      </div>
      {pricePerEmployee && (
        <p className="text-xl font-bold">{`$${pricePerEmployee} Per Employee / Month`}</p>
      )}
      {implementationFee && (
        <p className="text-xl font-bold">{`$${implementationFee} Implementation fee`}</p>
      )}
    </div>
  </div>
);

type PlanSelectionProps = {
  // Note: This is a short-term case for trying out a free plan and should be removed when the experiment is done
  shouldShowFreePlan: boolean;
  shouldShowBasicPlan: boolean;
};

type PlansTabs = {
  label: string;
  id: SubscriptionPlanEnum;
};

const plansTabs: PlansTabs[] = [
  {
    label: 'Free',
    id: SubscriptionPlanEnum.Free,
  },
  {
    label: 'Stripe Atlas',
    id: SubscriptionPlanEnum.Basic,
  },
  {
    label: 'Plus',
    id: SubscriptionPlanEnum.Plus,
  },
  {
    label: 'Standard',
    id: SubscriptionPlanEnum.Standard,
  },
  {
    label: 'Advanced',
    id: SubscriptionPlanEnum.Advanced,
  },
];

type PlansContent = {
  [key in SubscriptionPlanEnum]?: ReactNode;
};

const plansContent: PlansContent = {
  [SubscriptionPlanEnum.Free]: (
    <PlanTabContent
      title="Free Plan"
      tagLine="Get 1 free registration"
      pricePerEmployee="0"
      monthlyPrice="0"
      description="Open tax accounts in a new state for free including withholding tax and unemployment insurance accounts."
    />
  ),
  [SubscriptionPlanEnum.Basic]: (
    <PlanTabContent
      title="Stripe Atlas Plan"
      tagLine="Designed for Stripe Atlas companies"
      monthlyPrice="75"
      description="Stay compliant after incorporating—setup payroll tax accounts and foreign qualify in your first state, and access our automated mailroom."
    />
  ),
  [SubscriptionPlanEnum.Plus]: (
    <PlanTabContent
      title="Plus Plan"
      tagLine="Up to 100 employees"
      monthlyPrice="399"
      implementationFee="399"
      description="Ideal for startups who need state tax registration and compliance monitoring to eliminate manual work."
    />
  ),
  [SubscriptionPlanEnum.Standard]: (
    <PlanTabContent
      title="Standard Plan"
      tagLine="Up to 500 employees"
      monthlyPrice="899"
      implementationFee="1,999"
      description="Ideal for multi-state businesses who need ongoing state and local compliance to streamline teams in one system."
    />
  ),
  [SubscriptionPlanEnum.Advanced]: (
    <PlanTabContent
      title="Advanced Plan"
      tagLine="Up to 1000 employees"
      monthlyPrice="1,999"
      implementationFee="3,999"
      description="Ideal for scaling businesses who need maximum coverage and automation to operate in all states and localities."
    />
  ),
};

const PlanSelection: FunctionComponent<PlanSelectionProps> = ({
  shouldShowFreePlan,
  shouldShowBasicPlan,
}) => {
  const [searchParams] = useSearchParams();
  const selectedPlanId = searchParams.get('plan');

  // Type assertion to make compiler happy
  // We are checking if the value received is a valid (active) plan id
  const isExistingPlan = ACTIVE_PLANS.includes(
    selectedPlanId as SubscriptionPlanEnum,
  );

  const isValidPlanId =
    isExistingPlan &&
    (selectedPlanId !== SubscriptionPlanEnum.Free || shouldShowFreePlan) &&
    (selectedPlanId !== SubscriptionPlanEnum.Basic || shouldShowBasicPlan);

  const [planId, setPlanId] = useState<SubscriptionPlanEnum>(
    isValidPlanId
      ? (selectedPlanId as SubscriptionPlanEnum)
      : SubscriptionPlanEnum.Plus,
  );

  return (
    <div className="flex min-h-screen flex-col items-center justify-center px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="mb-10 w-full max-w-2xl pb-4">
        <div className="w-20">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <div className="w-full max-w-2xl space-y-8">
        <div>
          <h2 className="mt-6 text-4xl font-extrabold tracking-tight text-gray-900">
            Choose your plan
          </h2>
        </div>
        <div className="rounded-md border">
          <div className="flex w-full items-center justify-center border-b">
            {plansTabs.map(
              (plan) =>
                (plan.id !== SubscriptionPlanEnum.Free || shouldShowFreePlan) &&
                (plan.id !== SubscriptionPlanEnum.Basic ||
                  shouldShowBasicPlan) && (
                  <button
                    key={plan.id}
                    type="button"
                    onClick={() => setPlanId(plan.id)}
                    className={clsx(
                      'flex-1 border-b-2 py-8 text-center text-gray-500',
                      {
                        'text-rose-800 border-rose-800': planId === plan.id,
                        'border-transparent': planId !== plan.id,
                      },
                    )}
                  >
                    <h3 className="text-lg font-bold">{plan.label}</h3>
                  </button>
                ),
            )}
          </div>
          <div className="w-full px-7 pb-12 pt-9">
            {plansContent[planId]}

            <Button
              as={Link}
              to={`/subscription/checkout/${planId}`}
              size="xlarge"
              className="mt-9"
              isFullWidth
            >
              Choose this plan
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PlanSelectionView: FunctionComponent = () => {
  const batchResponse = useBatchApi([
    {
      url: `/api/subscription/plans`,
      method: 'GET',
    },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ([planResponse]: any[]): PlanSelectionProps => {
    return {
      shouldShowFreePlan: planResponse.should_show_free_plan,
      shouldShowBasicPlan: planResponse.should_show_basic_plan,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={PlanSelection}
      componentProps={componentPropsFn}
    />
  );
};

const ActivateSubscription: FunctionComponent = () => {
  return <Navigate to="/" />;
};

export const ActivateSubscriptionView: FunctionComponent = () => {
  const { planId } = useParams<Record<string, string>>();
  const params = new URLSearchParams(useLocation().search);
  const sessionId = params.get('session_id');

  if (!planId) {
    throw Error('Plan missing');
  }

  const batchResponse = useBatchApi([
    {
      url: `/api/subscription/activate/${planId}`,
      method: 'POST',
      body: { stripe_session_id: sessionId },
    },
  ]);

  const componentPropsFn = () => {
    return {};
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={ActivateSubscription}
      componentProps={componentPropsFn}
    />
  );
};
