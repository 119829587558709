import { FunctionComponent } from 'react';
import { useGlobalContext } from '../contexts/globalContext';
import { Navigate, useSearchParams } from 'react-router-dom';
import { BusinessInformationFormView } from './BusinessInformationForm';

export const AutomationWrapper: FunctionComponent = () => {
  const { globalState } = useGlobalContext();
  const [searchParams] = useSearchParams();
  const destination = searchParams.get('destination') || undefined;

  // TODO: when we make this component generic:
  //  - remove check for platform user
  //  - hit API forms endpoint here, iterate through all of the 'stacked' forms
  //  - render each form using generic individual form component (internal component shouldn't navigate anywhere)

  if (!globalState.user.is_platform_user && destination) {
    return <Navigate to={destination} replace />;
  } else if (globalState.user.is_platform_user) {
    return <BusinessInformationFormView destination={destination} />;
  }
  return <Navigate to="/" replace />;
};
