import { FunctionComponent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { EntityType, PersonIFormValues } from '../../types';
import { Button } from '@mosey/components/buttons/Button';
import {
  BackButton,
  FormBusiness,
  FormPeople,
  SectionHeading,
  SelectField,
} from '../../components';
import { cleanIntakeDates, unregisterByPersonType } from '../../utils/intake';
import { fetchApi } from '../../utils/fetchApi';
import { ApiStatus } from '../../utils/types';
import { useGlobalContext } from '../../contexts/globalContext';

export const cleanPeopleValues = (data: PersonIFormValues) => {
  const cleanedData = { ...data };

  switch (data.person_type) {
    case 'is_officer':
      cleanedData.is_officer = true;
      cleanedData.is_owner = false;
      break;

    case 'is_owner':
      cleanedData.is_owner = true;
      cleanedData.is_officer = false;
      break;

    case 'is_both_officer_and_owner':
      cleanedData.is_officer = true;
      cleanedData.is_owner = true;
      break;

    case 'is_member':
      cleanedData.is_llc_member = true;
      break;

    default:
      break;
  }

  return cleanedData;
};

export const OfficersAndOwnersCreateView: FunctionComponent = () => {
  const navigate = useNavigate();
  const { globalState } = useGlobalContext();
  const formMethods = useForm<PersonIFormValues>({
    mode: 'onBlur',
    defaultValues: {
      first_name: '',
      last_name: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const {
    watch,
    unregister,
    handleSubmit,
    clearErrors,
    reset,
    formState: { isDirty, isValid, errors },
  } = formMethods;

  const onCancel = () => {
    reset();
  };

  const watchPersonType = watch('person_type');

  // Unregister fields when the form structure changes
  useEffect(() => {
    unregisterByPersonType(watchPersonType, unregister);
  }, [watchPersonType]);

  const onSubmit = async (formData: PersonIFormValues) => {
    if (!isValid) return;

    setHasSubmitError(false);
    clearErrors();
    setIsLoading(true);

    let cleanedData = cleanIntakeDates(formData);
    cleanedData = cleanPeopleValues(cleanedData);

    try {
      const { status } = await fetchApi({
        url: `/api/legal_entity/${
          watchPersonType === 'is_business' ? 'businesses' : 'people'
        }`,
        method: 'POST',
        body: cleanedData,
      });

      if (
        status === ApiStatus.Error ||
        status === ApiStatus.ErrorUnauthorized
      ) {
        setHasSubmitError(true);
      } else {
        navigate('/settings/officers-and-owners');
      }
    } catch (error) {
      setHasSubmitError(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isDirty) return;

    // Reset validation messages if needed when the user touchs form inputs
    if (hasSubmitError) {
      setHasSubmitError(false);
    } else if (submitSuccess) {
      setSubmitSuccess(false);
    }
  }, [isDirty]);

  const personTypeOptions =
    globalState.user.legal_entity.entity_type === EntityType.LLC
      ? [
          { name: 'A Member', value: 'is_member' },
          { name: 'A Business', value: 'is_business' },
        ]
      : [
          { name: 'An Officer', value: 'is_officer' },
          { name: 'A Business Owner', value: 'is_owner' },
          {
            name: 'Both an Officer and Business Owner',
            value: 'is_both_officer_and_owner',
          },
          { name: 'A Business', value: 'is_business' },
        ];

  return (
    <div className="flex flex-col">
      <div className="p-16">
        <div className="mb-6">
          <BackButton />
        </div>

        <SectionHeading className="flex-1 pb-4" text="Add Officer/Owner" />

        <div className="flex flex-col py-9 xl:max-w-xl">
          <section>
            <FormProvider {...formMethods}>
              <form>
                <SelectField
                  name="person_type"
                  label="They are..."
                  error={errors.person_type}
                  options={personTypeOptions}
                  reactFormConfig={{
                    required: 'This is required',
                  }}
                />
                {watchPersonType === 'is_business' ? (
                  <FormBusiness />
                ) : (
                  <FormPeople personType={watchPersonType} />
                )}
              </form>
            </FormProvider>
          </section>
        </div>
      </div>
      {isDirty && (
        <div className="b-0 l-0 sticky bottom-0 flex h-[72px] items-center justify-end space-x-2 border-t border-gray-200 bg-sage-50 px-6 py-4">
          <Button
            type="button"
            variant="secondary"
            onClick={onCancel}
            disabled={isLoading}
          >
            Cancel
          </Button>

          <div className="w-28">
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isFullWidth
              isLoading={isLoading}
              disabled={isLoading}
            >
              Save Changes
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
