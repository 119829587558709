import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';

export const BackButton: FunctionComponent = () => {
  const navigate = useNavigate();

  // FIXME: Need an equivalent since react-router v6
  // Don't show a backbutton if there is nothing to go back to
  // if (history.length === 0) {
  //   return <></>;
  // }

  return (
    <button
      type="button"
      className="flex font-semibold hover:text-teal-800"
      onClick={() => navigate(-1)}
    >
      <svg
        className="size-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
        />
      </svg>
      <span className="ml-1">Back</span>
    </button>
  );
};
