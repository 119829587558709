import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { getSsoToken } from '../utils/auth';
import { LoadingFullScreen } from './Loading';
import { ApiStatus } from '../utils/types';
import { GENERIC_SSO_ERROR } from '../utils/constants';

export const SingleSignOnCallback: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const errorCode = searchParams.get('error');
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const throwError = (error: string) => {
    navigate(`/login/sso?error=${encodeURIComponent(error)}`, {
      replace: true,
    });
  };

  const tradeTokens = async () => {
    try {
      const response = await getSsoToken(code as string, state as string);

      if (response.status === ApiStatus.Error) {
        throwError(response.error.message);
      } else {
        navigate(response.data.state.redirect || '/', { replace: true });
      }
    } catch (err) {
      throwError(GENERIC_SSO_ERROR);
    }
  };

  useEffect(() => {
    if (code && state) {
      tradeTokens();
    } else {
      Sentry.captureMessage('SSO error', {
        extra: { errorCode },
      });
      throwError(GENERIC_SSO_ERROR);
    }
  }, []);

  return <LoadingFullScreen />;
};
