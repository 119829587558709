import { useGlobalContext } from '../../contexts/globalContext';
import { CopyButton } from '../../components/buttons/CopyButton';

import { Button } from '@mosey/components/buttons/Button';
import { Form, Link, useNavigation } from 'react-router-dom';
import { RipplingConnectWrapper } from './RipplingConnectWrapper';
import { RipplingSteps } from './RipplingSteps';
import { RipplingStep } from './RipplingStep';
import workflowstudioImg from '../../assets/rippling/workflow-studio.webp';
import startFromScratchImg from '../../assets/rippling/start-from-scratch.webp';
import nameTheWorkflowImg from '../../assets/rippling/name-the-workflow.webp';
import repeatOnScheduleImg from '../../assets/rippling/repeat-on-schedule.webp';
import scheduleDailyImg from '../../assets/rippling/schedule-daily.webp';
import actionsImg from '../../assets/rippling/actions.webp';
import sendReportEmailImg from '../../assets/rippling/send-report-email.webp';
import externalEmailImg from '../../assets/rippling/external-email.webp';
import enterEmailImg from '../../assets/rippling/enter-email.webp';

export const RipplingWorkflowView = () => {
  const {
    globalState: {
      user: {
        legal_entity: { id: publicId },
      },
    },
  } = useGlobalContext();
  const moseyEmail = `${publicId}@imports.mosey.com`;
  const { state } = useNavigation();

  return (
    <RipplingConnectWrapper>
      <RipplingSteps>
        <RipplingStep
          title={
            <>
              Go to <em>Tools</em>
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Select <em>Workflow Studio</em>
            </>
          }
          images={[workflowstudioImg]}
        />
        <RipplingStep
          title={
            <>
              If this is the first time you create a workflow, click{' '}
              <em>Start.</em> Otherwise, click <em>Create workflow</em>
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Click <em>Start from scratch</em>
            </>
          }
          images={[startFromScratchImg]}
        />
        <RipplingStep
          title={<>Name the workflow</>}
          images={[nameTheWorkflowImg]}
        />
        <RipplingStep
          title={
            <>
              Click <em>Add a Condition</em>
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Select the trigger <em>Repeat on schedule</em>
            </>
          }
          images={[repeatOnScheduleImg]}
        />
        <RipplingStep
          title={
            <>
              Set the frequency to <em>Daily</em> and choose a time
            </>
          }
          images={[scheduleDailyImg]}
        />
        <RipplingStep
          title={
            <>
              Click <em>Add condition</em> and confirm the selection
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Click <em>Add an action</em>
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Scroll down to <em>Rippling actions</em> and select{' '}
              <em>Send a report</em>
              <img src={actionsImg} alt="" />
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Search for the employee report you created in the steps above
              <img src={sendReportEmailImg} alt="" />
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Click <em>Add recipients</em>
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Select the option to <em>Add external emails as To recipients</em>
              <img src={externalEmailImg} alt="" />
            </>
          }
        />
        <RipplingStep
          title="Enter your Mosey reports email address"
          images={[enterEmailImg]}
          content={
            <div className="flex flex-col rounded-lg border border-gray-200 bg-gray-50 p-4 text-zinc-800">
              <p className="font-semibold leading-6 text-gray-900">
                Mosey reports email address:
              </p>
              <div className="flex items-center gap-4 whitespace-nowrap">
                <p className="text-gray-600">{publicId}@imports.mosey.com</p>
                <CopyButton textToCopy={moseyEmail} />
              </div>
            </div>
          }
        />
        <RipplingStep
          title="Set the following options:"
          content={
            <ul className="list-disc space-y-2 pl-6">
              <li>
                <strong>
                  Whose report view should be used to run the report?
                </strong>{' '}
                Based on{' '}
                <span className="whitespace-nowrap">[YOUR NAME]&apos;s</span>{' '}
                access level for this report
              </li>
              <li>
                <strong>File Format:</strong> CSV
              </li>
              <li>
                <strong>Currency fields:</strong> Leave the default option
              </li>
              <li>
                <strong>Include total & subtotal rows:</strong> Leave{' '}
                <em>on</em>
              </li>
            </ul>
          }
        />
        <RipplingStep
          title={
            <>
              Click <em>Done</em>
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Click <em>Create workflow</em>
            </>
          }
        />
      </RipplingSteps>

      <Form
        className="mt-10 flex justify-center gap-4"
        method="post"
        action="/settings/integrations"
      >
        <Button
          variant="secondary"
          to="/onboarding/rippling/report"
          as={Link}
          isFullWidth
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="primary"
          isDisabled={state !== 'idle'}
          isLoading={state !== 'idle'}
          isFullWidth
        >
          I&apos;ve completed this
        </Button>
      </Form>
    </RipplingConnectWrapper>
  );
};
