import { FunctionComponent, useMemo, useRef, useState } from 'react';
import {
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import * as config from '../settings/config';
import * as Sentry from '@sentry/react';
import {
  PDFIcon,
  DownloadIcon,
  PrintIcon,
  CheckIcon,
  LogoIcon,
} from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { BatchApiStatusHandler } from '../components';
import { TextField } from '@mosey/components/forms/TextField';
import { useBatchApi } from '../hooks';
import { logout } from '../utils/auth';
import { useCopy } from '@mosey/components/hooks/useCopy';
import { safelyUpdateLocation } from '../utils/redirect';

type Region = {
  code: string;
  name: string;
};

type Notice = {
  id: number;
  title: string;
  region: Region;
  file_key: string;
};

type NoticesListProps = {
  public_key: string;
  notices: Notice[];
  entity_name: string;
};

type NoticeProps = {
  notice: Notice;
  baseUrl: string;
  noticeFrameRef: React.RefObject<HTMLIFrameElement>;
};

const ALL_LOCATIONS = 'All Locations';

const NoticeEntry = ({ notice, baseUrl, noticeFrameRef }: NoticeProps) => {
  const [shouldShowNotice, setShouldShowNotice] = useState<boolean>(false);
  const noticeRef = useRef<HTMLDivElement>(null);

  const fileUrl = `${baseUrl}/${notice.file_key}`;
  const { pathname } = useLocation();

  // If we're embedded (like in Notion) then actions will be blocked by some browsers.
  // We need to redirect first, and then take the intended action.
  const redirectIfEmbedded = (action: string) => {
    if (window !== window.parent && window.top) {
      window.top.location = `${pathname}?${action}=${notice.id}`;
      return true;
    }
    return false;
  };

  // Open the PDF for in-line viewing.
  const viewHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (redirectIfEmbedded('view')) {
      e.preventDefault();
      return;
    }

    setShouldShowNotice(!shouldShowNotice);
  };

  // Load the PDF in a hidden iframe and open a print dialog.
  const printHandler = () => {
    if (redirectIfEmbedded('print')) {
      return;
    }

    const openPrintNoticeDialog = () => {
      const w = noticeFrameRef.current?.contentWindow;
      w?.focus();
      w?.print();
    };

    // Printing is not allowed cross domain in most browsers, so we need to load the pdf as a blob
    fetch(fileUrl)
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        if (noticeFrameRef.current) {
          noticeFrameRef.current.src = URL.createObjectURL(
            new Blob([resp], { type: 'application/pdf' }),
          );

          // Without a small delay Firefox tries to print a blank page
          setTimeout(openPrintNoticeDialog, 500);
        }
      });
  };

  // Download the PDF.
  const downloadHandler = () => {
    if (redirectIfEmbedded('download')) {
      return;
    }

    if (window.top) {
      window.top.location = `${fileUrl}?download=True`;
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  if (searchParams.get('view') === notice.id.toString()) {
    if (!shouldShowNotice) {
      setShouldShowNotice(true);
    }
    searchParams.delete('view');
    setSearchParams(searchParams);
  }
  if (searchParams.get('print') === notice.id.toString()) {
    printHandler();
    searchParams.delete('print');
    setSearchParams(searchParams);

    // A slight delay is needed here to allow for the element to render before scrolling.
    setTimeout(() => {
      noticeRef.current?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }, 500);
  }
  if (searchParams.get('download') === notice.id.toString()) {
    downloadHandler();
    searchParams.delete('download');
    setSearchParams(searchParams);

    // A slight delay is needed here to allow for the element to render before scrolling.
    setTimeout(() => {
      noticeRef.current?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }, 500);
  }

  return (
    <Disclosure as="li" ref={noticeRef}>
      <div className="flex items-center justify-end gap-x-2 px-4 text-sm">
        <DisclosureButton
          onClick={viewHandler}
          className="mr-auto py-6 font-bold"
        >
          <PDFIcon className="mr-4 inline w-6" />
          {notice.title}
        </DisclosureButton>

        <DisclosureButton onClick={viewHandler} className="text-teal-700">
          View
        </DisclosureButton>

        <button type="button" onClick={printHandler} className="border-l pl-2">
          <PrintIcon className="w-6 text-gray-400" />
        </button>

        <button type="button" onClick={downloadHandler}>
          <DownloadIcon className="w-6 text-gray-400" />
        </button>
      </div>

      <DisclosurePanel static>
        {shouldShowNotice && (
          <iframe
            src={fileUrl}
            title="Notice"
            className="h-screen w-full justify-center px-12 py-4"
            onLoad={() =>
              noticeRef?.current?.scrollIntoView({ behavior: 'smooth' })
            }
          />
        )}
      </DisclosurePanel>
    </Disclosure>
  );
};

const NoticesList: FunctionComponent<NoticesListProps> = ({
  notices,
  public_key: publicKey,
  entity_name: entityName,
}) => {
  const noticeFrameRef = useRef<HTMLIFrameElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredRegion, setFilteredRegion] = useState<string>(
    searchParams.get('region') ?? ALL_LOCATIONS,
  );
  const [dismissedBanner, setDismissedBanner] = useState<boolean>(false);

  const groupedNotices = useMemo(() => {
    return Object.entries(
      notices
        .filter(
          (n) =>
            filteredRegion === ALL_LOCATIONS ||
            n.region.name === filteredRegion,
        )
        .reduce((acc: { [region: string]: Notice[] }, notice) => {
          if (!acc[notice.region.name]) {
            acc[notice.region.name] = [];
          }

          acc[notice.region.name].push(notice);

          return acc;
        }, {}),
    ).sort(([a], [b]) => a.localeCompare(b));
  }, [notices, filteredRegion]);

  const regions = notices
    .map((n) => n.region.name)
    .filter((v, i, a) => i === a.indexOf(v))
    .sort((a, b) => a.localeCompare(b));

  const baseFileUrl = `${config.API_BASE_URL}/api/notices/view/${publicKey}`;

  function onFilterRegion(e: React.ChangeEvent<HTMLSelectElement>) {
    const region = e.target.value;

    if (region === ALL_LOCATIONS) {
      searchParams.delete('region');
    } else {
      searchParams.set('region', region);
    }

    setFilteredRegion(region);
    setSearchParams(searchParams);
  }

  return (
    <div>
      <header className="flex items-center bg-rose-700 py-1 pl-8 pr-6 text-white">
        <LogoIcon className="my-4 mr-1 inline-block w-20" />
      </header>

      <div className="mx-7 mt-4">
        {!dismissedBanner && (
          <div className="mb-6 flex justify-between bg-teal-700 p-4">
            <p className="m-2 p-1 font-bold text-white">
              {entityName} is using Mosey to help you stay informed about
              mandatory employment practices and employee rights in your state
            </p>
            <Button
              onClick={() => {
                setDismissedBanner(true);
              }}
              type="button"
              size="xlarge"
            >
              Ok Got it!
            </Button>
          </div>
        )}

        <h1 className="my-8 text-2xl font-bold tracking-tight text-gray-900">
          Posters and Notices
        </h1>

        <div className="mb-3 flex items-end justify-between text-zinc-800">
          <p>
            <span className="font-bold">{regions.length} Locations</span> -{' '}
            {notices.length} Notices
          </p>
          <span className="pr-1 text-xs tracking-wider">
            <label htmlFor="region-filter" className="pr-2 font-bold">
              Filter by:
            </label>
            <select
              id="region-filter"
              className="rounded border-gray-300"
              onChange={onFilterRegion}
              value={filteredRegion}
            >
              {[ALL_LOCATIONS, ...regions].map((r) => (
                <option key={r}>{r}</option>
              ))}
            </select>
          </span>
        </div>

        <div className="border">
          {groupedNotices.map(([regionName, notices]) => (
            <article key={regionName} className="border-b last:border-b-0">
              <h2 className="border-b bg-sage-200 p-4 font-bold text-gray-900">
                {regionName}
              </h2>

              <ul className="divide-y">
                {notices.map((notice) => (
                  <NoticeEntry
                    key={notice.id}
                    notice={notice}
                    baseUrl={baseFileUrl}
                    noticeFrameRef={noticeFrameRef}
                  />
                ))}
              </ul>
            </article>
          ))}
        </div>

        <iframe
          ref={noticeFrameRef}
          title="Notice Print"
          className="absolute size-0 border-0"
        />
      </div>
    </div>
  );
};

export const NoticesView: FunctionComponent = () => {
  const { publicKey } = useParams<Record<string, string>>();

  if (!publicKey) {
    throw Error('Notice key missing');
  }

  const batchResponse = useBatchApi([
    { url: `/api/notices/summary/${publicKey}`, method: 'GET' },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ([noticesResponse]: any[]): NoticesListProps => {
    return {
      /* eslint-disable camelcase */
      notices: noticesResponse.notices,
      public_key: publicKey,
      entity_name: noticesResponse.entity_name,
      /* eslint-enable camelcase */
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={NoticesList}
      componentProps={componentPropsFn}
    />
  );
};

export type NoticeEnrollmentProps = {
  publicKey: string;
  regionName: string;
  callbackUrl?: string | null;
};

export const NoticeEnrollment: FunctionComponent<NoticeEnrollmentProps> = ({
  publicKey,
  regionName,
  callbackUrl,
}) => {
  const url = `${config.EMBED_BASE_URL}/notices/summary/${publicKey}?region=${regionName}`;
  const [onCopy, isCopied] = useCopy(url);

  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  const navigate = useNavigate();

  const continueHandler = () => {
    if (callbackUrl) {
      let parseCallbackUrl;

      try {
        parseCallbackUrl = new URL(callbackUrl);
      } catch (error) {
        Sentry.captureException(error);
      }

      if (parseCallbackUrl?.host !== window.location.host) {
        logout().then(() => safelyUpdateLocation(callbackUrl));
      } else {
        safelyUpdateLocation(callbackUrl);
      }

      return;
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="flex justify-center px-10">
      <div className="max-w-lg pt-14">
        <div className="flex w-full justify-center pb-2">
          <CheckIcon className="size-12 rounded-full bg-green-600 p-1 text-white" />
        </div>
        <h1 className="w-full pb-10 text-center text-2xl font-bold">
          Employee Notice Board Updated
        </h1>
        <div className="rounded border-1 bg-sage-50">
          <div className="p-9">
            <h2 className="pb-6 text-xl font-bold">
              Share With Your Employees
            </h2>
            <p className="text-sm">
              All required notices and posters for {regionName} are now
              available on your{' '}
              <a href={url} className="text-blue-500">
                Notice Board
              </a>
              . Copy the link below to an internal wiki or document that is
              readibly accessible to all remote employees and notify them of
              where to find it. Updates will happen automatically for all
              regions you add.
            </p>
          </div>
          <div className="border-t p-9">
            <p className="text-sm">
              If you have a physical office in {regionName}, print the notices
              and post them in an area that is accessible and visible to all
              employees.
            </p>
            <FormProvider {...formMethods}>
              <form
                className="flex w-full pt-9"
                onSubmit={handleSubmit(onCopy)}
              >
                <TextField
                  name="url"
                  readOnly
                  value={url}
                  inputClassName="rounded-r-none"
                  hasMargin={false}
                  isFullWidth
                />
                <Button
                  size="large"
                  className="w-48 rounded-l-none"
                  variant="secondary"
                >
                  {isCopied ? 'Link copied' : 'Copy link'}
                </Button>
              </form>
            </FormProvider>
          </div>
        </div>
        <div className="flex w-full justify-center pt-9">
          <Button size="large" className="w-48" onClick={continueHandler}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export const NoticeEnrollmentView: FunctionComponent = () => {
  const { locationId, requirementId } = useParams<Record<string, string>>();

  if (!locationId) {
    throw Error('Location missing');
  }

  if (!requirementId) {
    throw Error('Requirement missing');
  }

  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get('callback_url') || undefined;

  const batchResponse = useBatchApi([
    {
      url: `/api/notices/enrollment/${locationId}/${requirementId}`,
      method: 'POST',
    },
    {
      url: `/api/regions/${locationId}`,
      method: 'GET',
    },
  ]);

  const componentPropsFn = ([
    enrollmentResponse,
    regionResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): NoticeEnrollmentProps => {
    return {
      publicKey: enrollmentResponse.public_key,
      regionName: regionResponse.name,
      callbackUrl,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={NoticeEnrollment}
      componentProps={componentPropsFn}
    />
  );
};
