import { FunctionComponent } from 'react';
import { Inbox, TaskNotice } from '../components';
import { Mail, User, LegalEntityRegion, RegionStatus } from '../types';

export type MailRoomProps = {
  isIncorporationState: boolean;
  isForeignQualified: boolean;
  mailId?: string;
  messages: Mail[];
  users: User[];
  toggleReloadView: () => void;
  legalEntityRegion?: LegalEntityRegion;
};

export const MailRoom: FunctionComponent<MailRoomProps> = ({
  isIncorporationState,
  isForeignQualified,
  mailId,
  messages,
  users,
  toggleReloadView,
  legalEntityRegion,
}) => {
  const shouldShowRegisteredAgentBanner =
    legalEntityRegion &&
    !legalEntityRegion.registered_agent_transfer &&
    legalEntityRegion.status !== RegionStatus.Entering &&
    (isIncorporationState || isForeignQualified);

  return (
    <>
      {shouldShowRegisteredAgentBanner && (
        <div>
          <TaskNotice
            className="mt-0 rounded-none border-transparent bg-teal-700 text-white"
            heading="Registered Agent Transfer"
            body="You can transfer your existing registered agent to Mosey and read your mail here."
            url={`/automation/registered-agent-transfer/${legalEntityRegion.region.id}`}
            cta="Start transfer"
            buttonVariant="primary"
          />
        </div>
      )}
      <div className="size-full p-6">
        <div className="h-full border bg-white">
          <Inbox
            messages={messages}
            users={users}
            toggleReloadView={toggleReloadView}
            mailId={mailId}
          />
        </div>
      </div>
    </>
  );
};
