import {
  InputHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  forwardRef,
} from 'react';
import { FieldError as ReactHookFieldError } from 'react-hook-form';
import { clsx } from 'clsx';

export type TextFieldSizes = 'small' | 'medium' | 'large';

type BaseFormFieldCommon = {
  error?: ReactHookFieldError;
  className?: string;
};

export type BaseTextFieldProps = {
  size?: TextFieldSizes;
} & BaseFormFieldCommon &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

export const classes = {
  size: {
    small: 'py-2 text-xs',
    medium: 'py-2 px-3 text-base',
    large: 'py-3 px-6 text-md',
  },
  variant: {
    error: 'border-red-300 focus:border-red-500 focus:ring-red-500',
    default: 'focus:border-teal-500 focus:ring-teal-500',
  },
};

export const BaseTextField = forwardRef<HTMLInputElement, BaseTextFieldProps>(
  function BaseTextField({ className, error, size = 'medium', ...props }, ref) {
    return (
      <input
        ref={ref}
        className={clsx(
          'block w-full rounded border-gray-300 transition-shadow duration-150',
          {
            [classes.size[size]]: !!size,
            [classes.variant.error]: error,
            [classes.variant.default]: !error,
          },
          className,
        )}
        {...props}
      />
    );
  },
);

export type BaseSelectFieldProps = {
  size?: TextFieldSizes;
  options?: { name?: string; value: string | number; disabled?: boolean }[];
} & BaseFormFieldCommon &
  Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'>;

export const BaseSelectField = forwardRef<
  HTMLSelectElement,
  BaseSelectFieldProps
>(function BaseSelectField(
  { className, error, size = 'medium', options, id, ...props },
  ref,
) {
  return (
    <select
      ref={ref}
      id={id}
      data-testid={`select-field-button-${id}`}
      className={clsx(
        'w-full rounded border border-gray-300 transition-shadow duration-150',
        {
          [classes.size[size]]: !!size,
          [classes.variant.error]: error,
          [classes.variant.default]: !error,
        },
        className,
      )}
      {...props}
    >
      {options?.map((option, index) => {
        if (index === 0 && option.value !== '') {
          return (
            <>
              <option key="placeholder" value="">
                Choose one
              </option>
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.name || option.value}
              </option>
            </>
          );
        }
        return (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.name || option.value}
          </option>
        );
      })}
    </select>
  );
});

export type BaseTextAreaFieldProps = BaseFormFieldCommon &
  Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'size'>;

export const BaseTextAreaField = forwardRef<
  HTMLTextAreaElement,
  BaseTextAreaFieldProps
>(function BaseTextAreaField({ className, error, ...props }, ref) {
  return (
    <textarea
      ref={ref}
      className={clsx(
        'w-full rounded border-gray-300 transition-shadow duration-150',
        {
          [classes.variant.error]: error,
          [classes.variant.default]: !error,
        },
        className,
      )}
      {...props}
    />
  );
});
