import * as Sentry from '@sentry/react';
import { useSearchParams } from 'react-router-dom';
import { CheckIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { logout } from '../utils/auth';
import { useGlobalContext } from '../contexts/globalContext';
import logo from '../assets/logo.svg';
import { safelyUpdateLocation } from '../utils/redirect';

export const CallbackInterstitial = () => {
  const { globalState } = useGlobalContext();
  const { user } = globalState;
  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get('callback_url') as string;
  const replace: boolean = JSON.parse(searchParams.get('replace') || 'true');
  const platformName = user.legal_entity.platforms?.[0]?.name;
  const buttonText =
    user.is_platform_user && platformName
      ? `Return to ${platformName}`
      : 'Continue';

  const onClick = () => {
    let parseCallbackUrl;
    try {
      parseCallbackUrl = new URL(callbackUrl);
    } catch (error) {
      Sentry.captureException(error);
    }

    if (parseCallbackUrl?.host !== window.location.host) {
      logout().then(() => safelyUpdateLocation(callbackUrl, replace));
    } else {
      safelyUpdateLocation(callbackUrl, replace);
    }
  };

  return (
    <div className="flex h-full flex-col items-center justify-center gap-6 p-8">
      <div className="size-10 rounded-full bg-green-600 p-1 text-white">
        <CheckIcon />
      </div>
      <div className="flex flex-col items-center">
        <h3 className="text-2xl font-bold text-green-600">Got it!</h3>
        <p className="max-w-sm text-center text-gray-500">
          Your request is on its way.
        </p>
      </div>
      <Button type="button" size="large" onClick={onClick}>
        {buttonText}
      </Button>
      {user?.is_platform_user && (
        <>
          <hr className="my-4 w-full max-w-sm" />
          <div className="flex items-center justify-center gap-1">
            <div className="whitespace-nowrap text-sm text-gray-500">
              Powered by
            </div>
            <a
              href="https://mosey.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logo} alt="Logo" className="mt-1 h-4" />
            </a>
          </div>
        </>
      )}
    </div>
  );
};
