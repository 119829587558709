import { FunctionComponent } from 'react';
import { CheckIcon, StopwatchIcon } from '@mosey/components/Icons';
import { RequirementImportDatumForm, RemediationsList } from '.';
import {
  Requirement,
  RequirementComputedStatus,
  RequirementStatus,
} from '../types';
import { requirementComputedStatus } from '../utils/format';
import { RequirementStatusMessage } from './requirement/RequirementStatusMessage';

type ManagedRequirementProgressProps = {
  requirement: Requirement;
};

const ManagedRequirementProgress: FunctionComponent<
  ManagedRequirementProgressProps
> = ({ requirement }) => {
  if (requirement.status === RequirementStatus.Done) {
    return (
      <RequirementStatusMessage
        icon={<CheckIcon />}
        title="All done!"
        description={`${requirement.title} is complete.`}
      />
    );
  }

  if (
    requirement.status === RequirementStatus.InProgress ||
    requirement.is_managed
  ) {
    return (
      <RequirementStatusMessage
        icon={<StopwatchIcon />}
        variant="inProgress"
        title="In progress"
        description="Your request is being processed."
      />
    );
  }

  return (
    <div className="flex h-64 items-center justify-center">
      <div className="flex items-center italic text-gray-500">
        Something went wrong, we&apos;re looking into it.
      </div>
    </div>
  );
};

// Shows the status of the requirement or steps to complete.
type RequirementProgressProps = {
  requirement: Requirement;
  // Whether or not the requirement was resolved by importing
  isImported: boolean;
  // Whether the component should handle importing requirement datums
  isImporting: boolean;
  // Whether the requirement is managed by a provider
  isManaged: boolean;
  // Whether the requirement is managed by us
  isManagedByMosey: boolean;
  // Callback for succesfully importing
  onImportSuccess: () => void;
  // Callback for canceling import
  onImportCancel: () => void;
  // Callback for whenever the requirement updates
  onRequirementChange: () => void;
};

export const RequirementProgress: FunctionComponent<
  RequirementProgressProps
> = ({
  requirement,
  isImported,
  isImporting,
  isManaged,
  isManagedByMosey,
  onImportCancel,
  onImportSuccess,
  onRequirementChange,
}) => {
  const inactiveStatuses = [
    RequirementComputedStatus.Locked,
    RequirementComputedStatus.Deferred,
    RequirementComputedStatus.Managed,
  ];
  const computedStatus = requirementComputedStatus(requirement);
  const isDisabled =
    requirement.is_virtual ||
    inactiveStatuses.includes(computedStatus) ||
    isManaged ||
    isManagedByMosey ||
    requirement.is_blocked;

  if (isImported) {
    return (
      <div className="flex h-64 flex-col items-center justify-center">
        <div className="size-10 rounded-full bg-green-600 p-1 text-white">
          <CheckIcon />
        </div>
        <div>
          <h3 className="text-2xl font-bold text-green-600">Success</h3>
        </div>
        <p className="ml-1 text-gray-500">
          You&apos;re information was successfully imported into your account.
        </p>
      </div>
    );
  }

  if (isImporting && requirement.id) {
    return (
      <div className="px-8 py-4">
        <div>
          <h3 className="mt-4 text-xl font-semibold">Import your setup</h3>
          <p className="mt-1 text-gray-600">
            Import your existing setup by providing the following information.
          </p>
          <RequirementImportDatumForm
            requirementId={requirement.id}
            regionCode={requirement.region[0].toLowerCase()}
            attributes={requirement.produces}
            onSubmitSuccess={onImportSuccess}
            onCancel={onImportCancel}
          />
        </div>
      </div>
    );
  }

  if (isManagedByMosey) {
    return (
      <div className="flex grow flex-col justify-center py-4">
        <ManagedRequirementProgress requirement={requirement} />
      </div>
    );
  }

  return (
    <RemediationsList
      requirement={requirement}
      onChange={onRequirementChange}
      remediations={requirement.remediations}
      isDisabled={isDisabled}
    />
  );
};
