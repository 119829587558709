import { getDecodedAccessToken } from '@mosey/utils/auth';
import { LightBulbIcon } from '@mosey/components/Icons';
import { getImpersonatedUser } from '../../utils/impersonation';
import { useUser } from '../../hooks/useUser';

export const ImpersonationAlert = () => {
  const user = useUser();
  const decodedAccessToken = getDecodedAccessToken();
  const impersonatedUser = getImpersonatedUser(decodedAccessToken);

  if (impersonatedUser) {
    return (
      <div className="flex w-full items-center border-b bg-sage-200 p-4 px-8 text-base leading-6 text-rose-600">
        <div className="flex flex-1 items-center justify-between gap-x-2">
          <LightBulbIcon className="mr-2 mt-2 size-5 self-start rounded-3xl text-rose-700" />
          <div className="flex-1">
            <div className="text-base font-bold leading-7 text-zinc-700">
              Impersonating user
            </div>
            <div className="text-sm text-zinc-700">
              You are impersonating{' '}
              <span className="font-bold">{user.email}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
