import { Button } from '@mosey/components/buttons/Button';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { Link } from 'react-router-dom';
import { HandbookBanner } from '../components/HandbookBanner';

export const Welcome = () => {
  return (
    <section className="flex min-h-[calc(100vh-64px)] flex-col justify-center p-8">
      <HandbookBanner as="main">
        <div className="flex flex-1 flex-col p-10">
          <h1 className="text-2xl font-bold">
            Launch your multi-state employee handbook in minutes.
          </h1>
          <p className="mt-1 text-zinc-800">
            Everything you need to make a multi-state compliant employee
            handbook for all 50 states.
          </p>
          <div className="mt-8 flex items-center gap-6">
            <Button as={Link} to="../form">
              Get started
            </Button>
            <TextLink
              to="https://mosey.com/handbook-beta/"
              target="_blank"
              variant="secondary"
              skipInk={false}
              skipIcon
            >
              Learn more
            </TextLink>
          </div>
        </div>
      </HandbookBanner>
    </section>
  );
};
