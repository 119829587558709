import { FunctionComponent, useEffect, useState } from 'react';

import { useGlobalContext } from '../contexts/globalContext';
import {
  Region,
  Person,
  LegalEntityRegionRef,
  SubscriptionPlanEnum,
} from '../types';
import { Gate } from '../components/base/Gate';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus } from '../utils/types';
import { useUser } from '../hooks/useUser';

/**
 * This function uses a combination of subscription_plan, hq_region, number of regions,
 * and leadership addresses to determine if the app should be gated to a user. It's
 * expected that hq_region is set for legal entities with domestic leadership after being
 * caught by this gate once.
 *
 * The cases that should be caught in this gate:
 *
 * case 1: Business has created incorporation region with at least 1 domestic leadership
 * case 2: Business has not-created incorporation region with all international leadership
 * case 3: Business has not-created incorporation region with at least 1 domestic leadership
 *
 * ignored case: Business has DE as incorporation region with all international leadership
 * - this case should not be caught to avoid circular logic
 * - more info in PR description:
 */
const shouldCatchBasicGate = ({
  subscriptionPlan,
  incorporationRegion,
  hqRegion,
  regions,
  peopleData,
}: {
  subscriptionPlan?: SubscriptionPlanEnum;
  incorporationRegion?: Region;
  hqRegion?: Region;
  regions: LegalEntityRegionRef[];
  peopleData: Person[];
}): boolean => {
  if (subscriptionPlan !== SubscriptionPlanEnum.Basic) {
    return false;
  }

  const filteredPeople = peopleData.filter(
    (p) => p.is_officer || p.is_owner || p.is_member,
  );
  const businessHasOnlyInternationalPeople = filteredPeople.every(
    (p) => p.address?.country_region !== 'United States',
  );

  const hasCreatedIncorporationRegion = !!regions.find(
    (r) => incorporationRegion && r.region.code === incorporationRegion?.code,
  );

  if (hasCreatedIncorporationRegion) {
    // case 1
    if (!businessHasOnlyInternationalPeople && !hqRegion) {
      return true;
    }
  } else {
    // case 2
    if (businessHasOnlyInternationalPeople && regions.length === 0) {
      return true;
    }
    // case 3
    if (!businessHasOnlyInternationalPeople && !hqRegion) {
      return true;
    }
  }

  return false;
};

type GateBasicPlanSetupProps = {
  children: React.ReactNode;
};

export const GateBasicPlanSetup: FunctionComponent<GateBasicPlanSetupProps> = ({
  children,
}) => {
  const { globalState } = useGlobalContext();
  const {
    legal_entity: {
      subscription_plan: subscriptionPlan,
      hq_region: hqRegion,
      incorporation_region: incorporationRegion,
      regions,
    },
  } = useUser();
  const [peopleData, setPeopleData] = useState<Person[]>([]);

  useEffect(() => {
    if (subscriptionPlan === SubscriptionPlanEnum.Basic && !hqRegion) {
      fetchApi({
        url: `/api/legal_entity/people`,
        method: 'GET',
      }).then(({ status, data }) => {
        if (status === ApiStatus.Success) {
          setPeopleData(data);
        }
      });
    }
  }, [subscriptionPlan, hqRegion]);

  const shouldRenderBasicScreen =
    shouldCatchBasicGate({
      subscriptionPlan,
      incorporationRegion,
      hqRegion,
      regions,
      peopleData,
    }) && !globalState.hasCheckedBasicPlanOnboarding;

  return (
    <Gate
      shouldRenderChildren={!shouldRenderBasicScreen}
      redirectUrl="/onboarding/basic"
    >
      {children}
    </Gate>
  );
};

export default GateBasicPlanSetup;
