import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router';
import { clsx } from 'clsx';
import {
  CheckIcon,
  ClipboardCheckIcon,
  LockClosedFilledIcon,
  RefreshIcon,
  RobotIcon,
  SortAscIcon,
  SortDescIcon,
  StopwatchIcon,
  EditIcon,
  GovernmentIcon,
  LockIcon,
} from '@mosey/components/Icons';
import { Pill } from '@mosey/components/badges/Pill';
import { RequirementComputedStatus, RequirementOverview } from '../types';
import { CategoryTag, Tooltip } from '../components';
import {
  dueDateOccurrenceDescriptor,
  formatDateFromString,
  nameFromUser,
  requirementComputedStatus,
} from '../utils/format';
import { genRequirementPath } from '../utils/paths';
import {
  CategoryToIconMapping,
  CategoryToStringMapping,
} from '../utils/category';
import { statusColorMapping } from '../utils/requirement';
import { ColumnType, SortDir, SortTableBy, sortTable } from '../utils/sort';

export const statusIconMapping: Record<
  RequirementComputedStatus,
  FunctionComponent
> = {
  [RequirementComputedStatus.Todo]: ClipboardCheckIcon,
  [RequirementComputedStatus.Done]: CheckIcon,
  [RequirementComputedStatus.Deferred]: StopwatchIcon,
  [RequirementComputedStatus.Locked]: LockClosedFilledIcon,
  [RequirementComputedStatus.Overdue]: StopwatchIcon,
  [RequirementComputedStatus.InProgress]: RefreshIcon,
  [RequirementComputedStatus.Managed]: RobotIcon,
};

type RequirementsTableRowProps = {
  requirement: RequirementOverview;
};

const RequirementsTableRow: FunctionComponent<RequirementsTableRowProps> = ({
  requirement,
}) => {
  const navigate = useNavigate();
  const status = requirementComputedStatus(requirement);
  const isLocked = status === RequirementComputedStatus.Locked;
  const statusPill = (
    <Pill
      Icon={statusIconMapping[requirementComputedStatus(requirement)]}
      variant={statusColorMapping[status]}
      size="small"
    >
      {status}
    </Pill>
  );

  return (
    <tr
      className={clsx('cursor-pointer align-top hover:bg-gray-50', {
        'text-gray-400': isLocked,
      })}
      onClick={() => navigate(genRequirementPath(requirement))}
    >
      <td className="py-4">
        <div className="flex">
          <div className="flex items-start px-6">
            {isLocked && (
              <span className="mr-1 mt-1 size-4 text-gray-400">
                <LockIcon />
              </span>
            )}
            <p className="flex-1 font-semibold">{requirement.title}</p>
          </div>
        </div>
        {requirement.requirement_context && (
          <div className="flex items-start px-6 text-xs text-gray-400">
            {requirement.requirement_context.message}
          </div>
        )}
      </td>
      <td className="py-4">
        <div className="flex">
          <div className="pr-6">
            {requirement.assigned_user ? (
              <p>{nameFromUser(requirement.assigned_user)}</p>
            ) : (
              <p className="italic text-gray-500">Unassigned</p>
            )}
          </div>
        </div>
      </td>
      <td className="py-4 pr-6">
        <div className="flex flex-row items-center">
          <CategoryTag className="mr-2" text="State" icon={GovernmentIcon} />
          <CategoryTag
            className="mr-2"
            text={CategoryToStringMapping[requirement.category]}
            icon={CategoryToIconMapping[requirement.category]}
          />
        </div>
      </td>
      <td className="py-4 pr-6">
        <div>
          <p className="font-semibold">
            {requirement.due_date && formatDateFromString(requirement.due_date)}
          </p>
          <p className="text-xs text-gray-400">
            {dueDateOccurrenceDescriptor(requirement.schedule).toUpperCase()}
          </p>
        </div>
      </td>
      <td className="py-4 pr-6">
        {requirement.managed_provider ? (
          <Tooltip
            name={`requirement-managed-by-tooltip-${requirement.id}`}
            label={`This requirement is managed by ${requirement.managed_provider.name}`}
          >
            {statusPill}
          </Tooltip>
        ) : (
          statusPill
        )}
      </td>
      <td className="py-4 pr-6">
        <div className="size-6">
          <EditIcon />
        </div>
      </td>
    </tr>
  );
};

type RequirementsTableProps = {
  requirements: RequirementOverview[];
};

export const RequirementsTable: FunctionComponent<RequirementsTableProps> = ({
  requirements,
}) => {
  const [sortBy, setSortBy] = useState<SortTableBy>({
    column: ColumnType.DueDate,
    sortDir: SortDir.Asc,
  });
  const [hoverColumn, setHoverColumn] = useState<ColumnType>(
    ColumnType.DueDate,
  );
  const tableRequirements = sortTable(requirements, sortBy);

  const handleSortColumnClick = (col: ColumnType): void => {
    if (sortBy.column === col) {
      const newSortDir =
        sortBy.sortDir === SortDir.Asc ? SortDir.Desc : SortDir.Asc;
      setSortBy({ column: col, sortDir: newSortDir });
    } else {
      setSortBy({ column: col, sortDir: SortDir.Asc });
    }
  };

  const getSortIcon = () => {
    const iconClass = 'w-4 h-4 ml-2';
    if (sortBy.sortDir === SortDir.Asc) {
      return <SortAscIcon className={iconClass} />;
    }
    return <SortDescIcon className={iconClass} />;
  };

  const getTableHeader = (
    name: string,
    columnType: ColumnType,
    styles: string,
  ) => {
    return (
      <th
        onClick={() => handleSortColumnClick(columnType)}
        onMouseEnter={() => setHoverColumn(columnType)}
        onMouseLeave={() => setHoverColumn(sortBy.column)}
        className={clsx(
          'cursor-pointer border-b text-left font-medium tracking-wider',
          styles,
        )}
      >
        <div className="flex items-center">
          {name}
          {sortBy.column === columnType && getSortIcon()}
          {sortBy.column !== columnType && hoverColumn === columnType && (
            <SortAscIcon className="ml-2 size-4" />
          )}
        </div>
      </th>
    );
  };

  return (
    <table className="w-full border">
      <thead>
        <tr className="text-sm uppercase text-gray-500">
          {getTableHeader(
            'Requirement',
            ColumnType.ReqName,
            'pl-6 pr-5 py-3 w-80',
          )}
          {getTableHeader(
            'Assigned to',
            ColumnType.AssignedTo,
            'pr-6 py-2 w-36',
          )}
          {getTableHeader('Category', ColumnType.Category, 'px-3 py-3 w-36')}
          {getTableHeader('Due Date', ColumnType.DueDate, 'pr-6 py-2 w-36')}
          {getTableHeader('Status', ColumnType.Status, 'pl-3 pr-6 py-2 w-36')}
          <th className="w-16 border-b py-2 pr-6 text-left font-medium tracking-wider">
            Edit
          </th>
        </tr>
      </thead>
      <tbody>
        {tableRequirements.length > 0 ? (
          tableRequirements.map((r: RequirementOverview) => (
            <RequirementsTableRow
              key={`${r.id}-${r.data_id}-${r.due_date}`}
              requirement={r}
            />
          ))
        ) : (
          <tr>
            <td colSpan={6}>
              <div className="flex h-64 items-center justify-items-center">
                <div className="m-auto">
                  <p className="italic text-gray-400">No requirements found</p>
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
