import { CircleAddIcon } from '@mosey/components/Icons';
import { SectionHeading } from '../components';
import { Badge } from '../components/alerts/Badge';
import { Connection } from '../types';
import { Link, json, useLoaderData } from 'react-router-dom';
import { ReactivateConnectionButton } from '../components/settings/ReactivateConnectionButton';
import { api } from '../utils/fetchApi';

export const IntegrationsView = () => {
  const connections = useLoaderData() as Array<Connection>;
  return (
    <div className="flex flex-col p-16">
      <SectionHeading
        className="flex flex-1 items-center pb-4"
        text="Integrations"
      />

      {connections.length > 0 ? (
        <div className="mt-6 divide-y rounded-lg border border-gray-200">
          {connections.map((connection) => (
            <div
              key={connection.id}
              className="flex items-center justify-between px-4 py-5"
            >
              <p>
                {connection.provider_name
                  ? `${connection.provider_name} (${connection.integration.name})`
                  : connection.integration.name}
              </p>
              <div className="flex items-center gap-x-4">
                {connection.needs_auth && (
                  <ReactivateConnectionButton connection={connection} />
                )}
                <Badge
                  isActive={!(connection.is_disabled || connection.needs_auth)}
                >
                  {connection.is_disabled || connection.needs_auth
                    ? 'Inactive'
                    : 'Active'}
                </Badge>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Link to="/onboarding/setup">
          <button
            type="button"
            className="mt-10 inline-flex w-full items-center justify-center rounded-lg border border-dashed border-sage-500 bg-sage-100 py-4 font-bold"
          >
            <span>Add Integration</span>
            <CircleAddIcon className="ml-3 size-6 text-sage-600" />
          </button>
        </Link>
      )}
    </div>
  );
};

IntegrationsView.loader = () => {
  return api({
    url: '/api/connections',
    method: 'GET',
  });
};

IntegrationsView.action = async () => {
  try {
    return await api({
      url: `/api/connections`,
      method: 'POST',
      body: { integration_slug: 'csv-import' },
    });
  } catch (err) {
    // failures dont impact whether payroll sync will work
    return json({});
  }
};
