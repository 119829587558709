import { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { HomeIcon, CheckIcon, CopyIcon } from '@mosey/components/Icons';
import { BatchApiStatusHandler } from '../components';
import { AccountsTable } from '../components/AccountsTable';
import {
  AgencyDetails,
  LegalEntityRegion,
  AgencyAccount,
  AgencyAttribute,
} from '../types';
import { useBatchApi } from '../hooks';
import { useGlobalContext } from '../contexts/globalContext';
import { getLoginDataFromAgencies } from '../utils/agency';

import { useCopy } from '@mosey/components/hooks/useCopy';
import { IconButton } from '@mosey/components/buttons/IconButton';

type LocationDetailAccountsProps = {
  legalEntityRegion: LegalEntityRegion;
  agencies: AgencyDetails[];
};

type AgencyAccountDetailProps = {
  agency: AgencyDetails;
  attributes: AgencyAttribute[];
};

const AgencyAccountAttributeCopyButton = ({
  textToCopy,
}: {
  textToCopy: string;
}) => {
  const [onCopy, isCopied] = useCopy(textToCopy);
  return (
    <IconButton
      className="ml-2"
      aria-label="Copy input value"
      aria-describedby="text-field-state"
      type="button"
      size="small"
      variant={isCopied ? 'actionSuccess' : 'secondary'}
      onClick={onCopy}
      icon={
        isCopied ? (
          <CheckIcon className="size-3" />
        ) : (
          <CopyIcon className="size-3" />
        )
      }
    />
  );
};
const AgencyAccountDetail: FunctionComponent<AgencyAccountDetailProps> = ({
  agency,
  attributes,
}) => {
  if (attributes.length === 0) {
    return null;
  }

  return (
    <li className="border bg-white p-4">
      <div className="text-xs font-medium uppercase leading-6 tracking-widest text-zinc-600">
        {agency.name}
      </div>
      <dl className="mt-2 divide-y text-sm text-gray-500">
        {attributes.map((attr) => (
          <div
            className="flex items-center justify-between py-2 text-zinc-800"
            key={attr.id}
          >
            <dt>{attr.name}</dt>
            <dd className="whitespace-nowrap font-semibold">
              {attr.value}
              <AgencyAccountAttributeCopyButton textToCopy={attr.value || ''} />
            </dd>
          </div>
        ))}
      </dl>
    </li>
  );
};

export const LocationDetailAccounts: FunctionComponent<
  LocationDetailAccountsProps
> = ({ legalEntityRegion, agencies }) => {
  const stateCode = legalEntityRegion.region.code.toLowerCase();
  const { globalState } = useGlobalContext();
  const isIncorporationState =
    globalState.user.legal_entity.incorporation_region?.code.toLowerCase() ===
    stateCode;

  const logins = useMemo(() => getLoginDataFromAgencies(agencies), [agencies]);
  const agenciesWithDatums: AgencyAccountDetailProps[] = [];
  for (const agency of agencies) {
    const datums = agency.agency_accounts.flatMap(
      (agencyAccount: AgencyAccount) => {
        return agencyAccount.attributes.filter(
          (a: AgencyAttribute) => a.value !== null,
        );
      },
    );
    if (datums.length > 0) {
      agenciesWithDatums.push({ agency, attributes: datums });
    }
  }

  return (
    <div className="min-h-full space-y-6 p-6">
      <section>
        <h2 className="font-bold text-zinc-800">Registrations</h2>

        {isIncorporationState && (
          <div className="mt-6 border bg-white">
            <div className="flex flex-1 divide-x">
              <div className="flex flex-1 flex-col justify-start p-6">
                <div className="mt-1 flex items-center space-x-2 text-sm">
                  <span className="rounded-full bg-yellow-400 p-2 text-white">
                    <HomeIcon className="size-4" />
                  </span>
                  <div>
                    <div className="text-sm font-bold leading-none text-gray-700">
                      Incorporation State
                    </div>
                    <Link
                      to={`/locations/usa/${stateCode}/certificate-of-good-standing`}
                      className="text-teal-600 hover:text-teal-700"
                    >
                      Request a Certificate of Good Standing...
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {(agenciesWithDatums.length > 0 && (
          <ul className="mt-6 grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
            {agenciesWithDatums.map((props: AgencyAccountDetailProps) => (
              <AgencyAccountDetail {...props} key={props.agency.id} />
            ))}
          </ul>
        )) || (
          <p className="mt-6 border bg-white p-6 text-gray-400">
            Your account numbers and tax details will be listed here
          </p>
        )}
      </section>
      <section>
        <h2 className="font-bold text-zinc-800">Login Credentials</h2>
        <div className="mt-6 bg-white">
          <AccountsTable logins={logins} />
        </div>
      </section>
    </div>
  );
};

type LocationDetailAccountsViewProps = {
  legalEntityRegion: LegalEntityRegion;
};

export const LocationDetailAccountsView: FunctionComponent<
  LocationDetailAccountsViewProps
> = ({ legalEntityRegion }) => {
  const locationId = legalEntityRegion.region.code.toLowerCase();

  const batchResponse = useBatchApi([
    {
      url: `/api/legal_entity/locations/${locationId}`,
      method: 'GET',
    },
    {
      url: `/api/legal_entity/locations/${locationId}/agencies`,
      method: 'GET',
    },
  ]);

  const componentPropsFn = ([
    legalEntityRegionResponse,
    agenciesResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): LocationDetailAccountsProps => {
    return {
      legalEntityRegion: legalEntityRegionResponse,
      agencies: agenciesResponse,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={LocationDetailAccounts}
      componentProps={componentPropsFn}
    />
  );
};
