import { jwtDecode } from 'jwt-decode';

export type DecodedToken = {
  sub: string;
  token: string;
  permissions: string;
  exp: string;
  impersonator?: string;
  legal_entity_id?: string;
};

export enum Role {
  Admin = 'admin',
  Agent = 'agent',
  User = 'user',
  Developer = 'developer',
  AgentLead = 'agent_lead',
}

export enum RefreshTokenError {
  InternalServerError,
  Unauthorized,
  Expired,
}

export const getAccessToken = () => {
  return localStorage.getItem('token');
};

export const getDecodedAccessToken = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return null;
  }

  return jwtDecode<DecodedToken>(token);
};

export const setAccessTokenCommon = (decodedToken: DecodedToken): void => {
  localStorage.setItem('legal_entity_id', decodedToken.legal_entity_id || '');
  localStorage.setItem('permissions', decodedToken.permissions);
  localStorage.setItem('expires', decodedToken.exp);
};

export const setAccessToken = (accessToken: string): void => {
  const decodedToken: DecodedToken = jwtDecode(accessToken);
  localStorage.setItem('token', accessToken);
  setAccessTokenCommon(decodedToken);
};

export const clearAccessToken = (): void => {
  localStorage.removeItem('token');
  localStorage.removeItem('permissions');
  localStorage.removeItem('expires');
  localStorage.removeItem('legal_entity_id');
};

export const getRole = () => {
  return localStorage.getItem('permissions') as Role | null;
};

export const isAuthenticated = (
  allowedRoles: `${Role}`[] = ['user', 'admin'],
) => {
  const role = getRole();

  return role !== null && allowedRoles.includes(role);
};
