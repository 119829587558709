import { ElementType, ReactNode, forwardRef } from 'react';
import { clsx } from 'clsx';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { StatusIcon } from './StatusIcon';
import { TaskCardVariant, TaskCardReason, TaskCardStatus } from './types';
import { ReasonIcon } from './ReasonIcon';

export interface TaskSummaryCardProps {
  as?: ElementType;
  variant?: TaskCardVariant;
  title: ReactNode;
  description?: ReactNode;
  status: TaskCardStatus;
  reason?: {
    title: ReactNode;
    type: TaskCardReason;
  };
  region?: ReactNode;
  to: NavLinkProps['to'];
  ariaLabelTitle?: NavLinkProps['aria-label'];
  isVisible?: boolean;
  // whether to match the hash as well
  isExactMatch?: boolean;
}

export const TaskSummaryCard = forwardRef(function TaskSummaryCard(
  {
    as: Container = 'div',
    status,
    title,
    description,
    reason,
    region,
    to,
    ariaLabelTitle,
    variant = 'card',
    isVisible = true,
    isExactMatch = false,
  }: TaskSummaryCardProps,
  ref,
) {
  const location = useLocation();
  const fullPath = location.pathname + location.hash;
  const showFooter = region || reason;

  return (
    <Container
      className={clsx(
        'group/task-summary-card-container',
        !isVisible && 'hidden',
      )}
      ref={ref}
    >
      <NavLink
        to={to}
        className={({ isActive }) =>
          clsx(
            'block border border-gray-100 px-3 py-[10px] outline-none hover:border-teal-350 hover:bg-teal-300 focus-visible:ring-2 focus-visible:ring-rose-700',
            variant === 'nav' &&
              'relative z-0 -mt-px hover:z-10 hover:border-b focus-visible:z-20 group-first/task-summary-card-container:rounded-t group-last/task-summary-card-container:rounded-b',
            variant === 'card' && 'rounded shadow-sm hover:shadow',
            isActive &&
              (!isExactMatch || fullPath === to) &&
              variant === 'nav' &&
              'bg-teal-100 text-black',
          )
        }
        aria-label={`${ariaLabelTitle || title}${status === 'done' ? ' (Complete)' : status === 'overdue' ? ' (Overdue)' : ''}`}
      >
        <div className="flex w-full gap-3">
          <StatusIcon
            status={status}
            size={variant === 'card' ? 'large' : 'medium'}
          />
          <div className="flex w-full flex-1 flex-col items-start justify-center gap-0.5 self-center overflow-hidden">
            <p className="w-full font-medium">{title}</p>
            {description && (
              <p className="w-full truncate text-sm">{description}</p>
            )}
            {showFooter && (
              <div className="flex w-full max-w-full text-sm">
                {reason && (
                  <div className="flex items-center gap-1 truncate">
                    <ReasonIcon reason={reason.type} />
                    <p className="truncate text-zinc-700">{reason.title}</p>
                  </div>
                )}
                <div className="min-w-1 flex-1" />
                {region && <p className="text-zinc-600">{region}</p>}
              </div>
            )}
          </div>
        </div>
      </NavLink>
    </Container>
  );
});
