import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  LoaderFunction,
  ActionFunction,
  useLoaderData,
  useFetcher,
  Link,
} from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import {
  formSpecToRendererConfig,
  Renderer,
  SectionHeading,
} from '../../components';
import { Invite, User } from '../../types';
import { STRIPE_BILLING_URL } from '../../settings/config';
import { api, apiBatch } from '../../utils/fetchApi';
import { FormError } from '@mosey/components/forms/FormError';
import { Team } from './Team';
import { useUser } from '../../hooks/useUser';

type MutationResponse =
  | {
      success: boolean;
      errors: Record<string, string> | null;
    }
  | undefined;

export const loader: LoaderFunction = async () => {
  return apiBatch({
    users: {
      url: '/api/users',
      method: 'GET',
    },
    invites: {
      url: '/api/invites',
      method: 'GET',
    },
    billing: {
      url: '/api/subscription/billing',
      method: 'GET',
      onError: () => ({
        customer_portal_url: STRIPE_BILLING_URL,
      }),
    },
  });
};

export const action: ActionFunction = async ({
  request,
}): Promise<MutationResponse> => {
  const data = await request.json();

  try {
    await api({
      url: '/api/users/me',
      method: 'PUT',
      body: {
        first_name: data.firstName,
        last_name: data.lastName,
      },
    });
  } catch (error) {
    return {
      success: false,
      errors: {
        submit: 'Something went wrong, please try again.',
      },
    };
  }

  return {
    success: true,
    errors: null,
  };
};

type FormValues = {
  firstName?: string;
  lastName?: string;
};

export const Component = () => {
  const user = useUser();
  const fetcher = useFetcher<MutationResponse>();
  const {
    users,
    invites,
    billing: { customer_portal_url: customerPortalUrl },
  } = useLoaderData() as {
    users: User[];
    invites: Invite[];
    billing: { customer_portal_url: string };
  };

  const formMethods = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      firstName: user.first_name || '',
      lastName: user.last_name || '',
    },
  });

  const filteredInvites = invites.filter((invite) => !invite.is_accepted);
  const mergedUsersAndInvites = [...users, ...filteredInvites];

  const formFields = [
    {
      name: 'firstName',
      label: 'First name',
      required: 'This is required',
      component: {
        type: 'text',
      },
    },
    {
      name: 'lastName',
      label: 'Last name',
      required: 'This is required',
      component: {
        type: 'text',
      },
    },
  ];

  const {
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors, isDirty, isValid },
  } = formMethods;

  const loadedSpec = {
    title: 'Profile',
    sections: [{ form_fields: formFields }],
  };

  const onCancel = () => {
    reset();
  };

  const onSubmit = async (formData: FormValues) => {
    clearErrors();

    fetcher.submit(formData, { method: 'PUT', encType: 'application/json' });
  };

  useEffect(() => {
    if (fetcher.data?.success && fetcher.state === 'idle') {
      reset({
        firstName: user.first_name,
        lastName: user.last_name,
      });
    }
  }, [fetcher.data, fetcher.state, user.first_name, user.last_name, reset]);

  return (
    <div className="flex flex-col">
      <div className="p-16">
        <SectionHeading className="flex-1 pb-4" text="Account" />

        <div className="flex flex-col py-9 xl:max-w-xl">
          <section>
            <FormProvider {...formMethods}>
              <fetcher.Form id="update-user" onSubmit={handleSubmit(onSubmit)}>
                <Renderer
                  config={formSpecToRendererConfig(loadedSpec)}
                  errors={errors}
                />
                {fetcher.data?.errors?.submit && <FormError margin="mb-9" />}
                {fetcher.data?.success && !isDirty && (
                  <div className="mb-9 w-full rounded border border-green-200 bg-green-100 px-4 py-2 text-green-700">
                    Your profile information has been updated!
                  </div>
                )}
              </fetcher.Form>
            </FormProvider>
          </section>
          <hr className="mb-6" />
          <section>
            <h3 className="text-lg font-medium">Billing</h3>

            <div className="mt-6 rounded-lg border border-gray-200 bg-gray-50 p-4">
              <h4 className="text-sm font-medium text-gray-700">
                Your Billing Plan
              </h4>
              <div className="flex space-x-2">
                <p className="mt-2 flex-1 text-sm text-gray-700">
                  Manage your subscription and billing information.
                </p>

                <Button
                  as={Link}
                  size="small"
                  to={customerPortalUrl}
                  target="_blank"
                >
                  Manage Billing
                </Button>
              </div>
            </div>
          </section>

          <hr className="mb-6 mt-9" />

          <section>
            <Team usersAndInvites={mergedUsersAndInvites} />
          </section>
        </div>
      </div>
      {isDirty && isValid && (
        <div className="sticky bottom-0 flex h-[72px] items-center justify-end space-x-2 border-t border-gray-200 bg-sage-50 px-6 py-4">
          <Button
            type="button"
            variant="secondary"
            onClick={onCancel}
            disabled={fetcher.state !== 'idle'}
          >
            Cancel
          </Button>

          <div className="w-28">
            <Button
              type="submit"
              form="update-user"
              isFullWidth
              isLoading={fetcher.state !== 'idle'}
              disabled={fetcher.state !== 'idle'}
            >
              Save Changes
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
