import { clsx } from 'clsx';

export type PillProps<T extends React.ElementType = 'span'> = {
  as?: T;
  Icon?: React.ComponentType<
    Pick<React.HTMLAttributes<HTMLElement>, 'aria-hidden' | 'className'>
  >;
  children: React.ReactNode;
  size?: 'small' | 'large';
  variant?: 'primary' | 'secondary' | 'inactive' | 'automation' | 'success';
  isCircle?: boolean;
} & Omit<React.ComponentPropsWithoutRef<T>, 'className'>;

export const Pill = <T extends React.ElementType = 'span'>({
  as,
  Icon,
  children,
  size = 'large',
  variant = 'primary',
  isCircle = false,
  ...rest
}: PillProps<T>) => {
  const Component = as || 'span';

  return (
    <Component
      {...rest}
      className={clsx(
        'inline-flex flex-nowrap items-center self-center whitespace-nowrap text-nowrap rounded-full',
        isCircle
          ? 'size-8 justify-center'
          : 'font-bold uppercase leading-none tracking-wide',
        !isCircle && size === 'small' && 'h-6 gap-x-1 pl-2 pr-3 text-[11px]',
        !isCircle && size === 'large' && 'h-8 gap-x-2 pl-3 pr-4 text-xs',
        variant === 'primary' && 'bg-rose-200 text-rose-900',
        variant === 'secondary' && 'bg-teal-350 text-teal-800',
        variant === 'inactive' && 'bg-gray-200 text-zinc-600',
        variant === 'automation' && 'bg-violet-100 text-violet-800',
        variant === 'success' && 'bg-teal-700 text-teal-50',
      )}
    >
      {Icon && (
        <Icon
          aria-hidden
          className={clsx(
            'shrink-0',
            !isCircle && size === 'small' && 'h-[14px] max-w-[14px]',
            (isCircle || size === 'large') && 'h-5 max-w-5',
            variant === 'secondary' && 'text-teal-700',
            variant === 'automation' && 'text-violet-600',
          )}
        />
      )}

      <div className={clsx(isCircle && 'sr-only')}>{children}</div>
    </Component>
  );
};
