interface HandbookContainerProps {
  children: React.ReactNode;
}

export const HandbookContainer = ({ children }: HandbookContainerProps) => {
  return (
    <div className="flex h-full items-center justify-center p-16">
      <article className="flex w-[496px] flex-col gap-y-20 overflow-y-auto">
        {children}
      </article>
    </div>
  );
};
