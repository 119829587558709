export const button =
  'flex min-w-[210px] max-w-none items-center gap-x-1.5 rounded-md bg-white my-px px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-sage-600 focus:outline-none focus:ring-2 focus:ring-rose-600 hover:bg-gray-50 group disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200';

export const iconBase = 'h-5 w-5 shrink-0';

export const icon = `${iconBase} h-5 w-5 shrink-0 text-sage-600 group-hover:text-sage-700`;

export const container =
  'z-10 absolute bg-white rounded-md shadow-lg right-0 ring-1 ring-black ring-opacity-5 focus:outline-none mt-3';

export const item =
  'group block w-full px-4 py-2 text-sm data-[active]:bg-gray-100 bg-white text-gray-700 flex items-center gap-x-1.5';

export const transitions = {
  enter: 'transition ease-out duration-100',
  enterFrom: 'transform opacity-0 scale-95',
  enterTo: 'transform opacity-100 scale-100',
  leave: 'transition ease-in duration-75',
  leaveFrom: 'transform opacity-100 scale-100',
  leaveTo: 'transform opacity-0 scale-95',
};
