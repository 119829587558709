import { FunctionComponent } from 'react';
import { clsx } from 'clsx';

import { ChevronDownIcon } from '@mosey/components/Icons';
import { Section } from '../../components';
import { useGlobalContext } from '../../contexts/globalContext';
import { FatalErrorFullScreen } from '../FatalError';

type NavItemProps = {
  className?: string;
  icon: React.ReactNode;
  text: string;
  url: string;
};

const NavItem: FunctionComponent<NavItemProps> = ({
  className,
  icon,
  text,
  url,
}) => {
  return (
    <a
      href={url}
      className={`flex h-9 w-56 items-center rounded px-3 py-1.5 hover:bg-gray-200/50 ${
        className || ''
      }`}
    >
      <span className="size-4">{icon}</span>
      <span className="ml-3">{text}</span>
    </a>
  );
};

type GustNavProps = {
  children?: React.ReactNode;
};

const GustoNav: FunctionComponent<GustNavProps> = ({ children }) => {
  return (
    <>
      <div className="z-50 flex h-24 w-screen items-center overflow-y-hidden border-b border-gray-200 pl-8 shadow-sm">
        <div className="flex items-center">
          <svg
            id="logo"
            role="img"
            aria-labelledby="titleText"
            viewBox="0 0 1040.65 399.35"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-6 h-10 text-[#f45d48]"
          >
            <title id="titleText">Gusto logo</title>
            <path
              fill="currentColor"
              d="M920.06 61.34A115.69 115.69 0 1 0 1035.74 177 115.81 115.81 0 0 0 920.06 61.34zm0 176.5A60.82 60.82 0 1 1 980.88 177a60.89 60.89 0 0 1-60.82 60.84zM579.44 153.62l-20.21-10.1q-13.14-6.47-16.46-10.2a12 12 0 0 1-3.33-8.18c0-3.9 1.74-7.17 5.25-9.8s8.35-3.94 14.54-3.94q17 0 37.78 20.61l34.14-34.14a89.18 89.18 0 0 0-72.32-36.36q-31.32 0-51.41 18.48t-20.1 44.54q0 40.41 53.33 67.28l18.58 9.29q24 12.12 24 24.85 0 6.87-6.66 12.12T559 243.32q-10.11 0-23.13-7a85.18 85.18 0 0 1-23-18L479.24 255q28.67 37.57 76.76 37.57 36.16 0 57.68-19.59t21.51-48.89q0-22-11.92-38.38t-43.83-32.09zM236.05 65.92h-54.87l.18 19.73c-17.77-18-39.66-24.31-62.18-24.31a115.69 115.69 0 0 0 0 231.38c26.63 0 49.42-11.8 61.16-26.81 0 .91-.08 11.82-.11 13-.22 7.46-1.3 19.41-8.33 31.62A60.6 60.6 0 0 1 87.53 331l-27.29 47.5a114.77 114.77 0 0 0 59.46 16.64c30.36 0 68.22-12 95.07-49.21 22.33-30.92 21.28-60.13 21.28-88.41zM119.18 237.84A60.82 60.82 0 1 1 180 177a60.88 60.88 0 0 1-60.82 60.84zM454.8 65.92h-55.15v110c.11 15.58.09 42.57-11.4 54.14-5.51 5.54-11.51 10.5-24.24 10.5s-18.78-5-24.28-10.5c-11.49-11.57-11.46-38.56-11.35-54.14v-110h-55.11v109.61c-.15 22.57-.43 64.6 27.24 92.45 16.24 16.34 35.58 24.63 63.54 24.63s47.31-8.29 63.55-24.63c27.67-27.85 27.39-69.88 27.24-92.45z"
            />
            <path
              fill="currentColor"
              d="M740.05 237.18c-6.29-.85-12.48-5.34-16-13.54-2.41-5.62-3.28-13-3.28-22.09V113.1h64.81V65.75h-64.81V4.25h-55v192.84c0 18.91 1.52 41.83 14 63 10.65 18 29.41 32.55 60.2 32.55 37.33 0 64.19-27 64.19-27l-33-40.58s-14.49 14.37-31.11 12.12z"
            />
          </svg>
          <button
            type="button"
            className="text-md mr-24 w-24 rounded border-2 border-[#0a8080] px-2.5 py-1 font-semibold text-[#0a8080]"
          >
            Premium
          </button>
        </div>
        <div className="flex size-full items-center">
          <input
            id="searchInput"
            type="text"
            name="search"
            placeholder="Search for shortcuts, help, and people..."
            className="mr-3 h-11 w-2/3 rounded border-x-0 border-b border-t-0 border-b-gray-400 bg-gray-100 pl-10 text-lg font-medium transition-shadow duration-150 placeholder:text-gray-500/80 focus:border-teal-500 focus:ring-teal-500"
          />
          {/* Calendar Icon */}
          <svg
            aria-hidden="true"
            className="mr-4 size-14 rounded-full p-3 font-bold text-gray-500 hover:bg-gray-100"
            role="img"
            focusable="false"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 1.5H6v-.25a.75.75 0 00-1.5 0v.25H3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-10a2 2 0 00-2-2h-1.5v-.25a.75.75 0 00-1.5 0v.25zm-4.75 3a.75.75 0 01-.75-.75V3H3a.5.5 0 00-.5.5V6h11V3.5A.5.5 0 0013 3h-1.5v.75a.75.75 0 01-1.5 0V3H6v.75a.75.75 0 01-.75.75zm-2.75 3v6a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-6h-11z"
            />
          </svg>
          {/* Referrals Icon */}
          <svg
            aria-hidden="true"
            className="mr-1 size-14 rounded-full p-3 font-bold text-gray-500 hover:bg-gray-100"
            role="img"
            focusable="false"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.163 2.716a2.845 2.845 0 013.083-1.685A2.147 2.147 0 0113.01 3.5l-.04.246a2.4 2.4 0 01-.142.503h.672c.966 0 1.75.784 1.75 1.75v1a1.75 1.75 0 01-1.5 1.732V13.5A1.75 1.75 0 0112 15.25H4a1.75 1.75 0 01-1.75-1.75V8.732A1.75 1.75 0 01.75 7V6c0-.966.784-1.75 1.75-1.75h.668a2.38 2.38 0 01-.134-.485l-.019-.11A2.266 2.266 0 014.93 1.037a3.025 3.025 0 013.194 1.77l.039-.092zm2.45 1.534H9.136l.404-.943c.245-.57.846-.899 1.458-.797.352.059.59.392.532.744l-.042.247a.897.897 0 01-.877.749zm-1.695 1.5H8.75v1.5h4.75a.25.25 0 00.25-.25V6a.25.25 0 00-.25-.25H8.918zm-3.54 0H2.5a.25.25 0 00-.25.25v1c0 .138.112.25.25.25h4.75v-1.5H5.377zm1.446-2.137l.182.637H5.369a.876.876 0 01-.856-.732l-.018-.11a.766.766 0 01.647-.885 1.524 1.524 0 011.682 1.09zM8.75 8.75h3.5v4.75a.25.25 0 01-.25.25H8.75v-5zm-1.5 0v5H4a.25.25 0 01-.25-.25V8.75h3.5z"
            />
          </svg>
          <div className="flex h-full w-96 items-center border-l border-gray-200 pl-6">
            <button type="button" className="flex items-center">
              <div className="mr-4 flex flex-col items-start">
                <div className="text-lg font-semibold">Merri Friesen</div>
                <div className="text-lg text-gray-500">
                  Admin • Ledner Group
                </div>
              </div>
              <span className="p-3">
                <ChevronDownIcon className="size-6 font-extrabold text-[#0a8080]" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="flex">
        <aside
          className={clsx(
            'linear bg-sidebar relative hidden h-screen w-72 overflow-y-auto border-r bg-gray-100/80 transition-nav duration-500 sm:block',
          )}
        >
          <div
            className={clsx(
              'flex w-full flex-col px-7 text-lg font-normal text-gray-600',
            )}
          >
            <NavItem
              className="my-10"
              text="Home"
              url="https://app.gusto-demo.com/"
              icon={
                <svg
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  width="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.5 13.5V6.687a.5.5 0 00-.203-.402l-5-3.7a.5.5 0 00-.594 0l-5 3.7a.5.5 0 00-.203.402V13.5h2.923v-3.429c0-.966.784-1.75 1.75-1.75h1.654c.966 0 1.75.784 1.75 1.75V13.5H13.5zM1.81 5.079A2 2 0 001 6.687V14a1 1 0 001 1h3.923a1 1 0 001-1v-3.929a.25.25 0 01.25-.25h1.654a.25.25 0 01.25.25V14a1 1 0 001 1H14a1 1 0 001-1V6.687a2 2 0 00-.81-1.608l-5-3.699a2 2 0 00-2.38 0l-5 3.699z"
                  />
                </svg>
              }
            />
            <NavItem
              className="mb-2"
              text="People"
              url="https://app.gusto-demo.com/"
              icon={
                <svg
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  width="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.36 8.89a3.895 3.895 0 10-4.98 0 5.62 5.62 0 00-3.13 5.04.75.75 0 001.5 0 4.119 4.119 0 118.238 0 .75.75 0 001.5 0 5.62 5.62 0 00-3.129-5.04zm-.096-2.995a2.395 2.395 0 11-4.79 0 2.395 2.395 0 014.79 0z"
                  />
                  <path d="M10.75 4.042a.75.75 0 01.75-.75 3.083 3.083 0 012.178 5.266 4.248 4.248 0 012.072 3.65.75.75 0 01-1.5 0 2.75 2.75 0 00-2.75-2.75.75.75 0 010-1.5 1.583 1.583 0 100-3.166.75.75 0 01-.75-.75z" />
                </svg>
              }
            />
            <NavItem
              className="mb-2"
              text="Company"
              url="https://app.gusto-demo.com/"
              icon={
                <svg
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  width="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.822 2.015A2 2 0 013.762.5h8.476a2 2 0 011.94 1.515l1.27 5.079a.809.809 0 01-.005.41l-.052.19a.705.705 0 01-1.141.347V14.5a1 1 0 01-1 1H2.75a1 1 0 01-1-1V8.041a.705.705 0 01-1.141-.346l-.053-.19a.808.808 0 01-.004-.411l1.27-5.08zM3.762 2h8.476a.5.5 0 01.485.379l.956 3.823a2.197 2.197 0 00-1.5.54 2.419 2.419 0 00-2.773.028 2.418 2.418 0 00-2.812 0 2.419 2.419 0 00-2.772-.029 2.197 2.197 0 00-1.501-.54l.956-3.822A.5.5 0 013.762 2zM3.25 8.31V14H5v-2.75a1 1 0 011-1h4a1 1 0 011 1V14h1.75V8.31a.913.913 0 01-1.234-.16.912.912 0 00-1.407 0 .913.913 0 01-1.406 0 .913.913 0 00-1.406 0 .913.913 0 01-1.406 0 .913.913 0 00-1.407 0 .913.913 0 01-1.234.16zM9.5 14h-3v-2.25h3V14z"
                  />
                </svg>
              }
            />
            <NavItem
              className="mb-2"
              text="Payroll"
              url="https://app.gusto-demo.com/"
              icon={
                <svg
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  width="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.75 4.5v.468c.292.15.546.374.734.655l.14.211a.75.75 0 11-1.248.832l-.14-.21a.461.461 0 00-.384-.206h-.07a.399.399 0 00-.151.767l1.128.462a1.959 1.959 0 01-.009 3.63v.391a.75.75 0 01-1.5 0v-.374a2.016 2.016 0 01-1.237-1.323l-.018-.064a.75.75 0 011.439-.423l.018.064c.065.22.266.37.495.37h.07a.459.459 0 00.174-.883l-1.128-.461a1.899 1.899 0 01.187-3.58V4.5a.75.75 0 011.5 0z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M.5 8a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zM8 14A6 6 0 118 2a6 6 0 010 12z"
                  />
                </svg>
              }
            />
            <NavItem
              className="mb-2"
              text="Time tools"
              url="https://app.gusto-demo.com/"
              icon={
                <svg
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  width="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.75 5a.75.75 0 00-1.5 0v3.146a1 1 0 00.293.708L8.97 10.28a.75.75 0 101.06-1.06L8.75 7.94V5z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M.5 8a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zM8 14A6 6 0 118 2a6 6 0 010 12z"
                  />
                </svg>
              }
            />
            <NavItem
              className="mb-2"
              text="Benefits"
              url="https://app.gusto-demo.com/"
              icon={
                <svg
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  width="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.813 3.25c-.58.01-1.2.256-1.77.792-.914.86-1.007 2.095-.442 3.512.566 1.42 1.745 2.842 3.023 3.764a16.31 16.31 0 001.726 1.091 7.32 7.32 0 00.647.315 7.518 7.518 0 00.658-.317c.486-.261 1.11-.64 1.747-1.097 1.279-.916 2.449-2.334 3.007-3.753.556-1.414.457-2.652-.461-3.515-.57-.537-1.188-.782-1.766-.792-.574-.01-1.18.212-1.74.738L9.4 4.03l-.111.109-.766.752-.526-.535-.528.532-.438-.433-.314-.311-.112-.11-.035-.035-.011-.01c-.559-.526-1.167-.748-1.745-.739zm3.183 1.106l-.528.532a.75.75 0 001.054.003l-.526-.535zm.003-1.053c-.19-.189-.378-.374-.414-.407-.813-.766-1.79-1.163-2.797-1.145-1.004.017-1.97.443-2.772 1.198C.476 4.4.52 6.385 1.207 8.11c.686 1.72 2.062 3.36 3.539 4.425.685.494 1.355.905 1.89 1.195.268.145.51.263.71.349.099.042.197.08.288.109.07.022.21.063.362.063s.291-.041.362-.063c.091-.029.19-.067.29-.11.201-.085.446-.204.716-.349.542-.29 1.22-.703 1.912-1.199 1.483-1.063 2.852-2.702 3.529-4.423.678-1.726.708-3.71-.83-5.157-.801-.754-1.764-1.181-2.767-1.198-1.006-.018-1.98.38-2.793 1.145-.028.025-.22.214-.416.407z"
                  />
                </svg>
              }
            />
            <NavItem
              className="mb-2"
              text="Taxes & compliance"
              url="https://app.gusto-demo.com/"
              icon={
                <svg
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M1.5 4a1 1 0 100-2 1 1 0 000 2zM4.75 2.25a.75.75 0 000 1.5h10a.75.75 0 000-1.5h-10zM2.5 8a1 1 0 11-2 0 1 1 0 012 0zM4.75 7.25a.75.75 0 000 1.5h10a.75.75 0 000-1.5h-10zM2.5 13a1 1 0 11-2 0 1 1 0 012 0zM4.75 12.25a.75.75 0 000 1.5h10a.75.75 0 000-1.5h-10z" />
                </svg>
              }
            />
            <NavItem
              className="mb-2"
              text="Reports"
              url="https://app.gusto-demo.com/"
              icon={
                <svg
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 7.725V4.5a2 2 0 00-2-2H9.134a.25.25 0 01-.208-.111l-.48-.721A1.5 1.5 0 007.196 1H3a2 2 0 00-2 2v8.5H.95v1.225c0 .196.025.385.071.566A2 2 0 003 15h10a2 2 0 002-2V9h.05v-.75c0-.18-.017-.355-.05-.525zm-1.5.174V13a.5.5 0 01-.5.5H5.365c.087-.242.135-.503.135-.775V8.25C5.5 7.56 6.06 7 6.75 7h5.55c.568 0 1.048.38 1.2.899zm0-2.124a2.739 2.739 0 00-1.2-.275H6.75A2.75 2.75 0 004 8.25v4.475a.775.775 0 01-1.5.274V3a.5.5 0 01.5-.5h4.197l.48.72c.325.488.872.78 1.457.78H13a.5.5 0 01.5.5v1.275z"
                  />
                </svg>
              }
            />
            <NavItem
              className="mb-10"
              text="App directory"
              url="https://app.gusto-demo.com/"
              icon={
                <svg
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25.5H5.5c.966 0 1.75.784 1.75 1.75V5.5A1.75 1.75 0 015.5 7.25H2.25A1.75 1.75 0 01.5 5.5V2.25C.5 1.284 1.284.5 2.25.5zm0 1.5a.25.25 0 00-.25.25V5.5c0 .138.112.25.25.25H5.5a.25.25 0 00.25-.25V2.25A.25.25 0 005.5 2H2.25zM2.25 8.75H5.5c.966 0 1.75.784 1.75 1.75v3.25A1.75 1.75 0 015.5 15.5H2.25A1.75 1.75 0 01.5 13.75V10.5c0-.966.784-1.75 1.75-1.75zm0 1.5a.25.25 0 00-.25.25v3.25c0 .138.112.25.25.25H5.5a.25.25 0 00.25-.25V10.5a.25.25 0 00-.25-.25H2.25zM13.75 8.75H10.5a1.75 1.75 0 00-1.75 1.75v3.25c0 .966.784 1.75 1.75 1.75h3.25a1.75 1.75 0 001.75-1.75V10.5a1.75 1.75 0 00-1.75-1.75zm-3.5 1.75a.25.25 0 01.25-.25h3.25a.25.25 0 01.25.25v3.25a.25.25 0 01-.25.25H10.5a.25.25 0 01-.25-.25V10.5z"
                  />
                  <path d="M12 .75a.75.75 0 01.75.75v1.75h1.75a.75.75 0 010 1.5h-1.75V6.5a.75.75 0 01-1.5 0V4.75H9.5a.75.75 0 010-1.5h1.75V1.5A.75.75 0 0112 .75z" />
                </svg>
              }
            />
            <NavItem
              className="mb-2"
              text="Settings"
              url="https://app.gusto-demo.com/"
              icon={
                <svg
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.708 8a2.708 2.708 0 11-5.416 0 2.708 2.708 0 015.416 0zm-1.5 0a1.208 1.208 0 11-2.416 0 1.208 1.208 0 012.416 0z"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.88.75c-.35 0-.695.022-1.033.065a.995.995 0 00-.804.632l-.046.12c-.429 1.12-1.668 1.935-2.845 1.74l-.262-.043a.995.995 0 00-.909.322A7.067 7.067 0 00.887 5.243a.994.994 0 00.149 1.074l.338.392c.716.829.716 1.99 0 2.818l-.338.392a.994.994 0 00-.149 1.074 7.067 7.067 0 001.094 1.657c.233.264.58.377.91.323l.26-.044c1.263-.209 2.449.467 2.846 1.504l.046.12a.995.995 0 00.804.632 8.235 8.235 0 002.065 0 .995.995 0 00.804-.632l.045-.12c.189-.492.62-.94 1.207-1.242.585-.301 1.266-.423 1.88-.322l.262.044a.995.995 0 00.909-.322c.44-.5.81-1.056 1.094-1.658a.994.994 0 00-.149-1.074l-.338-.392c-.723-.837-.732-2.055-.023-2.875l.339-.392a.994.994 0 00.149-1.074 7.07 7.07 0 00-1.095-1.658.995.995 0 00-.909-.322l-.26.044c-.606.1-1.276-.031-1.857-.344-.584-.315-1.017-.78-1.209-1.28l-.045-.119a.995.995 0 00-.804-.632A8.242 8.242 0 007.879.75zM2.927 4.79c1.907.305 3.702-.922 4.4-2.518a6.777 6.777 0 011.103 0c.367.822 1.048 1.475 1.827 1.894.827.446 1.824.662 2.792.507.202.253.382.521.536.802l-.118.136c-1.21 1.4-1.173 3.453.023 4.837l.117.136c-.154.28-.334.55-.536.802-.959-.153-1.956.042-2.79.47-.786.404-1.48 1.044-1.851 1.872a6.79 6.79 0 01-1.103 0c-.728-1.653-2.572-2.575-4.4-2.282a5.572 5.572 0 01-.536-.802l.117-.136a3.617 3.617 0 000-4.78l-.117-.135c.154-.282.334-.55.536-.803z"
                  />
                </svg>
              }
            />
            <NavItem
              className="mb-2"
              text="Refer & earn"
              url="https://app.gusto-demo.com/"
              icon={
                <svg
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.163 2.716a2.845 2.845 0 013.083-1.685A2.147 2.147 0 0113.01 3.5l-.04.246a2.4 2.4 0 01-.142.503h.672c.966 0 1.75.784 1.75 1.75v1a1.75 1.75 0 01-1.5 1.732V13.5A1.75 1.75 0 0112 15.25H4a1.75 1.75 0 01-1.75-1.75V8.732A1.75 1.75 0 01.75 7V6c0-.966.784-1.75 1.75-1.75h.668a2.38 2.38 0 01-.134-.485l-.019-.11A2.266 2.266 0 014.93 1.037a3.025 3.025 0 013.194 1.77l.039-.092zm2.45 1.534H9.136l.404-.943c.245-.57.846-.899 1.458-.797.352.059.59.392.532.744l-.042.247a.897.897 0 01-.877.749zm-1.695 1.5H8.75v1.5h4.75a.25.25 0 00.25-.25V6a.25.25 0 00-.25-.25H8.918zm-3.54 0H2.5a.25.25 0 00-.25.25v1c0 .138.112.25.25.25h4.75v-1.5H5.377zm1.446-2.137l.182.637H5.369a.876.876 0 01-.856-.732l-.018-.11a.766.766 0 01.647-.885 1.524 1.524 0 011.682 1.09zM8.75 8.75h3.5v4.75a.25.25 0 01-.25.25H8.75v-5zm-1.5 0v5H4a.25.25 0 01-.25-.25V8.75h3.5z"
                  />
                </svg>
              }
            />
          </div>
        </aside>
        <main className="h-screen flex-1 overflow-y-auto">{children}</main>
      </div>
    </>
  );
};

type ChecklistItemProps = { children: React.ReactNode };

const ChecklistItem: FunctionComponent<ChecklistItemProps> = ({ children }) => {
  return (
    <li className="mb-4 h-28 w-full rounded border-2 border-gray-200 px-6 py-4">
      {children}
    </li>
  );
};

const ComplianceChecklist: FunctionComponent = () => {
  return (
    <ul className="w-full list-none">
      <ChecklistItem>
        <div className="flex items-center">
          <img
            className="mr-4"
            alt="checkbox"
            src="https://d3bnlkto289wdc.cloudfront.net/assets/packs/media/illustrations/checkmark-3923e43f.svg"
          />
          <div className="mr-14">
            <div className="text-xl font-semibold">
              Employment Tax Reporting & Payment
            </div>
            <div>
              We automatically file your forms to report employment taxes and
              payments.
            </div>
          </div>
          <a
            className="text-lg font-semibold text-[#0a8080] underline"
            href="mosey.com"
            target="_blank"
          >
            View Forms
          </a>
        </div>
      </ChecklistItem>
      <ChecklistItem>
        <div className="flex items-center">
          <img
            className="mr-4"
            alt="checkbox"
            src="https://d3bnlkto289wdc.cloudfront.net/assets/packs/media/illustrations/checkmark-3923e43f.svg"
          />
          <div className="mr-14">
            <div className="text-xl font-semibold">
              Employment Tax Reporting & Payment
            </div>
            <div>
              We automatically file your forms to report employment taxes and
              payments.
            </div>
          </div>
          <a
            className="text-lg font-semibold text-[#0a8080] underline"
            href="mosey.com"
            target="_blank"
          >
            View Forms
          </a>
        </div>
      </ChecklistItem>
      <ChecklistItem>
        <div className="flex items-center">
          <img
            className="mr-4"
            alt="checkbox"
            src="https://d3bnlkto289wdc.cloudfront.net/assets/packs/media/illustrations/checkmark-3923e43f.svg"
          />
          <div className="mr-14">
            <div className="text-xl font-semibold">
              Employment Tax Reporting & Payment
            </div>
            <div>
              We automatically file your forms to report employment taxes and
              payments.
            </div>
          </div>
          <a
            className="text-lg font-semibold text-[#0a8080] underline"
            href="mosey.com"
            target="_blank"
          >
            View Forms
          </a>
        </div>
      </ChecklistItem>
      <ChecklistItem>
        <div className="flex items-center">
          <img
            className="mr-4"
            alt="checkbox"
            src="https://d3bnlkto289wdc.cloudfront.net/assets/packs/media/illustrations/checkmark-3923e43f.svg"
          />
          <div className="mr-14">
            <div className="text-xl font-semibold">
              Employment Tax Reporting & Payment
            </div>
            <div>
              We automatically file your forms to report employment taxes and
              payments.
            </div>
          </div>
          <a
            className="text-lg font-semibold text-[#0a8080] underline"
            href="mosey.com"
            target="_blank"
          >
            View Forms
          </a>
        </div>
      </ChecklistItem>
      <ChecklistItem>
        <div className="flex items-center">
          <img
            className="mr-4"
            alt="checkbox"
            src="https://d3bnlkto289wdc.cloudfront.net/assets/packs/media/illustrations/checkmark-3923e43f.svg"
          />
          <div className="mr-14">
            <div className="text-xl font-semibold">
              Employment Tax Reporting & Payment
            </div>
            <div>
              We automatically file your forms to report employment taxes and
              payments.
            </div>
          </div>
          <a
            className="text-lg font-semibold text-[#0a8080] underline"
            href="mosey.com"
            target="_blank"
          >
            View Forms
          </a>
        </div>
      </ChecklistItem>
    </ul>
  );
};

const StayCompliant: FunctionComponent = () => {
  return (
    <Section isFullscreen>
      <div className="flex flex-col p-12 text-black">
        <div className="border-b border-zinc-200 py-2 text-4xl font-semibold leading-10">
          Stay Compliant
        </div>
        <div className="mt-16 flex w-4/5 flex-col">
          <div className="text-3xl font-semibold leading-10">
            Compliance Checklist
          </div>
          <div className="mb-4 mt-2 text-lg text-zinc-700/75">
            Gusto automates a lot of compliance items for you, but there are a
            few things you still need to take care of before you run payroll.
            Most importantly, you should set up workers’ compensation insurance
            for your employees.
          </div>
          <ComplianceChecklist />
          <div>SOMETHING UNDER LIST</div>
        </div>
      </div>
    </Section>
  );
};

export const GustoDashboard: FunctionComponent = () => {
  return (
    <div>
      <GustoNav>
        <StayCompliant />
      </GustoNav>
    </div>
  );
};

export const GustoDashboardView: FunctionComponent = () => {
  const { globalState } = useGlobalContext();

  // Gate view
  const permittedRoles = ['admin', 'developer'];
  if (!permittedRoles.includes(globalState.user.role)) {
    return <FatalErrorFullScreen />;
  }

  return <GustoDashboard />;
};
