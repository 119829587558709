import {
  ActionFunction,
  LoaderFunction,
  Navigate,
  Outlet,
  useNavigation,
  useParams,
} from 'react-router-dom';
import { ResolverSidebar } from '../resolver/Sidebar';
import {
  useTask,
  useTasks,
  useResolverUrl,
  useTaskActionData,
  useNextResolverUrl,
  useIsAutomationRoute,
  useIsStandaloneRoute,
} from '../utils/hooks';
import { api } from '../../../utils/fetchApi';
import {
  ResolverType,
  TaskRouteParams,
  TaskStatus,
  TaskType,
} from '../utils/types';
import { TaskQuestion } from './TaskQuestion';
import { TaskRequirement } from './TaskRequirement';
import { TaskSummary } from './TaskSummary';
import { Loading } from '../../Loading';
import { TaskActionResponse } from './types';
import { markTaskAsCompleteForSession } from '../utils/session';
import { TasksCategory } from '../../Tasks';

export const action: ActionFunction = async ({
  params: { taskId, locationId },
  request,
}): Promise<TaskActionResponse> => {
  const { intent, ...data } = Object.fromEntries(await request.formData());
  let response;
  let newTasks = [];

  // TODO: Better errors!!!

  if (intent === 'mark-as-done') {
    try {
      response = await api({
        url: `/api/compliance/tasks/${taskId}/status`,
        method: request.method,
        body: {
          status: TaskStatus.Done,
          scoped_to_single_location: !!locationId,
        },
      });
    } catch (error) {
      return {
        errors: {
          submit: 'Something went wrong, please try again.',
        },
        resolved: false,
        newTasks: [],
      };
    }

    newTasks = await response.json();
  } else if (intent === 'answer-question') {
    const answers = Object.values(data);

    if (answers.length === 0) {
      return {
        errors: {
          submit: 'Please answer the question to proceed.',
        },
        resolved: false,
        newTasks: [],
      };
    }

    const [answer] = answers;

    try {
      response = await api({
        url: `/api/compliance/tasks/${taskId}/answer`,
        method: request.method,
        body: {
          answer,
          scoped_region_code: locationId, // expected to be undefined if outside of a location scoped view
        },
      });
    } catch (error) {
      return {
        errors: {
          submit: 'Something went wrong, please try again.',
        },
        resolved: false,
        newTasks: [],
      };
    }

    newTasks = await response.json();
  }

  markTaskAsCompleteForSession(taskId);

  return {
    errors: {},
    resolved: true,
    newTasks,
  };
};

export const loader: LoaderFunction = async ({ params: { taskId } }) => {
  if (taskId === TasksCategory.All || taskId === TasksCategory.AssignedToMe) {
    return null;
  }

  return api({
    url: `/api/compliance/tasks/${taskId}`,
    method: 'GET',
  });
};

export const Component = () => {
  const { resolverType } = useParams<TaskRouteParams>();
  const tasks = useTasks();
  const { id, source } = useTask();
  const actionData = useTaskActionData();
  const resolverUrl = useResolverUrl();
  const navigation = useNavigation();
  const nextResolverUrl = useNextResolverUrl();
  const isStandaloneRoute = useIsStandaloneRoute();
  const isAutomationForm = useIsAutomationRoute();

  if (resolverUrl && !tasks.find((_task) => _task.id === id)) {
    return <Navigate to={resolverUrl} replace />;
  }

  let content = null;

  if (actionData?.resolved && !isStandaloneRoute) {
    if (navigation.state === 'idle') {
      const newTasks = actionData.newTasks;
      const newTasksInTaskList = tasks.filter((_task) => {
        return newTasks.find((newTask) => newTask.id === _task.id);
      });

      if (
        nextResolverUrl &&
        (newTasksInTaskList.length === 0 ||
          resolverType === ResolverType.Assessment)
      ) {
        let state;

        if (resolverType === ResolverType.Assessment) {
          state = { newTasks };
        }

        return <Navigate to={nextResolverUrl} state={state} />;
      }

      content = <TaskSummary />;
    } else {
      content = (
        <div className="w-full">
          <Loading />
        </div>
      );
    }
  }

  if (!content) {
    if (source.type === TaskType.Question) {
      content = <TaskQuestion key={id} />;
    } else if (!isAutomationForm) {
      content = <TaskRequirement key={id} />;
    }
  }

  return (
    <section className="flex h-full max-w-screen-2xl @container/task">
      <ResolverSidebar />

      {content}
      <Outlet />
    </section>
  );
};
